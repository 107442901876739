import React, { Component } from 'react';
// import CKEditors from "react-ckeditor-component";
// import { connect } from 'react-redux';
// import axios from 'axios'
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ProfileTeacher extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            phone: '',
            address: '',
            errors: []
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (
            <div className="col-md-12">
                {/* <form>
                    <div className='row'>
                        <div className='col-md-4 col-sm-12'>
                            <div className="form-group">
                                <label className='f-w-600'>Teacher name<span className="text-danger">*</span></label>
                                <input className="form-control" onChange={this.handleInput} value={this.state.name} name="name" type="text" placeholder="name" />
                                <div className="text-danger">{this.state.errors.name}</div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                            <div className="form-group">
                                <label className='f-w-600'>Email<span className="text-danger">*</span></label>
                                <input className="form-control" onChange={this.handleInput} value={this.state.email} name="email" type="text" placeholder="email" />
                                <div className="text-danger">{this.state.errors.email}</div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                            <div className="form-group">
                                <label className='f-w-600'>Phone<span className="text-danger">*</span></label>
                                <input className="form-control" onChange={this.handleInput} value={this.state.phone} name="email" type="text" placeholder="phone" />
                                <div className="text-danger">{this.state.errors.phone}</div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                            <div className='form-group'>
                                <label className='f-w-600'>Address</label>
                                <textarea className='form-control' name='address' value={this.state.address} onChange={this.handleInput}></textarea>
                            </div>
                        </div>
                    </div>
                </form> */}
            </div>
        )
    }

}

export default ProfileTeacher;