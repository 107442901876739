
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { Bar } from 'react-chartjs-2';
import axios from 'axios'

import API_URL from '../../../ApiUrl'

class EnglishStudentHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reports: [{ date: [], source: [] }],
            loading: false,
        }
    }

    componentDidMount() {
        this.loadDataReport()
    }

    loadDataReport = () => {
        let formData = new FormData()
        formData.append('code_teacher', this.props.userableData.userable.code_teacher)
        axios.post(API_URL.superStudent + '/english-division/report-hours-teacher', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then((response) => {
                console.log(response.data.data)
                this.setState({
                    reports: response.data.data,
                })
            })
    }

    render() {

        return (
            <>
                <div className="tab-content active default" id="tab-1">
                    <div className="card">
                        <div className='card-header'>
                            <h5>Total Teach Hours</h5>
                        </div>
                        <div className="card-body">
                            <div className="row m-1">
                                <div className="col-sm-12">
                                    <Bar data={{
                                        labels: this.state.reports.date,
                                        datasets: this.state.reports.source,
                                    }} options={{
                                        maintainAspectRatio: true,
                                        legend: {
                                            display: true,
                                        },
                                        plugins: {
                                            tooltip: {
                                                callbacks: {
                                                    label: function (context) {
                                                        let value = context.raw;
                                                        return `$${value}`;
                                                    },
                                                },
                                            },
                                        },
                                        scales: {
                                            y: {
                                                ticks: {
                                                    callback: function (value) {
                                                        return `$${value}`;
                                                    },
                                                },
                                            },
                                            xAxes: [{
                                                stacked: true,
                                            }],
                                            yAxes: [{
                                                stacked: true,
                                            }]
                                        },
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})


export default connect(reduxState, null)(withRouter(EnglishStudentHome));
