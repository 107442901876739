/* eslint-disable eqeqeq, react-hooks/exhaustive-deps  */

import React, { useEffect, useState, useCallback } from "react";
import Breadcrumb from '../../common/breadcrumb';
import setRangeDate from "../../../helper/general-function";
import axios from 'axios';
import API_URL from '../../../ApiUrl';
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import ReactTable from 'react-table';
import Loader from "../../common/loader";
import { matchSorter } from 'match-sorter';

import DetailNewMember from "./detail/detail-new-member";
import DetailGoldenTicket from "./detail/detail-golden-ticket";
import DetailLeadsEvent from "./detail/detail-leads-event";
import DetailStudentTask from "./detail/detail-student-task";
import DetailStudentsStuck from "./detail/detail-students-stuck";
import DetailPotensialEdufest from "./detail/detail-potensial-edufest";
import DetailAttedanceEdufest from "./detail/detail-attedance-edufest";
import DetailAmbassadorStudent from "./detail/detail-ambassador-student";

const DashboardFolowUp = () => {

    const[typeDate,setTypeDate] = useState('this_year')
    const[filterDate,setFilterDate] = useState('');
    const[showLoading,setShowLoading] = useState(false);
    const[listOffice,setListOffice] = useState([]);
    const[idKantor,setIdKantor] = useState(0);
    const[code_konsultan,setCodeKonsultan] = useState('');
    const[nama_konsultan,setNamaKonsultan] = useState('');

    const[dataFollowUpConsultantAll, setFollowUpConsultantAll] = useState([]);

    const[showDetailNewMember,setShowDetailNewMember] = useState(false);
    const[showDetailGoldenTicket,setShowDetailGoldenTicket] = useState(false);
    const[showDetailLeadsEventSession,setShowDetailLeadsEventSession] = useState(false);
    const[showDetailStudentTask,setShowDetailStudentTask] = useState(false);
    const[showDetailStudentStuck15,setShowDetailStudentStuck15] = useState(false);
    const[showDetailStudentStuck30,setShowDetailStudentStuck30] = useState(false);
    const[showDetailStudentStuck45,setShowDetailStudentStuck45] = useState(false);
    const[showDetailPotensialEdufest,setShowDetailPotensialEdufest] = useState(false); 
    const[showDetailAttendanceEdufest,setShowDetailAttendanceEdufest] = useState(false); 
    const[showDetailStudentAmbassador,setShowDetailStudentAmbassador] = useState(false); 
    
    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }))

    const { dataUser } = useSelector(state => ({
        dataUser:state.Login.detailUser
    }))

    useEffect(() => {
        loadConsultantByCode()
        loadOffice()
    },[])

    useEffect(() => {
        rangeDate()
    },[typeDate])

    useEffect(() => {
        loadDashboarHelperConsultant()
    },[filterDate,idKantor])

    const loadConsultantByCode = useCallback(() => {
        if (dataUser?.code_konsultan && dataUser?.code_roles && dataToken) {
            axios.get(API_URL.superStudent+'/consultant/detail/'+dataUser.code_konsultan, {
                headers: { 'Authorization': `Bearer ${dataToken}` }
            })
            .then(response => {
                setIdKantor(response.data.data.id_kantor);
            })
            .catch(error => console.log(error))
        }
    }, [dataUser, dataToken])

    const loadOffice = useCallback(() => {
        if (dataToken) {
            axios.get(API_URL.superStudent+'/master/office', {
                headers: { 'Authorization': `Bearer ${dataToken}` }
            })
            .then(response => {
                setListOffice(response.data.data);
            })
            .catch(error => console.log(error))
        }
    }, [dataToken])

    const rangeDate = useCallback(() => {
        if (typeDate) {
            let data = setRangeDate(typeDate);
            setFilterDate({start_date:data.start, end_date: data.end });
        }
    },[typeDate])

    const loadDashboarHelperConsultant = useCallback(() => {
        if (idKantor && dataUser?.code_konsultan && dataUser?.code_roles && filterDate.start_date && filterDate.end_date) {
            setShowLoading(true)
            let formData = new FormData()
            formData.append('id_kantor', dataUser.code_roles == 'RL001' || dataUser.code_roles == 'RL026' ? 0 : idKantor)
            formData.append('code_konsultan', dataUser.code_konsultan) 
            formData.append('code_roles', dataUser.code_roles) 
            formData.append('start_date', filterDate.start_date)
            formData.append('end_date', filterDate.end_date)
            axios.post(API_URL.superStudent+'/count-total-follow-up-all', formData, {
                headers: { 'Authorization': `Bearer ${dataToken}` }
            })
            .then(response => {
                setFollowUpConsultantAll(response.data.data);
                setShowLoading(false);
            })
            .catch(error => console.log(error))
        }
    },[idKantor, dataUser, filterDate, dataToken])

    const handleChangeFilterOffice = (event) => {
        setIdKantor(event.target.value);
    };

    const openNewMember = (code, nama) => {
        setCodeKonsultan(code);
        setNamaKonsultan(nama);
        setShowDetailNewMember(true);
        setShowDetailGoldenTicket(false);
        setShowDetailLeadsEventSession(false);
        setShowDetailStudentTask(false);
        setShowDetailStudentStuck15(false);
        setShowDetailStudentStuck30(false);
        setShowDetailStudentStuck45(false);
        setShowDetailPotensialEdufest(false);
        setShowDetailAttendanceEdufest(false);
        setShowDetailStudentAmbassador(false);
    }

    const openGoldenTicket = (code, nama) => {
        setCodeKonsultan(code);
        setNamaKonsultan(nama);
        setShowDetailNewMember(false);
        setShowDetailGoldenTicket(true);
        setShowDetailLeadsEventSession(false);
        setShowDetailStudentTask(false);
        setShowDetailStudentStuck15(false);
        setShowDetailStudentStuck30(false);
        setShowDetailStudentStuck45(false);
        setShowDetailPotensialEdufest(false);
        setShowDetailAttendanceEdufest(false);
        setShowDetailStudentAmbassador(false);
    }

    const openLeadsEventSession = (code, nama) => {
        setCodeKonsultan(code);
        setNamaKonsultan(nama);
        setShowDetailNewMember(false);
        setShowDetailGoldenTicket(false);
        setShowDetailLeadsEventSession(true);
        setShowDetailStudentTask(false);
        setShowDetailStudentStuck15(false);
        setShowDetailStudentStuck30(false);
        setShowDetailStudentStuck45(false);
        setShowDetailPotensialEdufest(false);
        setShowDetailAttendanceEdufest(false);
        setShowDetailStudentAmbassador(false);
    }

    const openStudentTask = (code, nama) => {
        setCodeKonsultan(code);
        setNamaKonsultan(nama);
        setShowDetailNewMember(false);
        setShowDetailGoldenTicket(false);
        setShowDetailLeadsEventSession(false);
        setShowDetailStudentTask(true);
        setShowDetailStudentStuck15(false);
        setShowDetailStudentStuck30(false);
        setShowDetailStudentStuck45(false);
        setShowDetailPotensialEdufest(false);
        setShowDetailAttendanceEdufest(false);
        setShowDetailStudentAmbassador(false);
    }

    const openStudentStuck15 = (code, nama) => {
        setCodeKonsultan(code);
        setNamaKonsultan(nama);
        setShowDetailNewMember(false);
        setShowDetailGoldenTicket(false);
        setShowDetailLeadsEventSession(false);
        setShowDetailStudentTask(false);
        setShowDetailStudentStuck15(true);
        setShowDetailStudentStuck30(false);
        setShowDetailStudentStuck45(false);
        setShowDetailPotensialEdufest(false);
        setShowDetailAttendanceEdufest(false);
        setShowDetailStudentAmbassador(false);
    }

    const openStudentStuck30 = (code, nama) => {
        setCodeKonsultan(code);
        setNamaKonsultan(nama);
        setShowDetailNewMember(false);
        setShowDetailGoldenTicket(false);
        setShowDetailLeadsEventSession(false);
        setShowDetailStudentTask(false);
        setShowDetailStudentStuck15(false);
        setShowDetailStudentStuck30(true);
        setShowDetailStudentStuck45(false);
        setShowDetailPotensialEdufest(false);
        setShowDetailAttendanceEdufest(false);
        setShowDetailStudentAmbassador(false);
    }

    const openStudentStuck45 = (code, nama) => {
        setCodeKonsultan(code);
        setNamaKonsultan(nama);
        setShowDetailNewMember(false);
        setShowDetailGoldenTicket(false);
        setShowDetailLeadsEventSession(false);
        setShowDetailStudentTask(false);
        setShowDetailStudentStuck15(false);
        setShowDetailStudentStuck30(false);
        setShowDetailStudentStuck45(true);
        setShowDetailPotensialEdufest(false);
        setShowDetailAttendanceEdufest(false);
        setShowDetailStudentAmbassador(false);
    }

    const openPotensialEdufest = (code, nama) => {
        setCodeKonsultan(code);
        setNamaKonsultan(nama);
        setShowDetailNewMember(false);
        setShowDetailGoldenTicket(false);
        setShowDetailLeadsEventSession(false);
        setShowDetailStudentTask(false);
        setShowDetailStudentStuck15(false);
        setShowDetailStudentStuck30(false);
        setShowDetailStudentStuck45(false);
        setShowDetailPotensialEdufest(true);
        setShowDetailAttendanceEdufest(false);
        setShowDetailStudentAmbassador(false);
    }

    const openAttendanceEdufest = (code, nama) => {
        setCodeKonsultan(code);
        setNamaKonsultan(nama);
        setShowDetailNewMember(false);
        setShowDetailGoldenTicket(false);
        setShowDetailLeadsEventSession(false);
        setShowDetailStudentTask(false);
        setShowDetailStudentStuck15(false);
        setShowDetailStudentStuck30(false);
        setShowDetailStudentStuck45(false);
        setShowDetailPotensialEdufest(false);
        setShowDetailAttendanceEdufest(true);
        setShowDetailStudentAmbassador(false);
    }

    const openAmbassadorStudent = (code, nama) => {
        setCodeKonsultan(code);
        setNamaKonsultan(nama);
        setShowDetailNewMember(false);
        setShowDetailGoldenTicket(false);
        setShowDetailLeadsEventSession(false);
        setShowDetailStudentTask(false);
        setShowDetailStudentStuck15(false);
        setShowDetailStudentStuck30(false);
        setShowDetailStudentStuck45(false);
        setShowDetailPotensialEdufest(false);
        setShowDetailAttendanceEdufest(false);
        setShowDetailStudentAmbassador(true);
    }

    const closeAllDetail = () => {
        setShowDetailNewMember(false);
        setShowDetailGoldenTicket(false);
        setShowDetailLeadsEventSession(false);
        setShowDetailStudentTask(false);
        setShowDetailStudentStuck15(false);
        setShowDetailStudentStuck30(false);
        setShowDetailStudentStuck45(false);
        setShowDetailPotensialEdufest(false);
        setShowDetailAttendanceEdufest(false);
        setShowDetailStudentAmbassador(false);
    }

    const column = [
        {
            Header: 'Office',
            accessor: 'office.nama_kantor',
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["office.nama_kantor"] }),
            filterable: true,
            Filter: () =>
            <>
                {
                    dataUser.code_roles == 'RL001' || dataUser.code_roles == 'RL003' ? 
                    <select className="form-control" name='id_office' value={idKantor} onChange={handleChangeFilterOffice}>
                        <option key={0} value={0}>Show All</option>
                        {
                            listOffice.map(office => {
                                return <option key={office.id_kantor} value={office.id_kantor}>{office.nama_kantor}</option>
                            })
                        }
                    </select>
                    :
                    ''
                }
            </>
        },
        {
            Header: 'Name',
            accessor: 'nama',
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["nama"] }),
            filterAll: true,
            style: { textAlign: 'center' },
        },
        {
            Header: 'New Member',
            accessor: 'students_count',
            filterable: false,
            Cell: (row) => (
                <>
                    <div onClick={() => openNewMember(row.original.code_konsultan, row.original.nama)}>
                        <span>{row.original.students_count - row.original.students_count_followup} / {row.original.students_count}</span>
                    </div>
                </>
            ),
            style: { textAlign: 'center', cursor: 'pointer' },
        },
        {
            Header: '15-30 Days',
            accessor: 'student_stuck15.length',
            filterable: false,
            Cell: (row) => (
                <>
                    <div onClick={() => openStudentStuck15(row.original.code_konsultan, row.original.nama)}>
                        <span>{row.original.student_stuck15.length - row.original.student_stuck15FollowUp} / {row.original.student_stuck15.length}</span>
                    </div>
                </>
            ),
            style: { textAlign: 'center', cursor: 'pointer' },
        },
        {
            Header: '31-44 Days',
            accessor: 'student_stuck30.length',
            filterable: false,
            Cell: (row) => (
                <>
                    <div onClick={() => openStudentStuck30(row.original.code_konsultan, row.original.nama)}>
                        <span>{row.original.student_stuck30.length - row.original.student_stuck30FollowUp} / {row.original.student_stuck30.length}</span>
                    </div>
                </>
            ),
            style: { textAlign: 'center', cursor: 'pointer' },
        },
        {
            Header: '> 45 Days',
            accessor: 'student_stuck45.length',
            filterable: false,
            Cell: (row) => (
                <>
                    <div onClick={() => openStudentStuck45(row.original.code_konsultan, row.original.nama)}>
                        <span>{row.original.student_stuck45.length - row.original.student_stuck45FollowUp} / {row.original.student_stuck45.length}</span>
                    </div>
                </>
            ),
            style: { textAlign: 'center', cursor: 'pointer' },
        },
        {
            Header: 'Golden Ticket',
            accessor: 'sekolah_peserta_count',
            filterable: false,
            Cell: (row) => (
                <>
                    <div onClick={() => openGoldenTicket(row.original.code_konsultan, row.original.nama)}>
                        <span>{row.original.sekolah_peserta_count - row.original.sekolah_peserta_count_followup} / {row.original.sekolah_peserta_count}</span>
                    </div>
                </>
            ),
            style: { textAlign: 'center', cursor: 'pointer' },
        },
        {
            Header: 'Info Session Leads',
            accessor: 'event_register_count',
            filterable: false,
            Cell: (row) => (
                <>
                    <div onClick={() => openLeadsEventSession(row.original.code_konsultan, row.original.nama)}>
                        {
                            row.original.event_register_count_followup.length > 0 ? 
                            <span>{row.original.event_register_count.length - row.original.event_register_count_followup.length} / {row.original.event_register_count.length}</span>
                            :
                            <span>{row.original.event_register_count.length} / {row.original.event_register_count.length}</span>
                        }
                    </div>
                </>
            ),
            style: { textAlign: 'center', cursor: 'pointer' },
        },
        {
            Header: 'Potensial Edufest',
            accessor: 'students_potensial_count',
            filterable: false,
            Cell: (row) => (
                <>
                    <div onClick={() => openPotensialEdufest(row.original.code_konsultan, row.original.nama)}>
                        {
                            row.original.students_potensial_count_followup > 0 ?
                            <span>{row.original.students_potensial_count - row.original.students_potensial_count_followup} / {row.original.students_potensial_count}</span>
                            :
                            <span>{row.original.students_potensial_count} / {row.original.students_potensial_count}</span>
                        }
                    </div>
                </>
            ),
            style: { textAlign: 'center', cursor: 'pointer' },
        },
        {
            Header: 'Attandance Edufest',
            accessor: 'students_attendance_count',
            filterable: false,
            Cell: (row) => (
                <>
                    <div onClick={() => openAttendanceEdufest(row.original.code_konsultan, row.original.nama)}>
                        {
                            row.original.students_attendance_count_followup > 0 ?
                            <span>{row.original.students_attendance_count - row.original.students_attendance_count_followup} / {row.original.students_attendance_count}</span>
                            :
                            <span>{row.original.students_attendance_count} / {row.original.students_attendance_count}</span>
                        }
                    </div>
                </>
            ),
            style: { textAlign: 'center', cursor: 'pointer' },
        },
        {
            Header: 'Student Ambassador',
            accessor: 'students_ambassador',
            filterable: false,
            Cell: (row) => (
                <>
                    <div onClick={() => openAmbassadorStudent(row.original.code_konsultan, row.original.nama)}>
                        {
                            row.original.students_ambassador_count_followup > 0 ?
                            <span>{row.original.students_ambassador_count - row.original.students_ambassador_count_followup} / {row.original.students_ambassador_count}</span>
                            :
                            <span>{row.original.students_ambassador_count} / {row.original.students_ambassador_count}</span>
                        }
                    </div>
                </>
            ),
            style: { textAlign: 'center', cursor: 'pointer' },
        },
        {
            Header: 'Student Task',
            accessor: 'student_task.length',
            filterable: false,
            Cell: (row) => (
                <><span onClick={() => openStudentTask(row.original.code_konsultan, row.original.nama)}>{row.original.student_task.length}</span></>
            ),
            style: { textAlign: 'center', cursor: 'pointer' },
        },
    ]

    return(
        <>
            <Breadcrumb parent="Home" title="Report Follow Up" />
            <div className="container-fluid">
                <div className="row justify-content-center mt-3">
                    <div className="col-sm-3">
                        <select name='date' value={typeDate} onChange={e => setTypeDate(e.target.value)} className='form-control mb-1'>
                            <option value='this_week'>This Week</option>
                            <option value='last_week'>Last Week</option>
                            <option value='this_month'>This Month</option>
                            <option value='last_month'>Last Month</option>
                            <option value='this_year'>This Year</option>
                            <option value='last_year'>Last Year</option>
                            <optgroup label='Quartal'>
                                <option value='1'>Quartal I</option>
                                <option value='2'>Quartal II</option>
                                <option value='3'>Quartal III</option>
                                <option value='4'>Quartal IV</option>
                            </optgroup>
                        </select>
                    </div>
                </div>
                <div className="row justify-content-center mt-5">
                    <div className="col-sm-12">
                        <span style={{fontSize:'1.5em'}} className='f-w-600'>{Moment(filterDate.start_date).format('DD MMMM yyyy')} - {Moment(filterDate.end_date).format('DD MMMM yyyy')}</span>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-sm-12">
                        <div className="card" style={{padding:'20px'}}>
                            <Loader showLoading={showLoading} />
                            {
                                dataFollowUpConsultantAll.length > 0 ?
                                <ReactTable
                                    data={dataFollowUpConsultantAll}
                                    showPagination={true}
                                    columns={column}
                                    filterable
                                    defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                    className="-striped -highlight"
                                    defaultPageSize={20}
                                />
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
                {
                    showDetailNewMember && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailNewMember code_konsultan={code_konsultan} nama={nama_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} closeDetailAll={() => closeAllDetail()} />
                            </div>
                        </div>
                    )
                }
                {
                    showDetailGoldenTicket && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailGoldenTicket code_konsultan={code_konsultan} nama={nama_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} closeDetailAll={() => closeAllDetail()} />
                            </div>
                        </div>
                    )
                }
                {
                    showDetailLeadsEventSession && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailLeadsEvent code_konsultan={code_konsultan} nama={nama_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} closeDetailAll={() => closeAllDetail()} />
                            </div>
                        </div>
                    )
                }
                {
                    showDetailStudentTask && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailStudentTask code_konsultan={code_konsultan} nama={nama_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} closeDetailAll={() => closeAllDetail()} />
                            </div>
                        </div>
                    )
                }
                {
                    showDetailStudentStuck15 && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailStudentsStuck code_konsultan={code_konsultan} nama={nama_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} type='student_stuck_15' judul='15-30 Days' closeDetailAll={() => closeAllDetail()} />
                            </div>
                        </div>
                    )
                }
                {
                    showDetailStudentStuck30 && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailStudentsStuck code_konsultan={code_konsultan} nama={nama_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} type='student_stuck_30' judul='30-45 Days' closeDetailAll={() => closeAllDetail()} />
                            </div>
                        </div>
                    )
                }
                {
                    showDetailStudentStuck45 && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailStudentsStuck code_konsultan={code_konsultan} nama={nama_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} type='student_stuck_45' judul='> 45 Days' closeDetailAll={() => closeAllDetail()} />
                            </div>
                        </div>
                    )
                }
                {
                    showDetailPotensialEdufest && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailPotensialEdufest code_konsultan={code_konsultan} nama={nama_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} closeDetailAll={() => closeAllDetail()} />
                            </div>
                        </div>
                    )
                }
                {
                    showDetailStudentAmbassador && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailAmbassadorStudent code_konsultan={code_konsultan} nama={nama_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} closeDetailAll={() => closeAllDetail()} />
                            </div>
                        </div>
                    )
                }
                {
                    showDetailAttendanceEdufest && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailAttedanceEdufest code_konsultan={code_konsultan} nama={nama_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} closeDetailAll={() => closeAllDetail()} />
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default DashboardFolowUp;