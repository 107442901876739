/* eslint-disable eqeqeq, jsx-a11y/alt-text */
import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../../components/common/loader';
import pdf from '../../../assets/images/document-logo/pdf-logo.png'
import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
import { saveAs } from "file-saver";

class EnglishTeacher extends Component {


    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            btnDisabled: false,
            title: "",
            operation: "",
            logName: "",
            errors: "",
            modal: false,
            modalDocument: false,
            file_document: '',
            id_document: '',
            dataTeacher: [],
            listDocument: [],
            dataArea: [],
            row: '',
            id_teacher: '',
            kode_teacher: '',
            id_area: '',
            nama_lengkap: '',
            email: '',
            no_wa: '',
            status: '',
            foto: '',
            image: '',
            filename: '',
            address: '',
            link_url: 'https://superstudent-bucketcourse.s3.ap-southeast-1.amazonaws.com/english-teacher/',
            modalPreview: false,
            modalDelete: false,
            urlPreview: ''
        }
    }

    componentDidMount() {
        this.getTeacherEnglishCourse();
        this.getInternalArea();
    }

    getTeacherEnglishCourse() {
        this.setState({
            showLoading: true
        });

        const url = API_URL.superStudent + '/english-course/list-teacher';
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => response.data.data)
            .then((data) => {
                this.setState({
                    showLoading: false,
                    dataTeacher: data
                });
            });
    }

    getInternalArea() {
        const url = API_URL.superStudent + '/master/area';
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => response.data.data)
            .then((data) => {
                this.setState({
                    showLoading: false,
                    dataArea: data
                });
            });
    }

    getListDocument() {
        axios.get(API_URL.superStudent + '/english-course/master-document', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        }).then((response) => {
            this.setState({
                listDocument: response.data.data
            });
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleDocument = (e) => {
        this.setState({
            file_document: e.target.files[0]
        })
    }

    onChangeFileName = (e) => {
        this.setState({
            image: e.target.files[0],
            filename: e.target.value
        })
    }

    addRow() {
        this.setState({
            modal: true,
            operation: 'add',
            title: 'Add Teacher',
            id_area: '',
            id_teacher: '',
            nama_lengkap: '',
            email: '',
            no_wa: '',
            status: 'Y',
            foto: '',
            image: '',
            filename: '',
            address: ''
        })
    }

    editRow(row) {
        this.setState({
            modal: true,
            operation: 'edit',
            title: 'Edit Teacher',
            id_area: row.id_area,
            id_teacher: row.id_teacher,
            nama_lengkap: row.nama_lengkap,
            kode_teacher: row.code_teacher,
            email: row.email,
            no_wa: row.no_wa,
            address: row.address,
            status: row.status,
        })
    }

    showModalDocument(teacher) {
        this.getListDocument();
        this.setState({
            modalDocument: true,
            id_document: '',
            id_teacher: teacher.id_teacher,
            file_document: '',
        })
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDocument: false,
            modalPreview: false,
            modalDelete: false
        })
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('document_id', this.state.id_document);
        formData.append('teacher_id', this.state.id_teacher);
        formData.append('document', this.state.file_document);

        let url = '/english-course/upload-document-teacher';
        axios.post(API_URL.superStudent + url, formData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then(() => {
            this.closeDialog();
            this.getTeacherEnglishCourse();
            this.setState({
                btnDisabled: false,
                image: "",
            });
            toast.success("Save Sucsessfully");
        })
    }

    saveRow() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('id_teacher', this.state.id_teacher);
        formData.append('kode_teacher', this.state.kode_teacher);
        formData.append('id_area', this.state.id_area);
        formData.append('nama_lengkap', this.state.nama_lengkap);
        formData.append('email', this.state.email);
        formData.append('no_wa', this.state.no_wa);
        formData.append('status', this.state.status);
        formData.append('image', this.state.image);
        formData.append('address', this.state.address)

        let url = '/english-course/create-teacher';

        if (this.state.operation == 'edit') {
            url = '/english-course/update-teacher'
        } else if (this.state.operation == 'delete') {
            url = '/english-course/delete-teacher'
        }

        axios.post(API_URL.superStudent + url, formData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((reply) => {
            this.closeDialog();
            this.getTeacherEnglishCourse();
            this.setState({
                btnDisabled: false,
                image: "",
            });
            toast.success("Save Sucsessfully");
            // window.location.reload();
        }).catch(err => {
            if (err.response.status === 422 || err.response.status === 409) {
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    deleteData() {
        axios.delete(API_URL.superStudent + '/english-course/delete-document/' + this.state.id_document, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}`, }
        }).then(() => {
            this.closeDialog();
            this.getTeacherEnglishCourse();
            toast.success("Save Sucsessfully");
        })
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Area</label>
                                    <select className='form-control' name='id_area' onChange={this.handleInput} value={this.state.id_area}>
                                        <option value=''>--Pilih Area--</option>
                                        {
                                            this.state.dataArea.map((area, index) => (
                                                <option key={index} value={area.id_area}>{area.area}</option>
                                            ))
                                        }
                                    </select>
                                    <span>{this.state.errors.id_area}</span>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Fullname</label>
                                    <input className="form-control" name="nama_lengkap" type="text" value={this.state.nama_lengkap}
                                        onChange={this.handleInput}
                                    />
                                    <span>{this.state.errors.nama_lengkap}</span>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">No. Whatsapp</label>
                                    <input className="form-control" name="no_wa" type="number" value={this.state.no_wa}
                                        onChange={this.handleInput}
                                    />
                                    <span>{this.state.errors.no_wa}</span>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Email</label>
                                    <input className="form-control" name="email" type="text" value={this.state.email}
                                        onChange={this.handleInput}
                                    />
                                    <span>{this.state.errors.email}</span>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status</label>
                                    <select className='form-control' name='status' onChange={this.handleInput} value={this.state.status}>
                                        <option value={'Y'}>Aktif</option>
                                        <option value={'N'}>Non Aktif</option>
                                    </select>
                                    <span>{this.state.errors.durasi}</span>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Image</label>
                                    <input className="form-control" name="image" type="file" id="image"
                                        onChange={(e) => this.onChangeFileName(e)}
                                    />
                                    <span>{this.state.errors.image}</span>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Address</label>
                                    <input className="form-control" name="address" type="text" value={this.state.address}
                                        onChange={this.handleInput}
                                    />
                                    <span>{this.state.errors.address}</span>

                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }


    handlePreview = (item) => {
        this.setState({
            modalPreview: true,
            urlPreview: item.pivot.document,
            id_document: item.pivot.id
        })
    }

    downloadFile = (e, filename) => {
        saveAs(filename, filename.substring(filename.lastIndexOf('/')));
    }

    deleteDocument() {
        this.setState({
            modalDelete: true,
        });
    }

    renderDeleteDialog() {
        const modal2 = this.state.modalDelete;
        return (
            <Modal isOpen={modal2} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>Delete Data</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.deleteData()}>Delete</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }


    renderPreview() {
        const url_doc = [{ uri: this.state.link_url + this.state.urlPreview }]
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalPreview} toggle={() => this.closeDialog()} size="lg">
                <ModalHeader toggle={() => this.closeDialog()}>Preview Data</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    {
                        this.state.urlPreview.split('.').pop().toLowerCase() == 'png' ||
                            this.state.urlPreview.split('.').pop().toLowerCase() == 'jpg' ||
                            this.state.urlPreview.split('.').pop().toLowerCase() == 'jpeg' ?
                            <img style={{ maxWidth: '100%', height: 'auto' }} src={this.state.link_url + this.state.urlPreview} alt="" />
                            :
                            <DocViewer
                                pluginRenderers={DocViewerRenderers}
                                documents={url_doc}
                                config={{
                                    header: {
                                        disableHeader: true,
                                        disableFileName: false,
                                        retainURLParams: false
                                    }
                                }}
                            />
                    }
                    <div className="form-row mt-1" style={{ textAlign: "end" }}>
                        <div className="col-md-12">
                            <button onClick={(e) => this.downloadFile(e, this.state.link_url + this.state.urlPreview)} className="btn btn-outline-success" type="button"><i className="fa fa-download"></i></button>
                            <button onClick={() => this.deleteDocument()} className="btn btn-outline-danger" type="button"><i className="fa fa-trash"></i></button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    renderModalDocument() {
        const { modalDocument } = this.state;

        return (
            <Modal isOpen={modalDocument} toggle={() => this.setState({ modalDocument: false })}>
                <ModalHeader toggle={() => this.setState({ modalDocument: false })}>Upload Document</ModalHeader>
                <ModalBody>
                    <form>
                        <div className='form-group'>
                            <select className='form-control' onChange={this.handleInput} name='id_document' value={this.state.id_document}>
                                <option>-Kategori-</option>
                                {
                                    this.state.listDocument.length > 0 ?
                                        this.state.listDocument.map((item) => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )) : ''
                                }
                            </select>
                        </div>
                        <div className='form-group'>
                            <input type='file' onChange={this.handleDocument} className='form-control' />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Upload</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const columns = [
            {
                Header: 'Area',
                accessor: 'area.area',
                filterable: false
            },
            {
                Header: 'Full Name',
                accessor: 'nama_lengkap',
                filterable: false
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterable: false
            },
            {
                Header: 'No. Whatsapp',
                accessor: 'no_wa',
                filterable: false
            },
            {
                Header: 'Images',
                Cell: (row) => (
                    <div>
                        <img style={{ width: '100px' }} src={row.original.foto} />
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: 'status',
                accessor: 'status',
                filterable: false,
                width: 90,
                Cell: (row) => (
                    <div>
                        {
                            row.original.status == 'Y' ?
                                <span className={'badge bg-primary'}>Aktif</span>
                                :
                                <span className={'badge bg-danger'}>Non Aktif</span>
                        }
                    </div>
                )
            },
            {
                Header: 'Action',
                style: { textAlign: 'center' },
                filterable: false,
                width: 80,
                Cell: (row) => (
                    <div>
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none' }} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                    </div>
                )
            },
        ]

        return (
            <Fragment>
                <Breadcrumb title="Teacher English Course" />
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none' }} >
                                            <div>
                                                <span>
                                                    <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                                </span>
                                            </div>
                                        </button>
                                        <br />
                                        {
                                            this.state.dataTeacher.length > 0 ?
                                                <ReactTable
                                                    data={this.state.dataTeacher}
                                                    columns={columns}
                                                    filterable
                                                    collapseOnDataChange={false}
                                                    defaultFilterMethod={(filter, row) =>
                                                        String(row[filter.id]) === filter.value}
                                                    defaultPageSize={100}
                                                    className="-striped -highlight"
                                                    SubComponent={row => {
                                                        return (
                                                            <div className="p-2">
                                                                <button disabled={this.state.btnDisabled} className='btn btn-success mb-1' onClick={() => this.showModalDocument(row.original)} >Add Document</button>
                                                                <table className="row">
                                                                    {
                                                                        row.original.documents.length > 0 ?
                                                                            row.original.documents.map((item, i) => {
                                                                                return (
                                                                                    <div key={i} className='col-md-2 mb-3'>
                                                                                        <div className='product-box'>
                                                                                            <div className='product-img'>
                                                                                                {
                                                                                                    item.pivot.document.split('.').pop() === 'pdf' ?
                                                                                                        <img onClick={() => this.handlePreview(item)} style={{ maxHeight: '130px' }} className='img-thumbnail' src={pdf} alt={item.pivot.document} />
                                                                                                        :
                                                                                                        <img onClick={() => this.handlePreview(item)} style={{ maxHeight: '150px' }} className='img-thumbnail' src={this.state.link_url + item.pivot.document} alt={item.pivot.document} />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            : ''
                                                                    }

                                                                </table>
                                                            </div>
                                                        )
                                                    }}
                                                />
                                                :
                                                <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderModalDocument()}
                {this.renderPreview()}
                {this.renderDeleteDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(EnglishTeacher));