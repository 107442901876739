/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import Breadcrumb from '../../components/common/breadcrumb';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Moment from 'moment';

class Scholarship extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: {},
            modal: false,
            modalDelete: false,
            btnDisabled: false,
            dataScholarship: [],
            dataCampus: [],
            dataCountry: [],
            id_scholarship: "",
            code_school_rep: "",
            scholarship_name: "",
            scholarship_type: "1",
            country: "",
            program: "1",
            link: "",
            description: "",
            requirement: "",
            programs: "",
            open_reg: "",
            close_reg: "",
            requirement_option: [
                { 'name' : 'IELTS' },
                { 'name' : 'TOEFL' },
                { 'name' : 'Essay' },
                { 'name' : 'Recommendation Letter' },
            ],
            program_option: [
                { 'program' : 'Cerviticate 3' },
                { 'program' : 'Cerviticate 4' },
                { 'program' : 'Diploma' },
                { 'program' : 'Advanced Diploma' },
                { 'program' : 'Bachelor' },
                { 'program' : 'Master' },
                { 'program' : 'Pre Master' },
                { 'program' : 'Ph.D' },
                { 'program' : 'Foundation' },
            ]
        }
    }

    componentDidMount() {
        this.getDataScholarship();
        this.getDataCampus();
        this.getCountry();
    }

    getDataCampus(){
        const url = API_URL.superStudent+'/universities';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dataCampus: data })
        })
    }

    getDataScholarship() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/website/scholarship';
        axios.get(url, { headers: 
            { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataScholarship:data.data
            });
        });
    }

    getCountry = async () => {
        await axios.get(API_URL.superStudent+'/master/country')
        .then(response => response.data.data)
        .then(result => {
            this.setState({ dataCountry: result })
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleRequirement = (e) => {
        this.setState({
            requirement: JSON.stringify(e)
        })
    }

    handleProgram = (e) => {
        this.setState({
            programs: JSON.stringify(e)
        })
    }

    handleCampus = (e) => {
        this.setState({
            code_school_rep: e[0].code
        })
    }

    addRow() {
        this.setState({
            modal: true,
            title: 'Add Scholarship Data',
            operation: 'add',
            logName: 'ADD-scholarship',
            code_school_rep: "",
            scholarship_name: "",
            scholarship_type: "",
            country: "",
            program: "",
            link: "",
            description: "",
            open_reg: "",
            close_reg: "",
            requirement: ""
        });
    }

    editRow(row) {
        axios.get(API_URL.superStudent+'/website/scholarship/'+row.id_scholarship, {
            headers: {
              'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                modal: true,
                title: 'Edit Scholarship Data',
                operation: 'edit',
                logName: 'EDIT-SCHOLARSHIP',
                id_scholarship: result.id_scholarship,
                code_school_rep: result.code_school_rep,
                scholarship_name: result.scholarship_name,
                scholarship_type: result.scholarship_type,
                country: result.country,
                programs: result.program,
                link: result.link,
                description: result.description,
                expired: result.expired,
                requirement: result.requirement,
                open_reg: result.open_reg,
                close_reg: result.close_reg,
            })
        })
    }

    deleteRow(row) {
        this.setState({
            id_scholarship: row.id_scholarship,
            modalDelete: true,
            title: 'Delete Scholarship Data',
            operation: 'delete',
            logName: 'DELETE-SCHOLARSHIP'
        })
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('code_school_rep',this.state.code_school_rep); 
        formData.append('scholarship_name',this.state.scholarship_name);
        formData.append('scholarship_type',this.state.scholarship_type);
        formData.append('country',this.state.country);
        formData.append('program',this.state.programs);
        formData.append('link',this.state.link);
        formData.append('description',this.state.description);
        formData.append('requirement',this.state.requirement);
        formData.append('open_reg',this.state.open_reg);
        formData.append('close_reg',this.state.close_reg);

        formData.append('user',this.props.dataId);
        formData.append('token',this.props.dataToken);

        let url = '/website/scholarship';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/website/scholarship/'+this.state.id_scholarship
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/website/scholarship/'+this.state.id_scholarship
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            if (reply.data.status == 'Success') {
                this.closeDialog();
                this.getDataScholarship();
                this.setState({ btnDisabled: false });
                if (this.state.operation == "add") {
                    toast.success("Save Sucsessfully");
                } else {
                    toast.success("Update Sucsessfully");
                }   
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
            city: "",
            country: "",
            harga: "",
            itenary: "",
            min_pax: "",
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Nama Kampus</label>
                                    <Typeahead
                                        id="multiple-typeahead"
                                        clearButton
                                        labelKey="name"
                                        options={this.state.dataCampus}
                                        onChange={(e) => this.handleCampus(e)}
                                        placeholder="Choose a campus..."
                                    />
                                    <span>{this.state.errors.close_reg}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Scholarship Name</label>
                                    <input className="form-control" name="scholarship_name" type="text" id="scholarship_name" onChange={(e) => this.handleInput(e)} value={this.state.scholarship_name} />
                                    <span>{this.state.errors.scholarship_name}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Scholarship Type</label>
                                    <select className="form-control" name="scholarship_type" type="text" id="scholarship_type" onChange={this.handleInput} value={this.state.scholarship_type}>
                                        <option value="1">Fully Funded</option>
                                        <option value="2">Partially Funded</option>
                                    </select>
                                    <span>{this.state.errors.scholarship_type}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Country</label>
                                    <select className="form-control btn-square" name='country' onChange={this.handleInput} value={this.state.country}>
                                        <option value="0">--Select Country--</option>
                                        {
                                            this.state.dataCountry.length > 0 ?
                                            this.state.dataCountry.map(country => {
                                                return <option key={country.code} value={country.code}>{country.name}</option>
                                            })
                                            :
                                            ''
                                        }
                                    </select>
                                    <span>{this.state.errors.country}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Program</label>
                                    <Typeahead
                                        id="multiple-typeahead"
                                        clearButton
                                        labelKey="program"
                                        multiple
                                        options={this.state.program_option}
                                        onChange={(e) => this.handleProgram(e)}
                                        placeholder="Choose a program..."
                                    />
                                    <span>{this.state.errors.program}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Link</label>
                                    <input className="form-control" name="link" type="text" id="link" onChange={(e) => this.handleInput(e)} value={this.state.link} />
                                    <span>{this.state.errors.link}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Description</label>
                                    <textarea className='form-control' name="description" type="text" id="description"  value={this.state.description}
                                              onChange={(e) => this.handleInput(e)}>
                                    </textarea>
                                    <span>{this.state.errors.description}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Open Registration</label>
                                    <input className="form-control" name="open_reg" type="date" id="open_reg"  value={this.state.open_reg}
                                        onChange={(e) => this.handleInput(e)}
                                    />
                                    <span>{this.state.errors.expired}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Close Registration</label>
                                    <input className="form-control" name="close_reg" type="date" id="close_reg"  value={this.state.close_reg}
                                        onChange={(e) => this.handleInput(e)}
                                    />
                                    <span>{this.state.errors.close_reg}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Requirement</label>
                                    <Typeahead
                                        id="multiple-typeahead"
                                        clearButton
                                        labelKey="name"
                                        multiple
                                        options={this.state.requirement_option}
                                        onChange={(e) => this.handleRequirement(e)}
                                        placeholder="Choose a requirement..."
                                    />
                                    <span>{this.state.errors.close_reg}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const columns = [
            {
                Header: 'Scholarship Name',
                accessor: 'scholarship_name',
                filterable: false
            },
            {
                Header: 'Universities',
                accessor: 'universities.nama_sekolah',
                filterable: false
            },
            {
                Header: 'Country',
                accessor: 'country.countryname',
                filterable: false
            },
            {
                Header: 'Scholarship Type',
                Cell: (row) => (
                    <div>                     
                        { row.original.scholarship_type == 1 ? 'Fully Funded' : 'Partially Funded' }
                    </div>
                ),
                sortable: false,
                filterable: false
            },
            {
                Header: 'Link',
                accessor: 'link',
                filterable: false
            },
            {
                Header: 'Open Registration',
                Cell: (row) => (
                    <div>
                        { Moment(row.original.open_reg).local().format("DD-MMM-YYYY") }
                    </div>
                ),
                sortable: false,
                filterable: false
            },
            {
                Header: 'Close Registration',
                Cell: (row) => (
                    <div>
                        { Moment(row.original.close_reg).local().format("DD-MMM-YYYY") }
                    </div>
                ),
                sortable: false,
                filterable: false
            },
            {
                Header: 'Date Registration',
                Cell: (row) => (
                    <div>
                        { Moment(row.original.created_at).local().format("DD-MMM-YYYY") }
                    </div>
                ),
                sortable: false,
                filterable: false
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <Breadcrumb title="Scholarship" parent="Scholarship" />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                                            <div>
                                            <span>
                                                <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                            </span>
                                            </div>
                                        </button>
                                        <br/>
                                        {
                                            this.state.dataScholarship.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataScholarship}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={50}
                                                className="-striped -highlight"
                                                SubComponent={row => {
                                                    const data = row.original;
                                                    return(
                                                        <div style={{ padding: "20px" }}>
                                                            <div style={{paddingLeft:"10px", fontStyle:"italic", color:"#A6A6A6"}}>
                                                                <div className="row">
                                                                    <div className="" align="left" style={{ color:"black" }}>Description <span>:</span></div>
                                                                    <p className="col-md-9" align="left">{data.description}</p>
                                                                </div>
                                                                {
                                                                    row.original.require != null ? 
                                                                    <div className="row">
                                                                        <div align="left" style={{ color:"black" }}>Requirement <span>:</span></div>
                                                                        {
                                                                            row.original.require.map(item => (
                                                                                <p className="col-md-2" align="left">{item.name},</p>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                    :
                                                                    ''
                                                                }

                                                                {
                                                                    row.original.program != null ? 
                                                                    <div className='row'>
                                                                        <div align="left" style={{ color:"black" }}>Program <span>:</span></div>
                                                                        {
                                                                            row.original.program.map(item => (
                                                                                <p className="col-md-2" align="left">{item.program},</p>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                    :
                                                                    ''
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                             /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(Scholarship));