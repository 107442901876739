/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import ReactTable from 'react-table';
import API_URL from '../../../ApiUrl';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify'
import { ExportCSV } from '../../../services/ExportCSV';
import Moment from 'moment';
import { matchSorter } from 'match-sorter';
import { Typeahead } from 'react-bootstrap-typeahead';

class StudentData extends Component {
  constructor(props) {
    super(props);
    this.renderEditable = this.renderEditable.bind(this);
    this.state = {
      data: [],
      filtered: [],
      dataCategory: [],
      dataCampus: [],
      dataSekolah: [],
      dataStatus: [],
      consultants: [],
      typeUpdate: "",
      detailStudent: "",
      potensial: "",
      code_status: "",
      code_school: "",
      code_sekolah_asal: "",
      code_pilihan_kampus: "",
      code_consulstant: "",
      modalPotensial: false,
      code_ambassador: this.props.match.params.codeAmbassador,
      id_category: this.props.match.params.idCategory,
      modal: false,
      modalTodo: false,
      code_students: '',
      codeStudent: '',
      showConfirm: false,
      showSuccess: false,
      filterRequestor: '',
      filterGrantedDate: '',
    }
  }

  componentDidMount() {
    this.loadData();
    this.getCategory();
    this.getDataCampus();
    this.getStatus();
  }

  renderEditable(cellinfo) {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const data = [...this.state.data]
          data[cellinfo.index][cellinfo.column.id] = e.target.innerHTML;
          this.handleEditable(cellinfo.column.id, cellinfo.original.code_students, e.target.innerHTML);
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.data[cellinfo.index][cellinfo.column.id]
        }}
      />
    )
  }

  handleEditable(kolom, code_students, value) {
    var formData = new FormData();
    formData.append('code_students', code_students)
    formData.append('columns', kolom)
    formData.append('value', value);

    axios.post(API_URL.superStudent + '/student/update-students-by-columns', formData, {
      headers: {
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => {
        this.loadData();
        this.closeModal();
      })
      .catch(error => {
        toast.error('there is an error ' + error)
      })
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  }

  SetFilterRequestor = (e) => {
    const newData = e.target.value;
    this.setState(prevState => {
      let filterRequestor = newData;
      return { filterRequestor };
    }, () => {
      this.onFilteredChangeCustom(newData, "code_students_category");
    })
  }

  SetFilterRequestorDate(e) {
    const newData = e.target.value;
    this.setState(prevState => {
      let filterGrantedDate = newData;
      return { filterGrantedDate };
    }, () => {
      this.onFilteredChangeCustom(newData, "granted_date");
    })
  }

  closeModal() {
    this.setState({
      modal: false,
      modalTodo: false,
      modalPotensial: false,
      code_students: ''
    });
  }

  editDetail = (row, type) => {
    this.getDataSekolah(row.id_propinsi)
    this.setState({
      typeUpdate: type,
      detailStudent: row,
      potensial: row.code_students_category,
      code_pilihan_kampus: row.code_school_rep,
      code_status: row.code_status,
      code_school: row.code_school,
      modalPotensial: true
    })
  }

  showAlert = (row) => {
    this.setState({
      showConfirm: true,
      codeStudent: row.code_students
    })
  }

  setConsultant = async (row) => {
    this.setState({
      modal: true,
      codeStudent: row.code_students
    })
    await this.loadDataConsultant()
  }

  loadDataConsultant = async () => {
    const url = API_URL.superStudent + '/consultant/jobdesk';
    let formData = new FormData()
    formData.append('jobdesk', JSON.stringify(['JD004']))
    await axios.post(url, formData, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then(result => this.setState({ consultants: result, modal: true }))
      .catch(error => console.log(error))
  }

  updateCommission = () => {
    let formData = new FormData()
    formData.append('code_student', this.state.codeStudent)
    axios.post(API_URL.superStudent + '/ambassador/commission-student', formData, {
      headers: {
        Authorization: `Bearer ${this.props.dataToken}`
      }
    })
    .then(response => {
      this.setState({
        showSuccess: true,
        showConfirm: false
      },() => {
        this.loadData()
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  updateStudent() {
    this.state.typeUpdate == "Potensial" ?
      this.handleEditable("code_students_category", this.state.detailStudent.code_students, this.state.potensial)
      :
      this.state.typeUpdate == "University" ?
        this.handleEditable("code_school_rep", this.state.detailStudent.code_students, this.state.code_pilihan_kampus)
        :
        this.state.typeUpdate == "Sekolah" ?
          this.handleEditable("code_school", this.state.detailStudent.code_students, this.state.code_sekolah_asal)
          :
          this.handleEditable("code_status", this.state.detailStudent.code_students, this.state.code_status)
  }

  handleSubmit() {
    var formData = new FormData();
    formData.append('code_konsultan', this.state.code_consulstant)
    formData.append('code_students', this.state.codeStudent)

    axios.post(API_URL.superStudent + '/student/set-consultant', formData, {
      headers: {
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => {
        this.loadData();
        this.closeModal();
      })
      .catch(error => {
        toast.error('there is an error ' + error)
      })
  }

  setSekolahAsal = (e) => {
    const newData = e.length > 0 ? e[0].kode_sekolah : "";
    this.setState(prevState => {
      let code_sekolah_asal = Object.assign({}, prevState.code_sekolah_asal);
      code_sekolah_asal = newData;
      return { code_sekolah_asal };
    })
  }

  setKampusTujuan = (e) => {
    const newData = e.length > 0 ? e[0].code : "";
    this.setState(prevState => {
      let code_pilihan_kampus = Object.assign({}, prevState.code_pilihan_kampus);
      code_pilihan_kampus = newData;
      return { code_pilihan_kampus };
    })
  }

  async loadData() {
    await axios.get(API_URL.superStudent + '/ambassador/students-data/' + this.state.code_ambassador, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({
          data: data
        })
      })
  }

  getDataSekolah(province_id) {
    const url = API_URL.superStudent + '/sekolah-indonesia?province_id=' + province_id;
    axios.get(url, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataSekolah: data })
      })
  }

  getCategory() {
    const url = API_URL.superStudent + '/master/student-category';
    axios.get(url, {
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataCategory: data })
      })
  }

  getStatus() {
    const url = API_URL.superStudent + '/master/status';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataStatus: data })
      })
  }

  getDataCampus() {
    const url = API_URL.superStudent + '/universities';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    }).then(response => response.data)
      .then((data) => {
        this.setState({ dataCampus: data.data })
      })
  }

  renderModalPotensial() {
    return (
      <Modal className="modal-dialog" isOpen={this.state.modalPotensial} toggle={() => this.closeModal()} size="lg">
        <ModalHeader toggle={() => this.closeModal()}><h5 className='mb-3'>Edit {this.state.typeUpdate} {this.state.detailStudent.nama}</h5></ModalHeader>
        <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <form className="needs-validation" noValidate="">
            <div className="form-row">
              <div className="col-md-12 mb-3">
                {
                  this.state.typeUpdate == "Potensial" ?
                    <select className="form-control" name="potensial" value={this.state.potensial} onChange={this.handleInput}>
                      {
                        this.state.dataCategory.map(item => {
                          return <option key={item.code_students_category} value={item.code_students_category}>{item.category}</option>
                        })
                      }
                    </select>
                    :
                    this.state.typeUpdate == "University" ?
                      <Typeahead
                        id="basic-typeahead"
                        labelKey="name"
                        name="code_pilihan_kampus"
                        options={this.state.dataCampus}
                        onChange={(e) => this.setKampusTujuan(e)}
                        placeholder="--Pilihan Kampus--"
                      />
                      :
                      this.state.typeUpdate == "Sekolah" ?
                        <Typeahead
                          id="basic-typeahead"
                          labelKey="nama_sekolah"
                          name="code_sekolah_asal"
                          options={this.state.dataSekolah}
                          onChange={(e) => this.setSekolahAsal(e)}
                          placeholder="--Sekolah Asal--"
                        />
                        :
                        <select name="code_status" onChange={this.handleChange} value={this.state.code_status} className="form-control">
                          <option key={0} value="">--Kategori--</option>
                          {
                            this.state.dataStatus.map(status => {
                              return <option key={status.code} value={status.code}>{status.name}</option>
                            })
                          }
                        </select>
                }

              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.updateStudent()}>Save</Button>
          <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderModalEditConsultant() {
    return (
      <Modal className="modal-dialog" isOpen={this.state.modal} toggle={() => this.closeModal()} size="lg">
        <ModalHeader toggle={() => this.closeModal()}><h5 className='mb-3'>Select Consultant</h5></ModalHeader>
        <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <form className="needs-validation" noValidate="">
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="id_kabkota">Pilih Consultant<span className='text-danger'>*</span> </label>
                <select className="form-control" onChange={(e) => this.setState({ code_consulstant: e.target.value })} type="text" value={this.state.code_consulstant}>
                  <option value="0">--Pilih Consultant--</option>
                  {this.state.consultants.map((data) => (
                    <option key={data.code_konsultan} value={data.code_konsultan}>{data.nama}</option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.handleSubmit()}>Save</Button>
          <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render() {

    const columns = [
      {
        Header: 'Code',
        accessor: 'code_students',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["code_students"] }),
        filterAll: true
      },
      {
        Header: 'Member Code',
        accessor: 'code_member',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["code_member"] }),
        filterAll: true
      },
      {
        Header: 'Source',
        accessor: 'sumber_name',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["sumber_name"] }),
        filterAll: true
      },
      {
        Header: 'Name',
        accessor: 'nama',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["nama"] }),
        filterAll: true,
        Cell: this.renderEditable
      },
      {
        Header: 'Telp',
        accessor: 'no_hp',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["no_hp"] }),
        filterable: true,
        Cell: this.renderEditable
      },
      {
        Header: 'Kategori',
        accessor: 'status_name',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["status_name"] }),
        filterAll: true,
        Cell: (row) => (
          <div onClick={e => this.editDetail(row.original, "Kategori")}>{row.original.status_name}</div>
        )
      },
      {
        Header: 'Progress',
        accessor: 'progress_name',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["progress_name"] }),
        filterAll: true
      },
      {
        Header: 'Consultant',
        accessor: 'consultant_name',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["consultant_name"] }),
        filterAll: true
      },
      {
        Header: 'Sekolah',
        accessor: 'sekolah_name',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["sekolah_name"] }),
        filterAll: true,
        Cell: (row) => (
          <div onClick={e => this.editDetail(row.original, "Sekolah")}>{row.original.sekolah_name}</div>
        )
      },
      {
        Header: 'University',
        accessor: 'university_name',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["university_name"] }),
        filterAll: true,
        Cell: (row) => (
          <div onClick={e => this.editDetail(row.original, "University")}>{row.original.university_name}</div>
        )
      },
      {
        Header: 'Potensial',
        accessor: 'category_name',
        style: { textAlign: 'center' },
        filterable: true,
        Cell: (row) => (
          <div onClick={e => this.editDetail(row.original, "Potensial")}>
            {
              row.original.code_students_category == 'SC00001' ?
                <span className={'badge bg-info'}>{row.original.category_name}</span>
                :
                row.original.code_students_category == 'SC00002' ?
                  <span className={'badge bg-success'}>{row.original.category_name}</span>
                  :
                  <span className={'badge bg-danger'}>{row.original.category_name}</span>
            }
          </div>
        ),
        Filter: () =>
          <select value={this.state.filterRequestor || ''} onChange={(e) => this.SetFilterRequestor(e)}>
            <option key={0} value=''>Show All</option>
            {
              this.state.dataCategory.map(item => {
                return <option key={item.code_students_category} value={item.code_students_category}>{item.category}</option>
              })
            }
          </select>,
      },
      {
        Header: 'Date Granted',
        accessor: 'granted_date',
        filterable: true,
        Cell: (row) => (
          <>
            {row.original.granted_date !== undefined && row.original.granted_date !== null ?
              Moment(row.original.granted_date).format('DD/MM/YYYY')
              : ''
            }
          </>
        ),
        Filter: () =>
          <input type='date' className='form-control' name='date_input' onChange={(e) => this.SetFilterRequestorDate(e)} />
      },
      {
        Header: 'Status Payment',
        accessor: 'ambassador_commission',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["ambassador_commission"] }),
        filterAll: true
      },
      {
        Header: <b>Action</b>,
        id: 'commission',
        accessor: str => "commission",
        Cell: (row) => (
          <div>
            <button onClick={() => this.showAlert(row.original)} className="btn btn-success btn-xs" style={{ border: 'none', display: row.original.ambassador_commission === 'paid' ? 'none' : '' }} ><i className="fa fa-money" ></i></button> &nbsp;
            <button onClick={() => this.setConsultant(row.original)} className='btn btn-primary btn-xs'> <i className="fa fa-user" ></i></button>
          </div>
        ),
        style: {
          textAlign: 'center',
        },
        width: 100,
        sortable: false,
        filterable: false
      }
    ]

    // const columns2 = [
    //   {
    //     Header: 'Code Student',
    //     accessor: 'code_students',
    //     style: { textAlign: 'center' },
    //     filterMethod: (filter, rows) =>
    //       matchSorter(rows, filter.value, { keys: ["code_students"] }),
    //     filterAll: true,
    //   },
    //   {
    //     Header: 'Kode Member',
    //     accessor: 'code_member',
    //     style: { textAlign: 'center' },
    //     filterMethod: (filter, rows) =>
    //       matchSorter(rows, filter.value, { keys: ["code_member"] }),
    //     filterAll: true,
    //   },
    //   {
    //     Header: 'Nama',
    //     accessor: 'nama',
    //     style: { textAlign: 'center' },
    //     filterMethod: (filter, rows) =>
    //       matchSorter(rows, filter.value, { keys: ["nama"] }),
    //     filterAll: true,
    //   },
    //   {
    //     Header: 'Telepon',
    //     accessor: 'no_hp',
    //     style: { textAlign: 'center' },
    //     filterMethod: (filter, rows) =>
    //       matchSorter(rows, filter.value, { keys: ["no_hp"] }),
    //     filterAll: true,
    //   },
    //   {
    //     Header: 'Email',
    //     accessor: 'email',
    //     style: { textAlign: 'center' },
    //     filterMethod: (filter, rows) =>
    //       matchSorter(rows, filter.value, { keys: ["email"] }),
    //     filterAll: true,
    //   },
    //   {
    //     Header: 'Konsultan',
    //     accessor: 'consultant_name',
    //     style: { textAlign: 'center' },
    //     filterMethod: (filter, rows) =>
    //       matchSorter(rows, filter.value, { keys: ["consultant_name"] }),
    //     filterAll: true,
    //   },
    //   {
    //     Header: 'Progress',
    //     accessor: 'progress_name',
    //     style: { textAlign: 'center' },
    //     filterMethod: (filter, rows) =>
    //       matchSorter(rows, filter.value, { keys: ["progress_name"] }),
    //     filterAll: true,
    //   },
    //   {
    //     Header: 'Status',
    //     accessor: 'status_name',
    //     style: { textAlign: 'center' },
    //     filterMethod: (filter, rows) =>
    //       matchSorter(rows, filter.value, { keys: ["status_name"] }),
    //     filterAll: true,
    //   }
    // ]

    return (
      <>
        <SweetAlert
          show={this.state.showConfirm}
          warning
          showCancel
          confirmBtnText="Yes, Paid!"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title="Anda yakin?"
          onConfirm={() => this.updateCommission()}
          onCancel={() => this.setState({ showConfirm: false })}
          focusCancelBtn
        />
        <SweetAlert show={this.state.showSuccess} success title="Berhasil" onConfirm={() => null}>
          Status pembayaran komisi di update!
        </SweetAlert>
        <div className="card">
          <div className="card-header">
            <h5>Data Students</h5>
          </div>
          <div className="card-body">
            <div className='row mb-3'>
              <div className="col-sm-12">
                <div className="text-right">
                  <ExportCSV csvData={this.state.data} fileName={"data-student-ambassador"} />
                </div>
              </div>
            </div>
            <ReactTable
              data={this.state.data}
              columns={columns}
              filterable
              onFilteredChange={(filtered, column, value) => {
                this.onFilteredChangeCustom(value, column.id || column.accessor);
              }}
              defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
              defaultPageSize={10}
              className="-striped -highlight"
              SubComponent={row => {
                return (
                  <div style={{ padding: '20px' }} className='row'>
                    {
                      row.original.progress_log.length > 0 ?
                        row.original.progress_log.map(item => (
                          <div className='col-md-4'>
                            <h6>Comment : {item.code}</h6>
                          </div>
                        ))
                        :
                        ''
                    }

                  </div>
                )
              }}
            />
          </div>
        </div>
        {this.renderModalPotensial()}
        {this.renderModalEditConsultant()}
      </>
    )
  }
}

const reduxState = (state) => ({
  dataToken: state.Login.lgnInitialize,
  dataUser: state.Login.detailUser,
  codeUsers: state.Login.detailUser.code_users,
  codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(StudentData));