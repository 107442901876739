
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import Moment from 'moment'
import { toast } from 'react-toastify';
import axios from 'axios'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import API_URL from '../../../ApiUrl'
import Loader from '../../../components/common/loader'


class EnglishStudentAttendance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            attendance: null,
            filtered: [],
            dataGroups: [],
            loading: false,
            searchInput: '',
            modalEdit: false,
            kode_register: '',
            date_time: '',
            textSave: 'Save',
            btnDisabled: false,

        }
    }

    handleInnput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    loadDataAttendance = () => {
        this.setState({
            loading: true
        })
        let formData = new FormData()
        //formData.append('teacher_id', this.props.userableData.userable.id_teacher)
        formData.append('code_group', this.state.searchInput)
        axios.post(API_URL.superStudent + '/english-division/list-attendance', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then((response) => {
                this.setState({
                    attendance: response.data.data,
                    loading: false
                })
            })
    }

    loadDataStudentGroup = () => {
        let formData = new FormData()
        formData.append('code_group', this.state.searchInput)
        axios.post(API_URL.superStudent + '/english-division/student-group', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then((response) => {
                this.setState({
                    dataGroups: response.data.data
                })
                console.log(response.data.data)
            })
    }

    filter = () => {
        this.loadDataAttendance()
    }

    showModalEditAttendance = async () => {
        this.loadDataStudentGroup()
        this.setState({
            modalEdit: true
        })
    }

    editDate = () => {
        this.setState({
            textSave: 'Saveing...',
            btnDisabled: true,
        })
        let formData = new FormData()
        formData.append('kode_register', this.state.kode_register)
        formData.append('date_time', this.state.date_time)
        formData.append('code_group', this.state.searchInput)

        axios.post(API_URL.superStudent + '/english-division/edit-attendance', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                this.loadDataAttendance()
                toast.success('successful data update')
                this.setState({
                    textSave: 'Save',
                    btnDisabled: false,
                    modalEdit: false
                })
            })
            .catch(() => {
                toast.error('there is an error')
                this.setState({
                    textSave: 'Save',
                    btnDisabled: false,
                    modalEdit: false
                })
            })
    }

    closeDialog = () => {
        this.setState({
            modalEdit: false
        })
    }

    renderModalEdit() {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modalEdit} toggle={() => this.setState({ modalEdit: false })}>
                    <ModalHeader toggle={() => this.setState({ modalEdit: false })}>Delete Task</ModalHeader>
                    <ModalBody>
                        <form>
                            <div className='form-group'>
                                <label className='control-label'>Name</label>
                                <select name='kode_register' className='form-control' value={this.state.kode_register} onChange={this.handleInnput}>
                                    <option value=''>--Select--</option>
                                    {
                                        this.state.dataGroups.length > 0 ?
                                            this.state.dataGroups.map((item, index) => (
                                                <option key={index} value={item.kode_register}>{item.nama_lengkap}</option>
                                            )) : ''
                                    }
                                </select>
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Date & Time</label>
                                <input type='datetime-local' className='form-control' onChange={this.handleInnput} name='date_time' value={this.state.date_time} />
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="danger" onClick={(e) => this.editDate()}>{this.state.textSave}</Button>
                        <Button color="secondary" onClick={() => this.setState({ modalEdit: false })}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    render() {
        return (
            <>
                <div className="tab-content active default" id="tab-1">
                    <div className="card">
                        <div className='row'>
                            <div className="col-md-6">
                                <div className="card-header">
                                    <div className="form-inline">
                                        <input
                                            className="form-control"
                                            placeholder="input code group"
                                            size="large"
                                            name="searchInput"
                                            value={this.state.searchInput}
                                            onChange={this.handleInnput}
                                            label="Search"
                                        />
                                        <Button className='ml-1' onClick={() => this.filter()} color="primary"><i className="fa fa-search"></i></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row m-1">
                                <div className="col-sm-12">
                                    {
                                        this.state.attendance != null ?
                                            <button className='btn btn-info btn-xs float-right mb-1' type='button' onClick={() => this.showModalEditAttendance()}>
                                                <i className="fa fa-pencil fa-2x"></i>
                                            </button> : ''
                                    }
                                    <div className='table-responsive'>
                                        <table style={{ fontSize: '10px' }} className='table table-striped table-hover table-bordered'>
                                            <thead className='tbl-strip-thad-bdr'>
                                                <tr className='table-secondary'>
                                                    <th style={{ textAlign: 'center', alignContent: 'center', width: '220px' }} rowSpan={2} scope="col">Name</th>
                                                    <th style={{ textAlign: 'center' }} colSpan={this.state.attendance != null ? this.state.attendance.notes_count : 1} scope="col">Meet</th>
                                                </tr>
                                                <tr>
                                                    {
                                                        this.state.attendance != null ?
                                                            [...Array(this.state.attendance.notes_count)].map((_, index) => (
                                                                <th style={{ textAlign: 'center' }} scope='col' key={index}>{index + 1}</th>
                                                            ))
                                                            : <th style={{ textAlign: 'center' }}>Empty Data</th>

                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.attendance != null ?
                                                        this.state.attendance.register.map((item, index) => (
                                                            <tr key={'row' + index}>
                                                                <th>{item.nama_lengkap}</th>
                                                                {
                                                                    item.attendances.map((col, index) => (
                                                                        <td key={'date' + index} style={{ textAlign: 'center' }}>
                                                                            <span>{Moment(col.date_time).format('DD MMM YY')}</span><p className='f-12 text-center'>{Moment(col.date_time).format('HH:mm')}</p>
                                                                        </td>
                                                                    ))
                                                                }
                                                            </tr>
                                                        )) : ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <Loader showLoading={this.state.loading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalEdit()}
            </>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})


export default connect(reduxState, null)(withRouter(EnglishStudentAttendance));
