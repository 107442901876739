/* eslint-disable eqeqeq */

import React, { Component, Fragment } from 'react';
import Countup from 'react-countup';
import { connect } from 'react-redux';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { Chart } from "react-google-charts"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import DataTablesPromoMember from '../../components/tables/dataTablesPromoMember'
import { Bar } from 'react-chartjs-2';
import ReportMemberSource from './report-member-source';
import setRangeDate from '../../helper/general-function'

class MembershipHomeComponent extends Component {

    constructor(props) {
        super(props)
        let data = setRangeDate('this_year')
        this.state = {
            rekapMemberKonsultan: [],
            memberSource: [],
            totalMember: [0, []],
            type: 'monthly',
            memberAktif: [0, []],
            memberNew: [0, []],
            start_date: data.start,
            end_date: data.end,
            dataGraphic: [],
        }
    }

    componentDidMount() {
        this.loadDataGraphic()
        this.getMemberAll();
        this.getMemberAktif();
        this.getMemberNew();
        this.getRekapKonsultan()
    }

    getRekapKonsultan() {
        let formData = new FormData()
        formData.append('start_date', this.state.start_date)
        formData.append('end_date', this.state.end_date)
        const url = API_URL.superStudent + '/consultant-peformance/all-members';
        axios.post(url, formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        }).then(response => response.data).then((data) => {
            this.setState({ rekapMemberKonsultan: data.data })
        });
    }

    getMemberAll() {
        const url = API_URL.superStudent + '/members/total-members';
        let formData = new FormData()
        formData.append('status_payment', '');
        axios.post(url, formData, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
            .then(response => response.data.data)
            .then((data) => {
                this.setState({ totalMember: data })
            });
    }

    loadDataGraphic() {
        let formData = new FormData()
        formData.append('start_date', this.state.start_date)
        formData.append('end_date', this.state.end_date)
        formData.append('type', this.state.type)
        axios.post(API_URL.superStudent + '/members/report-member', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => this.setState({ dataGraphic: response.data.data }))
            .catch(error => console.log(error))
    }

    handlePickerStartMonth = (e) => {
        this.setState({ start_date: Moment(e).startOf('month').format('YYYY-MM-DD') }, () => {
            this.loadDataGraphic()
            this.getRekapKonsultan()
        })
    }

    handlePickerEndMonth = (e) => {
        this.setState({ end_date: Moment(e).endOf('month').format('YYYY-MM-DD') }, () => {
            this.loadDataGraphic()
            this.getRekapKonsultan()
        })
    }

    getMemberAktif() {
        const url = API_URL.superStudent + '/members/total-members';
        let formData = new FormData()
        formData.append('status_payment', 'Y');
        axios.post(url, formData, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
            .then(response => response.data.data)
            .then((data) => {
                this.setState({ memberAktif: data })
            });
    }

    getMemberNew() {
        const url = API_URL.superStudent + '/members/total-members';
        let formData = new FormData()
        formData.append('status_payment', 'N');
        axios.post(url, formData, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
            .then(response => response.data.data)
            .then((data) => {
                this.setState({ memberNew: data })
            });
    }

    changeFilterType = () => {
        let type = this.state.type == 'monthly' ? 'yearly' : 'monthly'
        this.setState({
            type
        }, () => {
            if (this.state.type == 'monthly') {
                this.setState({
                    start_date: Moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: Moment().endOf('month').format('YYYY-MM-DD')
                }, () => this.loadDataGraphic())
            }
            else {
                this.setState({
                    start_date: Moment('2020-01-01').startOf('year').format('YYYY-MM-DD'),
                    end_date: Moment().endOf('year').format('YYYY-MM-DD'),
                }, () => this.loadDataGraphic())
            }
        })
    }

    render() {
        const dataArr = this.state.rekapMemberKonsultan;

        const konsultan = dataArr.map(data => {
            return data.nama;
        });

        const totalmember = dataArr.map(data => {
            return data.members_count;
        });

        const dataGrafik = {
            labels: konsultan,
            datasets: [
                {
                    label: 'Total Member',
                    data: totalmember,
                    borderColor: 'cyan',
                    backgroundColor: 'cyan',
                    borderWidth: 1,
                },
            ],
        };

        return (
            <Fragment>
                <div className="tab-content active default" id="tab-1">
                    <div className="row justify-content-center m-1">
                        <div className="col-xl-4 col-sm-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h5 className="mb-0" >Total Registration</h5>
                                    </div>
                                    <div className="project-widgets text-center">
                                        <h1 className="font-primary counter">
                                            <Countup end={this.state.totalMember[0]} />
                                        </h1>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <div style={{ fontSize: '12px' }} className='row m-0'>
                                        {
                                            this.state.totalMember[1].map((item, index) => (
                                                <div key={index} className='col-md text-center font-weight-bold mb-2'>
                                                    <div className="mb-0" >{item.area}</div>
                                                    <Countup className='font-primary' end={item.total_members} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h5 className="mb-0">Total Approved</h5>
                                    </div>
                                    <div className="project-widgets text-center">
                                        <h1 className="font-primary counter">
                                            <Countup end={this.state.memberAktif[0]} />
                                        </h1>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <div style={{ fontSize: '12px' }} className='row m-0'>
                                        {
                                            this.state.memberAktif[1].map((item, index) => (
                                                <div key={index} className='col-md text-center font-weight-bold mb-2'>
                                                    <div className="mb-0" >{item.area}</div>
                                                    <Countup className='font-primary' end={item.total_members} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h5 className="mb-0">Need Approval</h5>
                                    </div>
                                    <div className="project-widgets text-center">
                                        <h1 className="font-primary counter">
                                            <Countup end={this.state.memberNew[0]} />
                                        </h1>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <div style={{ fontSize: '12px' }} className='row m-0'>
                                        {
                                            this.state.memberNew[1].map((item, index) => (
                                                <div key={index} className='col-md text-center font-weight-bold mb-2'>
                                                    <div className="mb-0" >{item.area}</div>
                                                    <Countup className='font-primary' end={item.total_members} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-md-10'>
                                {
                                    this.state.type == 'monthly' ?
                                        <div className='form-group'>
                                            <div className='input-group'>
                                                <DatePicker dateFormat='MMMM yyyy' dateFormatCalendar='MMM' selected={Moment(this.state.start_date)._d} showMonthYearPicker className='form-control' onChange={this.handlePickerStartMonth} />
                                                <DatePicker dateFormat='MMMM yyyy' selected={Moment(this.state.end_date)._d} showMonthYearPicker className='form-control' onChange={this.handlePickerEndMonth} />
                                            </div>
                                        </div>
                                        : ''
                                }
                            </div>
                            <div className='col-md-1'>
                                <button style={{ color: 'white' }} selected={Moment(this.state.end_date)._d} className='btn btn-success btn-md' type='button'><i className='fa fa-refresh' onClick={this.changeFilterType} style={{ color: 'white' }}></i></button>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row justify-content-between'>
                                        <div className='col-md-6'>
                                            <h5>Report by Date</h5>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="text-right">
                                                <a href='https://prioritas.niecindonesia.com/' rel="noopener noreferrer" target='_blank'><button className='btn btn-primary'>Landing Page</button></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Chart
                                        height={'500px'}
                                        chartType="Bar"
                                        loader={<div>Loading Chart</div>}
                                        data={this.state.dataGraphic}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Total Member</h5>
                                </div>
                                <div className="card-body">
                                    <Bar data={dataGrafik} options={{ maintainAspectRatio: true }} />
                                    <p>&nbsp;</p>
                                    <ReportMemberSource start_date={this.state.start_date} end_date={this.state.end_date} />
                                </div>
                            </div>
                        </div>

                        {/* COLUMN CHART MEMBER KONSULTAN */}
                        <DataTablesPromoMember />
                        {/* COLUMN DATA PROMO MEMBER */}
                        <div className="col-md-8">

                        </div>
                        {/* COLUMN DATA PROMO MEMBER */}
                    </div>
                </div>
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(MembershipHomeComponent);