/* eslint-disable eqeqeq,  no-unused-vars */

import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import setRangeDate from '../../helper/general-function';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../actions/login.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {matchSorter} from 'match-sorter';
import { ExportCSV } from '../../services/ExportCSV';
import Moment from 'moment';

class SchoolVisitList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingRow: null,
            deletingRow: null,
            model: {
                code_schedulle_act: "",
                code_school:"",
                code_activities: "",
                status_activities: ""
            },
            errors: {},
            titleList: this.props.title,
            title: this.props.title,
            operation: '',
            toastId: null,
            modal:false,
            modalDelete:false,
            myData: this.props.myData,
            btnDisabled: false,
            logName: '',
            listSchoolVisit: [],
            listMasterAct: [],
            start_date: Moment().startOf('month').format('YYYY-MM-DD'),
            end_date: Moment().endOf('month').format('YYYY-MM-DD'),
            filtered: [],
            filterSource: '',
            code_master_activities: 'MA00001',
            date: 'this_month',
        }
    }

    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    componentDidMount(){
        this.getActivitiesByDate();
        this.getMasterActivites();
    }

    getActivitiesByDate(){
        var formData = new FormData();
        formData.append('code_jenis_act', this.state.code_master_activities);
        formData.append('code_konsultan',this.props.dataUser.code_konsultan);
        formData.append('code_roles',this.props.dataUser.code_roles);
        formData.append('start_date',this.state.start_date);
        formData.append('end_date',this.state.end_date);

        const url = API_URL.superStudent+'/school-activities/visit-by-date';
        axios.post(url, formData, { 
                headers: { 
                    'Authorization': `Bearer ${this.props.dataToken}`, 
                    'Content-Type': 'multipart/form-data' 
                } 
            })
        .then(response => response.data)
        .then((data) => {
            if(data.code === 400) {
                const config = {
                    headers: { Authorization: `Bearer `+this.props.dataToken }
                };
                var formData = new FormData();
                formData.append('id_users',this.props.dataId);
                formData.append('api_token',this.props.dataToken);
                this.props.resettoken(formData, config);
            }else{
                this.setState({listSchoolVisit:data.data});
            }
        });
    }

    getMasterActivites(){
        const url = API_URL.superStudent+'/school-activities/master-activities';
        axios.get(url, { 
                headers: { 
                    'Authorization': `Bearer ${this.props.dataToken}`, 
                    'Content-Type': 'multipart/form-data' 
                } 
            })
        .then(response => response.data)
        .then((data) => {
            if(data.code === 400) {
                const config = {
                    headers: { Authorization: `Bearer `+this.props.dataToken }
                };
            }else{
                this.setState({listMasterAct:data.data});
            }
        });
    }

    resetState(){
        this.setState({
        editingRow: null,
        deletingRow: null,
        model: {
            code_schedulle_act: "",
            code_school:"",
            code_activities: ""
        },
        errors: {},
        });
    }

    SetFilterJenis = (e) => {
        this.setState({
            code_master_activities: e.target.value
        },() => {
            this.getActivitiesByDate();
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
    }

    onChangesetStatusActivities = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.status_activities = newData; 
        return { model };                   
        }, () => {
            this.validateStatusActivities();
        })
    }

    handleValidation(){
        let formIsValid = true;
        if(!this.state.errors.status_activitiesStatus){
            formIsValid = false;
        }
        return formIsValid;
    }

    validateStatusActivities = () => {
        const data = this.state.model;
        if(data.status_activities === "" || data.status_activities === 0 || data.status_activities === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.status_activities = 'Status Activities Must Entered'; 
                errors.status_activitiesStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.status_activities = '';    
                errors.status_activitiesStatus = true;                             
                return { errors };                   
            })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        }, () => {
            this.setRangeDate()
        })
    }

    setRangeDate(){   
        let data = setRangeDate(this.state.date);

        this.setState({ 
            start_date : data.start,
            end_date: data.end
        }, () => {
            this.getActivitiesByDate();
        })
    }

    detailRow(row) {
        row.jenis.name_activites == 'School Meeting' ?
        this.props.history.push({
            pathname: `${process.env.PUBLIC_URL}/pages/visitschoolmeeting/`+row.sekolah.kode_sekolah+`/`+row.code_activities
        })
        :
        this.props.history.push({
            pathname: `${process.env.PUBLIC_URL}/pages/visitschooldetail/`+row.sekolah.kode_sekolah+`/`+row.code_activities
        })
    }

    addRow() {
        this.resetState();
        this.setState({
            title: 'Add Proposal Visit',
            operation: 'add',
            modal: true,
            logName: 'ADD-PROPOSAL-VISIT'
        });
    }

    editRow(row) {
        this.setState({
            editingRow: row,
            model: Object.assign({}, row),
            title: 'Update Status Activities',
            operation: 'edit',
            modal: true,
            logName: 'EDIT-STATUS-ACTIVITIES'
        });
    }

    saveRow() {
        if(this.handleValidation()){
            this.sendData();
        }else{
            this.validateStatusActivities();
        }
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        let row = Object.assign({}, this.state.editingRow, this.state.model);
        let data = this.state.data.slice();
        let index = data.indexOf(this.state.editingRow);
        data.splice(index, 1, row);

        axios.get(API_URL.superStudent+'/school-activities/status_update/'+data[0].code_activities+'/'+data[0].status_activities, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        }).then((reply)=>{
            toast.update(this.state.toastId, {
                render: "Input Success !",
                type: toast.TYPE.SUCCESS,
                autoClose: true,
                className: 'rotateY animated'
            })
            this.closeDialog();
            this.getActivities();
            this.setState({ btnDisabled: false });
        }).catch(function(reply){
            console.log(reply)
        });
    }

    closeDialog(){
        this.setState({
            modal: false,
            modalDelete: false
        });
        this.resetState();
    }

    renderDialog() {
        const row = this.state.model;
        const { title } = this.state;
        const modal1 = this.state.modal;
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal1} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="status_activities">Status Activities</label>
                                <select className="form-control" name="status_activities" type="text" id="status_activities"  value={row.status_activities}
                                    onChange={(e) => this.onChangesetStatusActivities(e)}
                                    onBlur={(e) => this.validateStatusActivities}
                                    ref={e => this.inputStatusActivities = e}
                                >
                                    <option value="">--Set Status Activities--</option>
                                    <option value="3">VISITED</option>
                                    <option value="4">CANCEL</option>
                                </select>
                                <span style={{color:"#FF5370"}}>{this.state.errors.code_activities}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                    </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    render() {
        const { pageSize, multiSelectOption, myData } = this.props;
        const columns = [
            {
                Header: 'School Name',
                accessor: 'sekolah.nama_sekolah',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["sekolah.nama_sekolah"] }),
                  filterAll: true
            },
            {
              Header: 'Activities Name',
              accessor: 'name_activities',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["name_activities"] }),
                filterAll: true
            },
            {
                Header: 'Jenis Name',
                accessor: 'jenis.name_activites',
                filterable: true,
                Filter:() =>
                <select  value={this.state.code_master_activities} onChange={(e) => this.SetFilterJenis(e)}>
                    <option value={0}>Show All</option>
                    {
                        this.state.listMasterAct.map(source => {
                            return <option key={source.id_master_act} value={source.code_master_activities}>{source.name_activites}</option>
                        })
                    }
                </select>
            },
            {
                Header: 'Start Date',
                accessor: 'feedback_meeting.start_date',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["feedback_meeting.start_date"] }),
                  filterAll: true,
                Cell: (row) => (
                    <div>                     
                        {Moment(row.original.feedback_meeting.start_date).format('DD MMMM YYYY')}
                    </div>
                ),
            },
            {
                Header: 'Golden Ticket',
                accessor: 'siswa_count',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["siswa_count"] }),
                  filterAll: true,
                Cell: (row) => (
                    <div>
                        {
                            row.original.code_master_activities == 'MA00003' || row.original.code_master_activities == 'MA00004' ?
                            ""
                            :
                            <p>{row.original.siswa_count}</p>
                        }
                    </div>
                ),
            },
            {
                Header: 'Total Member',
                accessor: 'member_count',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["member_count"] }),
                  filterAll: true,
                Cell: (row) => (
                    <div>
                        {
                            row.original.code_master_activities == 'MA00003' || row.original.code_master_activities == 'MA00004' ?
                            ""
                            :
                            <p>{row.original.member_count}</p>
                        }
                    </div>
                ),
            },
            {
                Header: 'Status Activities',
                accessor: 'status_activities',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["status_activities"] }), 
                  filterAll: true,
                Cell: (row) => (
                    <div>                     
                        {row.original.status_activities == 0 ? 'SENT' : row.original.status_activities == 1 ? 'FEEDBACK' : row.original.status_activities == 2 ? 'SCHEDULLED' : row.original.status_activities == 3 ? 'VISITED' : 'CANCEL'}
                    </div>
                ),
            }, 
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        {
                            row.original.code_master_activities == 'MA00004' ?
                            ""
                            :
                            <button onClick={() => this.detailRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-list" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                        }                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none', display: this.props.display}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return(
            <Fragment>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-3">
                                    <ExportCSV csvData={this.state.listSchoolVisit} fileName={"data-school-visit"} />
                                </div>
                                <div className='col-sm-5'>
                                    <span style={{fontSize:'1.5em'}} className='f-w-600'>{Moment(this.state.start_date).format('DD MMMM yyyy')} - {Moment(this.state.end_date).format('DD MMMM yyyy')}</span>
                                </div>
                                <div className="col-sm-4">
                                    <select name='date' value={this.state.date} onChange={this.handleChange} className='form-control'>
                                        <option value=''>--Choose Time--</option>
                                        <option value='all_time'>All Time</option>
                                        <option value='this_week'>This Week</option>
                                        <option value='last_week'>Last Week</option>
                                        <option value='this_month'>This Month</option>
                                        <option value='last_month'>Last Month</option>
                                        <option value='this_year'>This Year</option>
                                        <option value='last_year'>Last Year</option>
                                        <optgroup label='Quartal'>
                                            <option value='1'>Quartal I</option>
                                            <option value='2'>Quartal II</option>
                                            <option value='3'>Quartal III</option>
                                            <option value='4'>Quartal IV</option>
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                            <br /> 
                            <br /> 
                            <div className="row">
                                <div className="col-sm-12">
                                    <ReactTable 
                                        data={this.state.listSchoolVisit}
                                        columns={columns}
                                        pageSize={pageSize}
                                        defaultPageSize={100}
                                        showPagination={true}
                                        filtered={this.state.filtered}
                                        filterable
                                        className="-striped -highlight"
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        SubComponent={row => {
                                            const data = row.original;
                                            return (
                                                <div style={{ padding: "25px" }}>
                                                    <div style={{paddingLeft:"10px", fontStyle:"", color:"#A6A6A6"}}>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <table className='table'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Start Date</th>
                                                                            <th>End Date</th>
                                                                            <th>Start Time</th>
                                                                            <th>End Time</th>
                                                                            <th>Time Zone</th>
                                                                            <th>Tipe</th>
                                                                            <th>Judul</th>
                                                                            <th>Sub Judul</th>
                                                                            <th>Link Online Meet</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{data.start_date_format}</td>
                                                                            <td>{data.end_date_format}</td>
                                                                            <td>{data.start_time}</td>
                                                                            <td>{data.end_time}</td>
                                                                            <td>{data.zona_waktu}</td>
                                                                            <td>{data.tipe_activities}</td>
                                                                            <td>{data.judul}</td>
                                                                            <td>{data.sub_judul}</td>
                                                                            <td>{data.link_zoom}</td>
                                                                        </tr>
                                                                    </tbody>            
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                    <ToastContainer />
                                    {this.renderDialog()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

// export default Datatable;

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataId: state.Login.detailUser.id_users,
    codeUsers: state.Login.detailUser.code_users,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})
  
const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(SchoolVisitList);