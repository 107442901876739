/* eslint-disable eqeqeq, no-unused-vars */

import React, { Component, Fragment } from "react";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import Moment from 'moment';
import { round } from "lodash";
import { matchSorter } from 'match-sorter';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import setRangeDate from "../../helper/general-function"
import { Typeahead } from 'react-bootstrap-typeahead';
import withFixedColumns from 'react-table-hoc-fixed-columns'
import 'react-table-hoc-fixed-columns/lib/styles.css'

const ReactTableFixedColumns = withFixedColumns(ReactTable)

class StudentPeformance extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listArea: [],
            id_area: '0',
            id_area_point: '0',
            id_kategori: '0',
            periode_filter: 'this_year',
            detailProgress: [],
            detailArea: [],
            totalProgress: '',
            detailData: [],
            dataCategory: [],
            dataProgress: [],
            dataStatus: [],
            dataSource: [],
            dataConsultant: [],
            dataCountry: [],
            dataUniversity: [],
            dataAverageProgress: [],
            filtered: [],
            internalUserList: [],
            dataCampus: [],
            dataProgram: [],
            dataPointConsultant: [],
            dataDetailPoint: [],
            showDetail: 'none',
            filterCategory: '',
            filterPotensial: '',
            filterProgress: '',
            filterSource: '',
            filterKategori: '',
            modalNoted: '',
            modalDetail: false,
            dataNoted: '',
            source: '',
            potensial: '',
            typeUpdate: '',
            detailStudent: '',
            consultant: '',
            countrycode: '',
            code_school_rep: '',
            code_konsultan: '',
            task_description: '',
            status_task: '',
            per_page: 15,
            per_page_area: 2,
            modalTask: false,
            staticFilter: '',
            dinamicFilter: 'none',
            modalTodo: false,
            code_students: '',
            status_approval: '1',
            description: '',
            from: this.props.dataUser.code_users,
            todoType: '',
            due_date: '',
            code_pilihan_kampus: '',
            university_id: '',
            id_program: '',
            filterStartDate: '',
            filterFinishDate: '',
            filterStartPoint: '',
            filterEndPoint: '',
            date: 'this_year',
            date_point: 'this_year'
        }
    }

    componentDidMount() {
        let data = setRangeDate(this.state.date);
        this.setState({
            filterStartDate: data.start,
            filterFinishDate: data.end,
            filterStartPoint: data.start,
            filterEndPoint: data.end,
        }, () => {
            this.loadDataStatusPotensial()
            this.loadDataProgress()
            this.getConstultantProgress()
            this.getAverageProgressTime()
            //this.loadPointConsultant()
        })
        this.loadDataArea()
        this.loadDataSource()
        this.loadDatakategori()
        this.getListCountry()
        this.getLstUniversity();

        this.cekAchivement()
    }

    loadPointConsultant() {
        let formData = new FormData();
        formData.append('start_date', this.state.filterStartPoint)
        formData.append('end_date', this.state.filterEndPoint)
        formData.append('id_area', this.state.id_area_point)
        axios.post(API_URL.superStudent + '/consultant/point-consultant', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        }).then(response => {
            let sorting = response.data.data.sort((a, b) => b.total_points - a.total_points);
            this.setState({
                dataPointConsultant: sorting
            })
        })
    }

    handlePoint = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.loadPointConsultant());
    }

    handleFilterPoint = (e) => {
        let data = setRangeDate(e.target.value);
        this.setState({
            filterStartPoint: data.start,
            filterEndPoint: data.end,
            date_point: e.target.value
        }, () => this.loadPointConsultant())
    }

    loadDetailPoint(code_konsultan) {
        let formData = new FormData();
        formData.append('start_date', this.state.filterStartDate)
        formData.append('end_date', this.state.filterFinishDate)
        formData.append('code_konsultan', code_konsultan)
        axios.post(API_URL.superStudent + '/consultant/detail-point', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.setState({
                    modalDetail: true,
                    dataDetailPoint: response.data.data
                })
            })
    }

    modalDetail = () => {
        return (
            <Modal className="modal-dialog" size="lg" isOpen={this.state.modalDetail} toggle={() => this.closeModal()}>
                <ModalHeader toggle={() => this.closeModal()}>Detail Point</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        {
                            this.state.dataDetailPoint.length > 0 ?
                                this.state.dataDetailPoint.map((items, index) => (
                                    <div key={index} className='col-lg-6 col-md-12 mt-2'>
                                        <ul>
                                            <li className='f-w-600'>{items[0].progres == null ? 'Admin Fee' : items[0].progres.name_progress}</li>
                                            <ul style={{ fontSize: '11px' }}>
                                                {
                                                    items.length > 0 ?
                                                        items.map((item, i) => (
                                                            <li key={i}><i className='fa fa-caret-right txt-secondary m-r-10'></i> ({item.student.code_students}) {item.student.nama} = <span style={{ color: '#7c9ed9' }}>{item.points} point</span></li>
                                                        ))
                                                        :
                                                        ''
                                                }
                                            </ul>
                                        </ul>
                                    </div>
                                ))
                                :
                                <div className='col-md-6'>
                                    <label className='f-w-600'>Data kosong</label>
                                </div>
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
                </ModalFooter>
            </Modal>
        );
    }

    cekAchivement = (target, pencapaian) => {
        let bulan_sekarang = Moment().month() + 1;
        let urutan_target = [7, 8, 9, 10, 11, 12, 0, 1, 2, 3, 4, 5, 6];
        let index = urutan_target.indexOf(bulan_sekarang) + 1
        if (target / 12 * index > pencapaian)
            return false;
        return true;
    }

    getAverageProgressTime() {
        let formData = new FormData();
        formData.append('start_date', this.state.filterStartDate)
        formData.append('end_date', this.state.filterFinishDate)
        axios.post(API_URL.superStudent + '/consultant-peformance/rate-progress-time', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        }).then(respose => {
            this.setState({
                dataAverageProgress: respose.data.data
            })
        });
    }

    getListCountry() {
        axios.get(API_URL.superStudent + '/master/country-has-university', {
            headers: {
                'Accept': 'application/json'
            }
        }).then(response => response.data.data)
            .then((data) => {
                this.setState({ dataCountry: data })
            })
    }

    getLstUniversity() {
        let formData = new FormData();
        formData.append('start_date', this.state.filterStartDate)
        formData.append('end_date', this.state.filterFinishDate)
        axios.post(API_URL.superStudent + '/university-has-student', formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`

            }
        }).then(response => response.data.data)
            .then((data) => {
                this.setState({ dataUniversity: data })
            })
    }

    getConstultantProgress() {
        const url = API_URL.superStudent + '/student/consultant-peformance-detail';
        let formData = new FormData();
        formData.append('id_area', this.state.id_area)
        formData.append('id_kategori', this.state.id_kategori)
        formData.append('start_date', this.state.filterStartDate)
        formData.append('end_date', this.state.filterFinishDate)
        axios.post(url, formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        }).then(response => response.data.data).then((data) => {
            let params = [];
            data[0].forEach(function (item) {
                params.push({
                    'code_konsultan': item.code_konsultan,
                    'nama': item.nama
                })
            })

            const groupedData = data[0].reduce((result, item) => {
                const area = item.area;
                if (!result[area])
                    result[area] = { type: 'area', 'nama': area, new: 0, persiapan_dokumen: 0, translate_dokumen: 0, pendaftaran_sekolah: 0, loa_simulasi_biaya: 0, pembayaran_uang_sekolah: 0, lodge_visa: 0, granted: 0, achivement: 0, rejection: 0, cancel: 0, target: 0 };
                result[area].new += Number(item.new);
                result[area].persiapan_dokumen += Number(item.persiapan_dokumen);
                result[area].translate_dokumen += Number(item.translate_dokumen);
                result[area].pendaftaran_sekolah += Number(item.pendaftaran_sekolah);
                result[area].loa_simulasi_biaya += Number(item.loa_simulasi_biaya);
                result[area].lodge_visa += Number(item.lodge_visa);
                result[area].granted += Number(item.granted);
                result[area].achivement += Number(item.achivement);
                result[area].rejection += Number(item.rejection);
                result[area].cancel += Number(item.cancel);
                result[area].target += Number(item.target);
                return result
            }, {});
            const indexedGroupedData = Object.values(groupedData);
            indexedGroupedData.forEach(obj => {
                obj.persentage = round(obj.granted / obj.target * 100, 2) || 0;// Tambahkan key dan value baru
              });
              console.log(indexedGroupedData)
            indexedGroupedData.sort((a, b) => b.persentage - a.persentage);
            this.setState({ detailArea: indexedGroupedData });
            this.setState({ detailProgress: data[0], totalProgress: data[1], dataConsultant: params, per_page: data[0].length })
        })
            .catch(error => console.log(error))
    }

    loadDataStatusPotensial() {
        axios.get(API_URL.superStudent + '/master/student-category', {
            headers: {
                'Accept': 'application/json'
            }
        }).then(response => response.data.data)
            .then((data) => {
                this.setState({ dataCategory: data })
            })
    }

    loadDataProgress() {
        axios.get(API_URL.superStudent + '/master/progress', {
            headers: {
                'Accept': 'application/json'
            }
        }).then(response => response.data.data)
            .then((data) => {
                this.setState({ dataProgress: data })
            })
    }

    loadDatakategori() {
        axios.get(API_URL.superStudent + '/master/status', {
            headers: {
                'Accept': 'application/json'
            }
        }).then(response => response.data.data)
            .then((data) => {
                this.setState({ dataStatus: data })
            })
    }

    loadDataSource() {
        axios.get(API_URL.superStudent + '/master/lead-source', {
            headers: {
                'Accept': 'application/json'
            }
        }).then(response => response.data.data)
            .then((data) => {
                this.setState({ dataSource: data })
            })
    }

    loadDataArea() {
        axios.get(API_URL.superStudent + '/master/area', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        }).then(response => response.data.data).then((data) => {
            this.setState({ listArea: data })
        })
            .catch(error => console.log(error))
    }

    handlePeformance = (e) => {
        this.setState({ showDetail: 'none' })
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.getConstultantProgress());
    }

    handlePeriode = (e) => {
        let data = setRangeDate(e);
        this.setState({
            periode_filter: e,
            filterStartDate: data.start,
            filterFinishDate: data.end,
        }, () => {
            this.getConstultantProgress()
            this.getAverageProgressTime()
        })
    }

    loadInternalUser = () => {
        let formData = new FormData()
        formData.append('code_roles', JSON.stringify(['RL003','RL006','RL013', 'RL023','RL026']));
        axios.post(API_URL.superStudent + '/internal-user-active', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => response.data.data)
            .then(result => this.setState({ internalUserList: result }))
            .catch(error => console.log(error))
    }

    SetFilterPotensial = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterPotensial = newData;
            return { filterPotensial };
        }, () => {
            this.onFilteredChangeCustom(newData, "code_students_category");
        })
    }

    SetFilterProgress = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterProgress = newData;
            return { filterProgress };
        }, () => {
            this.onFilteredChangeCustom(newData, "code_progress");
        })
    }

    filter = () => {
        this.getAverageProgressTime()
        this.getConstultantProgress()
    }

    SetFilterSource = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterSource = newData;
            return { filterSource };
        }, () => {
            this.onFilteredChangeCustom(newData, "code_leads_source");
        })
    }

    SetFilterStatus = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterKategori = newData;
            return { filterKategori };
        }, () => {
            this.onFilteredChangeCustom(newData, "code_status");
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    }

    setKampusTujuan = (e) => {
        const newData = e.length > 0 ? e[0].code : "";
        const idCampus = e.length > 0 ? e[0].id : "";

        this.setState(prevState => {
            let code_pilihan_kampus = Object.assign({}, prevState.code_pilihan_kampus);
            code_pilihan_kampus = newData;
            return { code_pilihan_kampus };
        })

        this.setState(prevState => {
            let university_id = Object.assign({}, prevState.university_id);
            university_id = idCampus;
            return { university_id };
        }, () => this.getDataProgram());
    }

    setProgramTujuan = (e) => {
        const newData = e.length > 0 ? e[0].id : "";
        this.setState(prevState => {
            let id_program = Object.assign({}, prevState.id_program);
            id_program = newData;
            return { id_program };
        })
    }


    editDetail = (row, type) => {
        this.setState({
            typeUpdate: type,
            detailStudent: row,
            potensial: row.code_students_category,
            source: row.code_leads_source,
            modalEdit: true,
            consultant: row.consultant
        })
    }

    showDetail = (code_konsultan) => {
        let filterData = new FormData();
        filterData.append('start_date', this.state.filterStartDate)
        filterData.append('end_date', this.state.filterFinishDate)
        filterData.append('countrycode', this.state.countrycode)
        filterData.append('code_konsultan', code_konsultan)
        filterData.append('code_school_rep', this.state.code_school_rep)
        filterData.append('status_task', this.state.status_task)
        axios.post(API_URL.superStudent + '/student/detail-student-consultant', filterData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`,
            }
        })
            .then(response => {
                this.setState({
                    detailData: response.data.data,
                    code_konsultan: code_konsultan,
                    showDetail: ''
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    handleChangeFilter = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.showDetail(this.state.code_konsultan));
    }

    handleChangeDateDetail = (e) => {
        let data = setRangeDate(e.target.value)
        this.setState({
            periode_filter: e.target.value,
            filterStartDate: data.start,
            filterFinishDate: data.end,
        }, () => {
            this.showDetail(this.state.code_konsultan);
            this.getConstultantProgress()
        })
    }

    hideDetail = () => {
        this.setState({
            showDetail: 'none'
        })
    }

    editNoted = (row) => {
        this.setState({
            detailStudent: row,
            dataNoted: row.progress_log.length > 0 ? row.progress_log[0].code : '',
            modalNoted: true,
            consultant: row.consultant
        })
    }

    updateDate = (row) => {
        let formData = new FormData()
        formData.append('code_students', row.code_students)
        axios.post(API_URL.superStudent + '/student/update-date-input', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                this.showDetail(row.consultant.code_konsultan)
            })
    }

    updateStudent() {
        this.state.typeUpdate == "Potensial" ?
            this.handleEditable("code_students_category", this.state.detailStudent.code_students, this.state.potensial)
            :
            this.state.typeUpdate == 'Source' ?
                this.handleEditable("code_leads_source", this.state.detailStudent.code_students, this.state.source)
                :
                this.closeModal()
    }

    updateNoted() {
        let formData = new FormData()
        formData.append('code_students', this.state.detailStudent.code_students)
        formData.append('code', this.state.dataNoted)
        formData.append('code_users', this.props.dataUser.code_users)
        formData.append('code_lpt', 'CMNT')
        axios.post(API_URL.studentUrl + 'progress-log', formData, {
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        })
            .then(() => {
                this.showDetail(this.state.code_konsultan)
                this.closeModal();
            })
    }

    handleEditable(kolom, code_students, value) {
        var formData = new FormData();
        formData.append('code_students', code_students);
        formData.append('columns', kolom);
        formData.append('value', value);

        axios.post(API_URL.superStudent + '/student/update-students-by-columns', formData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => {
                this.showDetail(this.state.code_konsultan);
                this.closeModal();
            })
            .catch(error => {
                toast.error('there is an error ' + error)
            })
    }

    handleApprove(item) {
        axios.get(API_URL.superStudent + '/student/approval-task/' + item.id + '/edit', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                this.showDetail(this.state.code_konsultan)
            })
    }

    handleInput = (e) => {
        let val = e.target.value
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.periode_filter === 'dinammic_date') {
                this.setState({
                    dinamicFilter: '',
                    staticFilter: 'none'
                })
            }
            else {
                this.handlePeriode(val)
            }
        })
    }

    handleAddTask() {
        this.setState({
            btnDisabled: true,

        })
        let formData = new FormData()
        formData.append('code_students', this.state.detailStudent.code_students)
        formData.append('description', this.state.task_description)
        axios.post(API_URL.superStudent + '/student/approval-task', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                this.closeModal()
                this.setState({
                    btnDisabled: false,

                })
                this.showDetail(this.state.code_konsultan)
            })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleFrom = (e) => {
        let value = e.target.value
        let status_approval = '1'
        if (value !== this.props.dataUser.code_users) {
            status_approval = '0'
        }
        this.setState({
            status_approval,
            from: e.target.value
        })
    }

    handleTypeList = (e) => {
        let due_date = '';
        if (e.target.value === '10' || e.target.value === '33') {
            due_date = Moment().add(1, 'days').format('YYYYY-MM-DD HH:mm:ss')
            if (Moment().format('d') == 5 || Moment().format('d') == 6 || Moment().format('d') == 7)
                due_date = Moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD 19:00:00')
        }
        else if (e.target.value === '11') {
            due_date = Moment().add(2, 'days').format('YYYY-MM-DD HH:mm:ss')
            if (Moment().format('d') == 5 || Moment().format('d') == 6 || Moment().format('d') == 7)
                due_date = Moment().add(1, 'weeks').startOf('isoWeek').day(2).format('YYYY-MM-DD 19:00:00')
            else if (Moment().format('d') == 4)
                due_date = Moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD 19:00:00')
        }
        this.setState({
            due_date,
            todoType: e.target.value
        })
    }

    closeModal() {
        this.setState({
            modalEdit: false,
            modalNoted: false,
            modalTask: false,
            modalTodo: false,
            modalDetail: false
        });
    }

    renderModalNote() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalNoted} toggle={() => this.closeModal()}>
                <ModalHeader toggle={() => this.closeModal()}>Update Note</ModalHeader>
                <ModalBody>
                    <h5 className='mb-3'>Edit {this.state.typeUpdate} {this.state.detailStudent.nama}</h5>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <textarea rows={3} className='form-control' name='dataNoted' value={this.state.dataNoted} onChange={this.handleChange}></textarea>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.updateNoted()}>Save</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalTask() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalTask} toggle={() => this.closeModal()}>
                <ModalHeader toggle={() => this.closeModal()}>Task </ModalHeader>
                <ModalBody>
                    <h5 className='mb-3'>Tambah Task di {this.state.detailStudent.nama}</h5>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <textarea rows={3} className='form-control' name='task_description' value={this.state.task_description} onChange={this.handleChange}></textarea>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.handleAddTask()}>Save</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalEdit() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalEdit} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>Edit {this.state.typeUpdate} {this.state.detailStudent.nama}</ModalHeader>
                <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                {
                                    this.state.typeUpdate == "Potensial" ?
                                        <select className="form-control" name="potensial" value={this.state.potensial} onChange={this.handleChange}>
                                            {
                                                this.state.dataCategory.map(item => {
                                                    return <option key={item.code_students_category} value={item.code_students_category}>{item.category}</option>
                                                })
                                            }
                                        </select>
                                        : this.state.typeUpdate == "Source" ?
                                            <select className="form-control" name="source" value={this.state.source} onChange={this.handleChange}>
                                                {
                                                    this.state.dataSource.map(item => {
                                                        return <option key={item.code_leads_source} value={item.code_leads_source}>{item.source}</option>
                                                    })
                                                }
                                            </select>
                                            :
                                            <></>
                                }

                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.updateStudent()}>Save</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderTodoModal() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalTodo} toggle={() => this.closeModal()} size="md">
                <ModalHeader toggle={() => this.closeModal()}>Add Data</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <form className="needs-validation tooltip-validation" noValidate="" >
                        <div className='form-group'>
                            <label className='control-label'>Description</label>
                            <textarea className='form-control' onChange={this.handleChange} value={this.state.description} name='description'></textarea>
                        </div>
                        <div className='form-group'>
                            <label className='control-label'>Type</label>
                            <select onChange={this.handleTypeList} className='form-control' value={this.state.todoType} name='todoType'>
                                <option key={0} value='0'>Select Task</option>
                                <option key={1} value='10'>Pendaftaran Sekolah</option>
                                <option key={2} value='11'>Lodge Visa</option>
                                <option key={3} value='33'>Accomodation</option>
                                <option key={3} value='999'>Others</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <label className='control-label'>University</label>
                            <Typeahead
                                id="basic-typeahead"
                                labelKey="name"
                                name="code_pilihan_kampus"
                                options={this.state.dataCampus}
                                onChange={(e) => this.setKampusTujuan(e)}
                                placeholder="--Pilihan Kampus--"
                            />
                        </div>
                        <div className='form-group'>
                            <label className='control-label'>Program</label>
                            <Typeahead
                                id="basic-typeahead"
                                labelKey="program_name"
                                name="id_program"
                                options={this.state.dataProgram}
                                onChange={(e) => this.setProgramTujuan(e)}
                                placeholder="--Pilihan Program--"
                            />
                        </div>
                        <div className='form-group'>
                            <label className='control-label'>From</label>
                            <select name='from' className='form-control' onChange={this.handleFrom} value={this.state.from}>
                                <option key={0} value=''>--Select--</option>
                                {
                                    this.state.internalUserList.map(list => (
                                        <option key={list.code_users} value={list.code_users}>{list.username}</option>
                                    ))
                                }
                            </select>
                            <span style={{ fontSize: '10px', color: 'red' }}>Task perlu di approve yang bersangkuran ketika user yang  di pilih tidak sama dengan user yang login</span>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveList()}>Save</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    saveList() {
        this.setState({
            btnDisabled: true
        })
        let formData = new FormData()
        formData.append('code_students', this.state.code_students)
        formData.append('description', this.state.description)
        formData.append('todoType', this.state.todoType)
        formData.append('handle_by', 'CT046')
        formData.append('code_jobdesk', 'JD006')
        formData.append('title', 'Admission Task list');
        formData.append('status_approval', this.state.status_approval)
        formData.append('due_date', this.state.due_date)
        formData.append('from', this.state.from)
        formData.append('code_school_rep', this.state.code_pilihan_kampus);
        formData.append('id_program', this.state.id_program)
        formData.append('status_id', '6')
        axios.post(API_URL.superStudent + '/todo', formData, {
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => {
                toast.success('task list add successfully')
                this.closeModal()
                this.showDetail(this.state.code_konsultan)
            })
            .catch(error => {
                toast.error('there is an error')
                this.closeModal()
                this.showDetail(this.state.code_konsultan)
            })
    }

    calculateDifferenceInDays(end_date) {
        const date_now = Moment()
        return date_now.diff(end_date, 'days');
    }

    showModalTask(item) {
        this.setState({
            task_description: '',
            detailStudent: item,
            modalTask: true
        })
    }

    addTodo = (code_student) => {
        this.loadInternalUser()
        this.getDataCampus()
        this.setState({
            modalTodo: true,
            description: '',
            todoType: '',
            due_date: '',
            btnDisabled: false,
            code_students: code_student
        })
    }

    getDataCampus() {
        const url = API_URL.superStudent + '/universities';
        axios.get(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then(response => response.data)
            .then((data) => {
                this.setState({ dataCampus: data.data })
            })
    }

    getDataProgram() {
        const url = API_URL.superStudent + '/list-program-university/' + this.state.university_id;
        axios.get(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then(response => response.data)
            .then((data) => {
                this.setState({ dataProgram: data.data })
            })
    }

    render() {

        const columns_point = [
            {
                Header: '#',
                accessor: 'nama',
                style: { textAlign: 'center' },
            },
            {
                Header: <div>Member <br></br> (5 point) </div>,
                accessor: 'member',
                style: { textAlign: 'center' },
            },
            {
                Header: <div>Translate <br></br> (5 point) </div>,
                accessor: 'translate_doc',
                style: { textAlign: 'center' },
            },
            {
                Header: <div>Apply Campus <br></br> (5 point) </div>,
                accessor: 'apply_campus',
                style: { textAlign: 'center' },
            },
            {
                Header: <div>LoA <br></br> (5 point) </div>,
                accessor: 'loa',
                style: { textAlign: 'center' },
            },
            {
                Header: <div>Admin Fee <br></br> (10 point) </div>,
                accessor: 'admin_fee',
                style: { textAlign: 'center' },
            },
            {
                Header: <div>School Fee <br></br> (20 point) </div>,
                accessor: 'school_fee',
                style: { textAlign: 'center' },
            },
            {
                Header: <div>Lodge Visa <br></br> (25 point) </div>,
                accessor: 'lodge_visa',
                style: { textAlign: 'center' },
            },
            {
                Header: <div>Granted <br></br> (30 point) </div>,
                accessor: 'granted',
                style: { textAlign: 'center' },
            },
            {
                Header: 'Achivement',
                accessor: 'total_points',
                style: { textAlign: 'center' },
                width: 140,
                Cell: (row) => (
                    <span><span style={{ color: '#7c9ed9', fontWeight: 'bold' }}>{parseInt(row.original.total_points)}</span> | <span style={{ color: 'red', fontWeight: 'bold' }}>{row.original.target}</span>  | {round(row.original.total_points / row.original.target * 100, 2) || '0'}%</span>
                )
            },
            {
                Header: <b>#</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <button onClick={() => this.loadDetailPoint(row.original.code_konsultan)} style={{ border: 'none' }} className="btn btn-outline-success btn-xs"><i className='fa fa-eye' style={{ fontSize: 16 }}></i></button>
                ),
                style: {
                    textAlign: 'center'
                },
                width: 70,
                sortable: false,
                filterable: false
            }
        ];

        const columns_average = [
            {
                Header: 'Nama Konsultan',
                accessor: 'nama',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <span className={row.original.rate_day > 90 && Moment().diff(Moment(row.original.created),'days') > 90 ? 'blink' : ''}>{row.original.nama}</span>
                )
            },
            {
                Header: 'Rata-rata Progress',
                accessor: 'rate_day',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <>{row.original.rate_day} hari</>
                )
            },
        ]

        const columns = [
            {
                Header: '#',
                accessor: 'nama',
                fixed: 'left',
                width: 170,
                Cell: (row) => (
                    row.original.nama === 'Total' ? <strong>{row.original.nama}</strong> : row.original.type === 'area' ? <span style={{ color: 'blue' }}>{row.original.nama}</span> :
                        <button style={{ border: 'none' }} className={`btn btn-outline-primary btn-sm btn-pill ${this.cekAchivement(row.original.target, row.original.achivement) ? '' : 'blink'} `} onClick={() => this.showDetail(row.original.code_konsultan)}>{row.original.nama}</button>
                ),
                style: { textAlign: 'center' },
            },
            {
                id: 'new',
                Header: 'New(hot)',
                accessor: d => Number(d.new),
                style: { textAlign: 'center' },
            },
            {
                id: 'persiapan_dokumen',
                Header: 'Collect Doc',
                accessor: d => Number(d.persiapan_dokumen),
                style: { textAlign: 'center' },
                filterable: false,
            },
            {
                Header: 'Translate Doc',
                accessor: d => Number(d.translate_dokumen),
                id: 'translate_dokumen',
                style: { textAlign: 'center' },
                filterable: false,
            },
            {
                id: 'pendaftaran_sekolah',
                Header: 'Apply Campus',
                accessor: d => Number(d.pendaftaran_sekolah),
                style: { textAlign: 'center' },
                filterable: false
            },
            {
                Header: 'LOA',
                id: 'loa_simulasi_biaya',
                style: { textAlign: 'center' },
                accessor: d => Number(d.loa_simulasi_biaya),
                filterable: false,
                width: 70
            },
            {
                Header: 'Payment',
                id: 'pembayaran_uang_sekolah',
                style: { textAlign: 'center' },
                accessor: d => Number(d.pembayaran_uang_sekolah),
                filterable: false
            },
            {
                Header: 'Lodge Visa',
                id: 'lodge_visa',
                accessor: d => Number(d.lodge_visa),
                style: { textAlign: 'center' },
                filterable: false
            },
            {
                Header: 'Granted',
                id: 'persentage',
                accessor: d => Number(d.persentage),
                style: { textAlign: 'center' },
                width: 140,
                Cell: (row) => (
                    this.state.id_kategori != '0' ?
                        <span style={{ color: '#7c9ed9', fontWeight: 'bold' }}>{parseInt(row.original.granted)}</span>
                        :
                        <span><span style={{ color: '#7c9ed9', fontWeight: 'bold' }}>{parseInt(row.original.granted)}</span> | <span style={{ color: 'red', fontWeight: 'bold' }}>{row.original.target}</span>  | {round(row.original.granted / row.original.target * 100, 2) || '0'}%</span>
                ),
                filterable: false
            },
            {
                Header: 'Achivement',
                id: 'achivement',
                accessor: d => Number(d.achivement),
                sortType: 'number',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    this.state.id_kategori != '0' ?
                        <span style={{ color: '#7c9ed9', fontWeight: 'bold' }}>{parseInt(row.original.achivement)}</span>
                        :
                        <span> <span style={{ color: '#7c9ed9', fontWeight: 'bold' }}> {parseInt(row.original.achivement)} </span>  | {round((parseInt(row.original.achivement)) / row.original.target * 100, 2) || 0}%</span>
                ),
                filterable: false
            },
            {
                Header: 'Rejection',
                id: 'rejection',
                accessor: d => Number(d.rejection),
                style: { textAlign: 'center' },
                filterable: false
            },
            {
                Header: 'Cancel',
                id: 'cancel',
                accessor: d => Number(d.cancel),
                style: { textAlign: 'center' },
                filterable: false
            },
        ]

        const columns_student_detail = [
            {
                Header: 'Name',
                accessor: 'nama',
                width: 200,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama"] }),
                filterAll: true,
                Cell: (row) => (
                    <>{
                        this.state.periode_filter === 'this_year' || (row.original.code_progress === 'PG015' || row.original.code_progress === 'PG013' || row.original.code_progress === 'PG016') ?
                            '' :
                            <button
                                onClick={() => this.updateDate(row.original)}
                                className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none' }} ><i className="fa fa-check" style={{ fontSize: 16 }}></i>
                            </button>
                    } <button className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none', color: 'black' }} onClick={() =>
                        window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + row.original.token_students)
                    } >{row.original.nama}</button></>
                )
            },
            {
                Header: 'Status',
                accessor: 'code_students_category',
                style: { textAlign: 'center' },
                filterable: true,
                width: 90,
                Cell: (row) => (
                    <div onClick={e => this.editDetail(row.original, "Potensial")}>{row.original.category == null ? '-'
                        :
                        row.original.code_students_category == 'SC00001' ?
                            <span className={'badge bg-info'}>{row.original.category.category}</span>
                            :
                            row.original.code_students_category == 'SC00002' ?
                                <span className={'badge bg-success'}>{row.original.category.category}</span>
                                :
                                row.original.code_students_category == 'SC00004' ?
                                    <span className={'badge bg-dark'}>{row.original.category.category}</span>
                                    :
                                    <span className={'badge bg-danger'}>{row.original.category.category}</span>}</div>
                ),
                Filter: () =>
                    <select value={this.state.filterPotensial || ''} onChange={(e) => this.SetFilterPotensial(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.dataCategory.map(item => {
                                return <option key={item.code_students_category} value={item.code_students_category}>{item.category}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Progress',
                accessor: 'code_progress',
                style: { textAlign: 'center' },
                width: 140,
                filterable: true,
                Cell: (row) => (
                    <>
                        <div>{row.original.progress == null ? '' : row.original.progress.name_progress}</div>
                        <div style={{ fontSize: '11px', color: 'grey' }}>{row.original.last_progress_log == null ? this.calculateDifferenceInDays(row.original.input_date) : row.original.last_progress_log.total_date} days ago</div>
                    </>
                ),
                Filter: () =>
                    <select value={this.state.filterProgress || ''} onChange={(e) => this.SetFilterProgress(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.dataProgress.map(item => {
                                return <option key={item.code} value={item.code}>{item.name}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Kategori',
                accessor: 'code_status',
                style: { textAlign: 'center' },
                width: 140,
                filterable: true,
                Cell: (row) => (
                    <>{row.original.status.name_status}</>
                ),
                Filter: () =>
                    <select value={this.state.filterKategori || ''} onChange={(e) => this.SetFilterStatus(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.dataStatus.map(item => {
                                return <option key={item.code} value={item.code}>{item.name}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Source',
                accessor: 'code_leads_source',
                style: { textAlign: 'center' },
                width: 120,
                filterable: true,
                Cell: (row) => (
                    <div onClick={e => this.editDetail(row.original, 'Source')}>{row.original.source == null ? '-' : row.original.source.source}</div>
                ),
                Filter: () =>
                    <select value={this.state.filterSource || ''} onChange={(e) => this.SetFilterSource(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.dataSource.map(item => {
                                return <option key={item.code_leads_source} value={item.code_leads_source}>{item.source}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Programs',
                accessor: 'programs',
                filterable: false,
                width: 400,
                Cell: (row) => (
                    <ul style={{ overflow: 'auto' }} className='row'>
                        {
                            row.original.programs.length > 0 ?
                                row.original.programs.map((item, index) => (
                                    <li style={{ fontSize: '10px' }} className='col' key={index}>({item.university.countrycode}) {item.university.nama_sekolah} - {item.program_name} - ({Moment(item.pivot.date_program_start).format('MM/YY')}-{Moment(item.pivot.date_program_finish).format('MM/YY')})</li>
                                ))
                                : <></>
                        }
                    </ul>
                ),
            },
            {
                Header: 'Tasks',
                accessor: 'tasks',
                filterable: false,
                width: 400,
                Cell: (row) => (
                    <>
                        <button onClick={() => this.addTodo(row.original.code_students)} className="btn btn-warning btn-xs mr-1"><i className='fa fa-file-text-o'></i></button>
                        <button onClick={() => this.showModalTask(row.original)} className="btn btn-info btn-xs "><i className="fa fa-plus"></i></button>
                        <ul style={{ overflow: 'auto' }} className='row'>
                            {
                                row.original.tasks.length > 0 ?
                                    row.original.tasks.map((item, index) => (
                                        <li style={{ fontSize: '10px' }} className='col mb-1' key={index}> ({Moment(item.created_at).format('DD/MM/YY')}) {item.description} {item.status == 1 ? <span className={'badge bg-success'}>done</span> : <button onClick={() => this.handleApprove(item)} className='btn btn-danger btn-xs'><i className='fa fa-check'></i></button>}</li>
                                    ))
                                    : <></>
                            }
                        </ul>
                    </>
                ),
            },
        ];

        return (
            <Fragment>
                <div className="row justify-content-center">
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-header">
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h5>Average Student Progres Time</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <ReactTable
                                    data={this.state.dataAverageProgress}
                                    columns={columns_average}
                                    pageSize={this.state.dataAverageProgress.length}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div style={{ display: this.state.staticFilter }} className='col-lg-3 col-sm-4 mb-2'>
                            <select value={this.state.periode_filter} onChange={this.handleInput} className='form-control' name='periode_filter' >
                                <optgroup label='Primary Filter'>
                                    <option value='all_data'>All Data ({Moment().startOf('year').add(-6, 'months').format('MMM YY') + ' - ' + Moment().endOf('year').add(6, 'months').format('MMM YY')}) </option>
                                    <option value='last_year'>Last Year ({Moment().startOf('year').add(-6, 'months').format('MMM YY') + ' - ' + Moment().endOf('year').add(-6, 'months').format('MMM YY')}) </option>
                                    <option value='this_year'>This Year ({Moment().startOf('year').add(6, 'months').format('MMM YY') + ' - ' + Moment().endOf('year').add(6, 'months').format('MMM YY')}) </option>
                                </optgroup>
                                <option value='dinammic_date'> --Dinammic Filter-- </option>
                                <optgroup label="Periode Filter">
                                    <option value='this_week'>This Week</option>
                                    <option value='last_week'>Last Week</option>
                                    <option value='this_month'>This Month</option>
                                    <option value='last_month'>Last Month</option>
                                </optgroup>
                            </select>
                        </div>
                        <div style={{ display: this.state.dinamicFilter }} className='row'>
                            <div className="col-md-3 mb-2">
                                <input className="form-control" type="date" onChange={(e) => this.setState({ filterStartDate: Moment(e.target.value).format('YYYY-MM-DD') })} name="startDate" value={this.state.filterStartDate} />
                            </div>
                            <div className="col-md-3 mb-2">
                                <input className="form-control" type="date" onChange={(e) => this.setState({ filterFinishDate: Moment(e.target.value).format('YYYY-MM-DD') })} name="endDate" value={this.state.filterFinishDate} />
                            </div>
                            <div className="col-md-4 btn-group" role='group'>
                                <button style={{ color: 'white' }} type='button' className='btn btn-primary btn-md' onClick={() => this.filter()} ><i className='fa fa-filter'></i> Filter</button>
                                <button style={{ color: 'white' }} className='btn btn-success btn-md' type='button' onClick={() => this.setState({ dinamicFilter: 'none', staticFilter: '' })}><i className='fa fa-refresh'></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row justify-content-between'>
                                    <div className='col-lg-6'>
                                        <h5>Progress Consultant Peformance</h5>
                                    </div>
                                    <div className="col-lg-3">
                                        <select className='form-control' onChange={this.handlePeformance} value={this.state.id_kategori} name='id_kategori'>
                                            <option value='0'>All Kategori Student</option>
                                            <option value='ST001'>Student Visa</option>
                                            <option value='ST015'>J1 Visa</option>

                                        </select>
                                    </div>
                                    <div className='col-lg-3 col-sm-4'>
                                        <select className='form-control' onChange={this.handlePeformance} value={this.state.id_area} name='id_area'>
                                            <option value='0'>All Branch</option>
                                            {
                                                this.state.listArea.length > 0 ?
                                                    this.state.listArea.map((item, index) => (
                                                        <option key={index} value={item.id_area}>{item.area}</option>
                                                    )) : <></>
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <ReactTableFixedColumns
                                    data={this.state.detailProgress}
                                    showPaginationBottom={false}
                                    pageSize={this.state.per_page}
                                    columns={columns}
                                >
                                </ReactTableFixedColumns>
                                <ReactTable
                                    data={[this.state.totalProgress]}
                                    showPaginationBottom={false}
                                    pageSize={1}
                                    columns={columns}
                                >
                                </ReactTable>
                                <div className='mt-4' >
                                    <h5>Area Peformance</h5>
                                </div>
                                <ReactTableFixedColumns
                                    data={this.state.detailArea}
                                    showPaginationBottom={false}
                                    pageSize={this.state.detailArea.length}
                                    columns={columns}
                                >
                                </ReactTableFixedColumns>
                                <ReactTable
                                    data={[this.state.totalProgress]}
                                    showPaginationBottom={false}
                                    pageSize={1}
                                    columns={columns}
                                ></ReactTable>
                                <div style={{ display: this.state.showDetail }} className='mt-4'>
                                    <div className="row mb-2">
                                        <div className='col-lg-3 col-sm-3'>
                                            <select value={this.state.periode_filter} onChange={this.handleChangeDateDetail} className='form-control' name='periode' >
                                                <option value='all_data'>All Data ({Moment().startOf('year').add(-6, 'months').format('MMM YY') + ' - ' + Moment().endOf('year').add(6, 'months').format('MMM YY')}) </option>
                                                <option value='last_year'>Last Year ({Moment().startOf('year').add(-6, 'months').format('MMM YY') + ' - ' + Moment().endOf('year').add(-6, 'months').format('MMM YY')}) </option>
                                                <option value='this_year'>This Year ({Moment().startOf('year').add(6, 'months').format('MMM YY') + ' - ' + Moment().endOf('year').add(6, 'months').format('MMM YY')}) </option>
                                                <optgroup label="Periode Filter">
                                                    <option value='this_week'>This Week</option>
                                                    <option value='last_week'>Last Week</option>
                                                    <option value='this_month'>This Month</option>
                                                    <option value='last_month'>Last Month</option>
                                                </optgroup>
                                            </select>
                                        </div>
                                        <div className="col-lg-2 col-sm-3">
                                            <select className="form-control" name="code_konsultan" value={this.state.code_konsultan} onChange={(e) => this.showDetail(e.target.value)}>
                                                {
                                                    this.state.dataConsultant.length > 0 ?
                                                        this.state.dataConsultant.map((item, index) => (
                                                            <option key={index} value={item.code_konsultan}>{item.nama}</option>
                                                        )) : <></>
                                                }
                                            </select>
                                        </div>
                                        <div className="col-lg-2 col-sm-3">
                                            <select className="form-control" name="countrycode" value={this.state.countrycode} onChange={this.handleChangeFilter}>
                                                <option value=''>Show All Country</option>
                                                {
                                                    this.state.dataCountry.length > 0 ?
                                                        this.state.dataCountry.map((item, index) => (
                                                            <option key={index} value={item.code}>{item.name}</option>
                                                        )) : <></>
                                                }
                                            </select>
                                        </div>
                                        <div className="col-lg-2 col-sm-2">
                                            <select className="form-control" name="code_school_rep" value={this.state.code_school_rep} onChange={this.handleChangeFilter}>
                                                <option value=''>Show All university</option>
                                                {
                                                    this.state.dataUniversity.length > 0 ?
                                                        this.state.dataUniversity.map((item, index) => (
                                                            <option key={index} value={item.code_school_rep}>{item.nama_sekolah}</option>
                                                        )) : <></>
                                                }
                                            </select>
                                        </div>
                                        <div className="col-lg-2-sm-2">
                                            <select className="form-control" name="status_task" value={this.state.status_task} onChange={this.handleChangeFilter}>
                                                <option value=''>Show All Status Task</option>
                                                <option value='0'>Progress</option>
                                                <option value='1'>Done</option>

                                            </select>
                                        </div>
                                    </div>
                                    <ReactTable
                                        className="-striped"
                                        columns={columns_student_detail}
                                        data={this.state.detailData}
                                        filterable
                                        collapseOnDataChange={false}
                                        filtered={this.state.filtered}
                                        defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        SubComponent={row => {
                                            return (
                                                <div style={{ padding: "20px" }}>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <i onClick={e => this.editNoted(row.original)} className="fa fa-pencil" style={{ fontSize: 16, color: "#22af47" }}></i><strong> Notes :</strong> <span>{row.original.progress_log.length > 0 ?
                                                                <>
                                                                    {row.original.progress_log[0].code}
                                                                </>
                                                                : ''}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    >
                                    </ReactTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row justify-content-between'>
                                    <div className='col-lg-6 col-sm-12'>
                                        <h5>Point Consultant</h5>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <select onChange={this.handleFilterPoint} name="date_point" value={this.state.date_point} className='form-control' >
                                            <option value='this_year'>This Year </option>
                                            <option value='last_year'>Last Year</option>
                                            <option value='this_month'>This Month</option>
                                            <option value='last_month'>Last Month</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-3 col-sm-6'>
                                        <select className='form-control' onChange={this.handlePoint} value={this.state.id_area_point} name='id_area_point'>
                                            <option value='0'>All Branch</option>
                                            {
                                                this.state.listArea.length > 0 ?
                                                    this.state.listArea.map((item, index) => (
                                                        <option key={index} value={item.id_area}>{item.area}</option>
                                                    )) : <></>
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <ReactTable
                                    data={this.state.dataPointConsultant}
                                    columns={columns_point}
                                    pageSize={this.state.dataPointConsultant.length}
                                />
                            </div>
                        </div>
                    </div> */}
                </div>
                {this.renderModalEdit()}
                {this.renderModalNote()}
                {this.renderModalTask()}
                {this.renderTodoModal()}
                {this.modalDetail()}
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser
})

export default connect(reduxState, null)(StudentPeformance);