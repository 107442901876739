/* eslint-disable eqeqeq,  no-unused-vars, no-useless-concat */

import React, { Fragment, Component } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../../actions/login.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {matchSorter} from 'match-sorter';
import { ExportCSV } from '../../../services/ExportCSV';
import moment from 'moment';

class VisitSchoolActivities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingRow: null,
            deletingRow: null,
            model: {
                id_sekolah_siswa:"",
                code_school:"",
                code_activities:"",
                code_consultant:"",
                id_konsultan:"",
                nama_siswa:"",
                email_siswa:"",
                no_wa_siswa:"",
                id_promo:"",
                harga:"",
                kode_promo:"",
            },
            errors: {},
            titleList: this.props.title,
            title: this.props.title,
            operation: "",
            toastId: null,
            modal:false,
            modalEdit:false,
            modalDelete:false,
            modalDetail:false,
            modalPotensial:false,
            myData: this.props.myData,
            btnDisabled: false,
            logName: "",
            listPromoMember: [],
            filtered:[],
            dataCategory: [],
            filterRequestor:"",
            detail: [],
            potensial: "",
            dataDetailActivities: [],
            dataConsultant: [],
            dataSiswas: [],
            rows: 0,
        }
    }

    getDataPromoMember() {
        const url = API_URL.superStudent+'/promo-member/active';
        axios({
            method: 'get',
            url: url,
            headers: {
              'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({ listPromoMember: result}))
    }

    getDataCategory(){
        const url = API_URL.superStudent+'/master/student-category';
        axios.get(url,{
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dataCategory: data })
        })
    }

    getDetailSchoolVisit() {
        var formData = new FormData();
        formData.append('code_activities', this.props.match.params.codeActivities);

        const url = API_URL.superStudent+'/school-activities/visit-by-detail';
        axios.post(url, formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({ 
                dataDetailActivities:data.data 
            });
        });
    }

    getConsultant() {
        const url = API_URL.superStudent + '/master/consultant/list-priotas';
        axios.get(url, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.props.dataToken}`
          }
        })
        .then(response => response.data.data)
        .then((data) => {
          this.setState({ dataConsultant: data })
        })
    }

    componentDidMount() {
        this.getDataPromoMember();
        this.getDataCategory();
        this.getDetailSchoolVisit();
        this.getConsultant();
    }

    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    resetState() {
        this.setState({
            editingRow: null,
            deletingRow: null,
            model: {
                id_sekolah_siswa: "",
                code_school:"",
                code_activities:"",
                code_consultant:"",
                id_konsultan:"",
                nama_siswa:"",
                email_siswa: "",
                no_wa_siswa: "",
                tipe_transfer: "",
                id_promo: "",
                harga: "",
                kode_promo: "",
            },
            errors: {},
        });
    }

    // handle on change   
    onChangesetNamaSiswa = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.nama_siswa = newData; 
        return { model };                   
        }, () => {
            this.validateNamaSiswa();
        })
    }
    onChangesetEmailSiswa = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.email_siswa = newData; 
        return { model };                   
        }, () => {
            this.validateEmailSiswa();
        })
    }
    onChangesetWASiswa = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.no_wa_siswa = newData; 
        return { model };                   
        }, () => {
            this.validateWASiswa();
        })
    }
    onChangesetTipeTransfer = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.tipe_transfer = newData; 
        return { model };                   
        }, () => {
            this.validateTipeTransfer();
        })
    }
    onChangesetCodeConsultan = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.code_consultant = newData; 
        return { model };
        }, () => {
            this.validateKodeKonsultan();
        })
    }
    onChangesetKodePromo = (e) => {
        if(e.length > 0) {
            const harga = e[0].harga;
            const kode_promo = e[0].kode_harga;
            this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.harga = harga; 
            model.kode_promo = kode_promo; 
            return { model };
            }, () => {
                this.validateKodePromo();
            })
        } else {
            this.validateKodePromo();
        }
    }
    // end handle on change

    // validasi submit form
    handleValidation(){
        let formIsValid = true;
        
        if(this.state.errors.nama_siswa != ""){
            formIsValid = false;
        }
        if(this.state.errors.email_siswa != ""){
            formIsValid = false;
        }
        if(this.state.errors.no_wa_siswa != ""){
            formIsValid = false;
        }
        if(this.state.errors.code_consultant != ""){
            formIsValid = false;
        }
        return formIsValid;
    }
    // validasi submit form

    // handle validation per field
    validateNamaSiswa = () => {
        const data = this.state.model;
        if(data.nama_siswa === "" || data.nama_siswa === 0 || data.nama_siswa === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.nama_siswa = 'Student Name Must Entered'; 
                errors.nama_siswaStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.nama_siswa = '';    
                errors.nama_siswaStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateEmailSiswa = () => {
        const data = this.state.model;
        if(data.email_siswa === "" || data.email_siswa === 0 || data.email_siswa === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.email_siswa = 'Student Email By Must Entered'; 
                errors.email_siswaStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.email_siswa = '';    
                errors.email_siswaStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateWASiswa = () => {
        const data = this.state.model;
        if(data.no_wa_siswa === "" || data.no_wa_siswa === 0 || data.no_wa_siswa === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.no_wa_siswa = 'WA. Number Must Entered'; 
                errors.no_wa_siswaStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.no_wa_siswa = '';    
                errors.no_wa_siswaStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateTipeTransfer = () => {
        const data = this.state.model;
        if(data.tipe_transfer === "" || data.tipe_transfer === 0 || data.tipe_transfer === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.tipe_transfer = 'Tipe Transfer Must Entered'; 
                errors.tipe_transferStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.tipe_transfer = '';    
                errors.tipe_transferStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateKodePromo = () => {
        const data = this.state.model;
        if(data.id_promo === "" || data.id_promo === 0 || data.id_promo === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.id_promo = 'Promo Must Entered'; 
                errors.id_promoStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.id_promo = '';    
                errors.id_promoStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateKodeKonsultan = () => {
        const data = this.state.model;
        if(data.code_consultant === "" || data.code_consultant === 0 || data.code_consultant === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.code_consultant = 'Code Consultant Must Entered'; 
                errors.code_consultantStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.code_consultant = '';    
                errors.code_consultantStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateKodePromo = () => {
        const data = this.state.model;
        if(data.kode_promo === "" || data.kode_promo === 0 || data.kode_promo === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.kode_promo = 'Code Consultant Must Entered'; 
                errors.kode_promoStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.kode_promo = '';    
                errors.kode_promoStatus = true;                             
                return { errors };                   
            })
        }
    }
    // end handle validation per field

    addClick(){
        this.setState(prevState => ({ 
            dataSiswas: [...prevState.dataSiswas, {}]
        }))
    }

    removeClick(i){
        let dataSiswas = [...this.state.dataSiswas];
        dataSiswas.splice(i, 1);
        this.setState({ dataSiswas });
    }

    sendRows(){
        for (let i = 0; i < this.state.rows; i++) {
            this.setState(prevState => ({ 
                dataSiswas: [...prevState.dataSiswas, {}]
            }))
        }
    }

    handleChangeRow(i, e) {
        let dataSiswas = [...this.state.dataSiswas];
        dataSiswas[i] = {...dataSiswas[i], [e.target.name]:e.target.value};
        this.setState({ dataSiswas });
    }

    addRow() {
        this.resetState();
        this.setState({
            title: 'Add Student',
            operation: 'add',
            modal: true,
            logName: 'ADD-STUDENT'
        });
    }

    editRow(row) {
        this.setState({
            editingRow: row,
            model: Object.assign({}, row),
            title: 'Edit Student',
            operation: 'edit',
            modalEdit: true,
            logName: 'EDIT-STUDENT'
        });
    }

    deleteRow(row) {
        this.setState({
          deletingRow: row,
          model: Object.assign({}, row),
          title: 'Delete Student',
          operation: 'delete',
          modalDelete: true,
          logName: 'DELETE-STUDENT'
        });
    }

    updateData = () => {
        if(this.handleValidation()){
            this.sendUpdateData();
        }else{
            this.validateNamaSiswa();
            this.validateEmailSiswa();
            this.validateWASiswa();
            this.validateKodeKonsultan();
        }
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData(); 
        formData.append('code_school', this.props.match.params.codeSchool);
        formData.append('code_activities', this.props.match.params.codeActivities);
        formData.append('data_siswa', JSON.stringify(this.state.dataSiswas));

        let url = '/school-activities/list-siswa';

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getDetailSchoolVisit();
            this.setState({ btnDisabled: false });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
        }).catch(function(reply){
            console.log(reply)
        });
    }

    sendUpdateData() {
        this.setState({
            btnDisabled: true
        });

        let row = Object.assign({}, this.state.editingRow, this.state.model);
        let data = this.state.data.slice();
        let index = data.indexOf(this.state.editingRow);
        data.splice(index, 1, row);

        var formData = new FormData(); 
        formData.append('nama_lengkap', data[0].nama_siswa);
        formData.append('email', data[0].email_siswa);
        formData.append('no_wa', data[0].no_wa_siswa);
        formData.append('code_consultant', data[0].code_consultant);

        let url = '';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/school-activities/list-siswa/'+data[0].id_sekolah_siswa;
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/school-activities/list-siswa/'+data[0].id_sekolah_siswa;
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getDetailSchoolVisit();
            this.setState({ btnDisabled: false });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
        }).catch(function(reply){
            console.log(reply)
        });
    }

    closeDialog(){
        this.setState({
            modal: false,
            modalDelete: false,
            modalPotensial: false,
            modalEdit: false,
        });
        this.resetState();
    }

    editDetail = (row) => {
        this.setState({
            modalPotensial: true,
            potensial: row.code_students_category,
            detail: row
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    updateStudent() {
        var formData = new FormData();
        formData.append('id_sekolah_siswa', this.state.detail.id_sekolah_siswa);
        formData.append('nama_lengkap', this.state.detail.nama_siswa);
        formData.append('email', this.state.detail.email_siswa);
        formData.append('no_wa', this.state.detail.no_wa_siswa);
        formData.append('code_student_category', this.state.potensial);
        formData.append('_method', 'PATCH');
        
        axios.post(API_URL.superStudent+'/school-activities/list-siswa/'+this.state.detail.id_sekolah_siswa, formData, {
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((reply)=> {
            this.closeDialog();
            this.getDetailSchoolVisit();
        }).catch(function(reply){
            console.log(reply)
        });
    }

    renderDialog() {
        const row = this.state.model;
        const { title } = this.state;
        const modal1 = this.state.modal;
        return (
            <div className="card-body btn-showcase">
                <Modal size='lg' isOpen={modal1} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <div className="row mb-3">
                            <div className="col-lg-1">
                                <button onClick={this.addClick.bind(this)} className="btn btn-outline-success btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-plus" style={{ width: 35, padding: 11, fontSize: 15 }}></i></button>
                            </div>
                            <div className='col-lg-2'>
                                <input className='form-control' name='rows' type='number' onChange={(e) => this.handleInput(e)} />
                            </div>
                            <div className='col-lg-1'>
                                <button onClick={this.sendRows.bind(this)} className="btn btn-success" type="button">Set</button>
                            </div>
                        </div>
                        {
                            this.state.dataSiswas.map((siswa, i) => (
                                <div className='row'>
                                    <div className='col-lg-11'>
                                        <form className="needs-validation" noValidate="">
                                            <div className="form-row">
                                                <div className="col-md-3 mb-2">
                                                    <label htmlFor="nama_siswa">Student Name</label>
                                                    <input className="form-control" name="nama_siswa" type="text" id="nama_siswa" onChange={this.handleChangeRow.bind(this, i)} />
                                                </div>
                                                <div className="col-md-3 mb-2">
                                                    <label htmlFor="email">Email</label>
                                                    <input className="form-control" name="email" type="text" id="email" onChange={this.handleChangeRow.bind(this, i)}/>
                                                </div>
                                                <div className="col-md-3 mb-2">
                                                    <label htmlFor="no_wa_siswa">Phone Number</label>
                                                    <input className="form-control" name="no_wa_siswa" type="text" id="no_wa_siswa" onChange={this.handleChangeRow.bind(this, i)}/>
                                                </div>
                                                <div className="col-md-3 mb-2">
                                                    <label htmlFor="code_consultant">Consultant</label>
                                                    <select md="3" className="form-control" name="code_consultant" onChange={this.handleChangeRow.bind(this, i)}>
                                                        <option value="0">--Pilih Consultant--</option>
                                                        {this.state.dataConsultant.map((data1) => (
                                                            <option key={data1.code} value={data1.code}>{data1.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='col-lg-1'>
                                        <button onClick={this.removeClick.bind(this, i)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                                    </div>
                                </div>
                            ))
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderEditDialog() {
        const row = this.state.model;
        const { title } = this.state;
        const modal1 = this.state.modalEdit;
        return (
            <div className="card-body btn-showcase">
                <Modal size='lg' isOpen={modal1} toggle={() => this.setState({ modalEdit: false })}>
                    <ModalHeader toggle={() => this.setState({ modalEdit: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="nama_siswa">Student Name</label>
                                    <input className="form-control" name="nama_siswa" type="text" id="nama_siswa"  value={row.nama_siswa}
                                        onChange={(e) => this.onChangesetNamaSiswa(e)}
                                        onBlur={(e) => this.validateNamaSiswa}
                                        ref={e => this.inputNamaSiswa = e}
                                    />
                                    <span>{this.state.errors.nama_siswa}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="email_siswa">Student Email </label>
                                    <input className="form-control" name="email_siswa" type="text" id="email_siswa"  value={row.email_siswa}
                                        onChange={(e) => this.onChangesetEmailSiswa(e)}
                                        onBlur={(e) => this.validateEmailSiswa}
                                        ref={e => this.inputEmailSiswa = e}
                                    />
                                    <span>{this.state.errors.email_siswa}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="no_wa_siswa">WA. Number</label>
                                    <input className="form-control" name="no_wa_siswa" type="text" id="no_wa_siswa"  value={row.no_wa_siswa}
                                        onChange={(e) => this.onChangesetWASiswa(e)}
                                        onBlur={(e) => this.validateWASiswa}
                                        ref={e => this.inputWASiswa = e}
                                    />
                                    <span>{this.state.errors.no_wa_siswa}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="code_consultant">Consultant</label>
                                    <select md="3" className="form-control" value={row.code_consultant} onChange={(e) => this.onChangesetCodeConsultan(e)} onBlur={(e) => this.validateKodeKonsultan} name="code_consultant">
                                        <option value="0">--Pilih Consultant--</option>
                                        {this.state.dataConsultant.map((data1) => (
                                            <option key={data1.code} value={data1.code}>{data1.name}</option>
                                        ))}
                                    </select>
                                    <span style={{color: '#ff5370'}}>{this.state.errors.code_consultant}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.updateData()}>Update Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    renderDeleteDialog() {
        const row = this.state.data;
        const { title } = this.state;
        const modal2 = this.state.modalDelete;
        return (
            <Modal isOpen={modal2} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendUpdateData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderModalPotensial() {
        return(
            <Modal className="modal-dialog" isOpen={this.state.modalPotensial} toggle={() => this.closeDialog()} size="lg">
                <ModalHeader toggle={() => this.closeDialog()}></ModalHeader>
                <ModalBody>
                    <h5 className='mb-3'>Edit Potensial {this.state.detail.nama_siswa}</h5>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <select className="form-control" name="potensial" value={this.state.potensial} onChange={this.handleInput}>
                                    {
                                        this.state.dataCategory.map( item => {
                                            return <option key={item.code_students_category} value={item.code_students_category}>{item.category}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendUpdateData()}>Save</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    SetFilterPotensial = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
            let filterRequestor = newData;
            return { filterRequestor };
        }, () => { 
          this.onFilteredChangeCustom(newData, "category");
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                if (value === "" || !value.length) filtered.splice(i, 1);
                else filter["value"] = value;

                insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    }

    render() {
        const { pageSize, multiSelectOption, myData } = this.props;
        const columns = [
            {
                Header: 'Consultan Name',
                accessor: 'consultant.nama',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["consultant.nama"] }),
                filterAll: true
            },
            {
                Header: 'Code Member',
                accessor: 'code_member',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["code_member"] }),
                filterAll: true
            },
            {
                Header: 'Student Name',
                accessor: 'nama_siswa',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["nama_siswa"] }),
                filterAll: true
            },
            {
                Header: 'Student Email',
                accessor: 'email_siswa',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email_siswa"] }),
                filterAll: true
            },
            {
                Header: 'Student No. WA.',
                accessor: 'no_wa_siswa',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["no_wa_siswa"] }),
                filterAll: true,
                Cell: (row) => ( 
                    row.original.no_wa_siswa.substring(0,2) == '62' ?
                    <a href={'https://wa.me/'+row.original.no_wa_siswa} rel='noopener noreferrer' target='_blank'>{row.original.no_wa_siswa}</a>
                    :
                    <a href={'https://wa.me/'+'62'+row.original.no_wa_siswa.substring(1)} rel='noopener noreferrer' target='_blank'>{row.original.no_wa_siswa}</a>
                )
            }, 
            {
                Header: 'Potensial',
                accessor: 'code_students_category',
                style: {textAlign: 'center'},
                filterable: true,
                Cell: (row) => (
                    <div onClick={e => this.editDetail(row.original)}>
                        {
                            row.original.code_students_category == 'SC00001' ? 
                            <span className={'badge bg-info'}>{row.original.category}</span>
                            :
                            row.original.code_students_category == 'SC00002' ? 
                            <span className={'badge bg-success'}>{row.original.category}</span>
                            :
                            row.original.code_students_category == 'SC00003' ?  
                            <span className={'badge bg-danger'}>{row.original.category}</span>
                            :
                            row.original.code_students_category == 'SC00004' ?  
                            <span className={'badge bg-warning'}>{row.original.category}</span>
                            :
                            <span className={'badge bg-dark'}>None</span>
                        }
                    </div>
                ),
                Filter: () => 
                <select value={this.state.filterRequestor || ''} onChange={(e) => this.SetFilterPotensial(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.dataCategory.map( item => {
                        return <option key={item.code_students_category} value={item.code_students_category}>{item.category}</option>
                        })
                    }
                </select>
            }, 
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return(
            <Fragment>
                <Breadcrumb title="School Visit Detail" parent="School Visit Detail" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <div className="table-responsive">
                                        {
                                            this.state.dataDetailActivities.length > 0 ?
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>Activities Name</td>
                                                        <td style={{ textAlign: 'center' }}>:</td>
                                                        <td>{this.state.dataDetailActivities[0].name_activities}</td>
                                                        <td>Start Date</td>
                                                        <td style={{ textAlign: 'center' }}>:</td>
                                                        <td>{moment(this.state.dataDetailActivities[0].feedback_meeting.start_date).format('DD MMMM YYYY')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Judul</td>
                                                        <td style={{ textAlign: 'center' }}>:</td>
                                                        <td>{this.state.dataDetailActivities[0].feedback_meeting.judul}</td>
                                                        <td>End Date</td>
                                                        <td style={{ textAlign: 'center' }}>:</td>
                                                        <td>{moment(this.state.dataDetailActivities[0].feedback_meeting.end_date).format('DD MMMM YYYY')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sekolah</td>
                                                        <td style={{ textAlign: 'center' }}>:</td>
                                                        <td>{this.state.dataDetailActivities[0].sekolah.nama_sekolah}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            : ''
                                        }
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-sm-2">
                                            <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                                                <div>
                                                    <span>
                                                        <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                                    </span>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="col-sm-10 text-right">
                                            {
                                                this.state.dataDetailActivities.length > 0 ? 
                                                <ExportCSV csvData={this.state.dataDetailActivities[0].siswa} fileName={"data-peserta-infosession"} />
                                                :
                                                ''
                                            }
                                        </div>
                                    </div>
                                    {
                                        this.state.dataDetailActivities.length > 0 ? 
                                        <ReactTable 
                                            data={this.state.dataDetailActivities[0].siswa}
                                            filtered={this.state.filtered}
                                            columns={columns}
                                            pageSize={pageSize}
                                            showPagination={true}
                                            filterable
                                            defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                            style={{ height: "800px" }}
                                            onFilteredChange={(filtered, column, value) => {
                                                this.onFilteredChangeCustom(value, column.id || column.accessor);
                                            }}
                                        />
                                        :
                                        ''
                                    }
                                    <ToastContainer />
                                    {this.renderDialog()}
                                    {this.renderEditDialog()}
                                    {this.renderDeleteDialog()}
                                    {this.renderModalPotensial()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

// export default Datatable;

const reduxState = (state) => ({
    codeUsers: state.Login.detailUser.code_users,
    dataId: state.Login.detailUser.id_users,
    dataToken: state.Login.lgnInitialize,
})
  
const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(VisitSchoolActivities);