/* eslint-disable eqeqeq */

import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import CardTotal from './partition/cardTotal'
import IncomeConsultant from './partition//income-consultant'
import IncomeBizdev from './partition/income-bizdev';
import AdmissionTastk from './partition/admission-task';
import PeformanceBizdev from './partition/peformance-bizdev';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios'
import API_URL from '../../ApiUrl';
import Moment from 'moment';
import { connect } from 'react-redux';
import IncomeArea from './partition/income-area';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setRangeDate from '../../helper/general-function';

import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import SchoolVisitSchedule from './partition/school-visit-schedule';
import EventReport from './partition/event-report';
import AdmissionReport from './partition/admission-report';
import CardStatic from './partition/card-static';
import PeformanceBranch from './partition/peformance-branch';
import KPIMarketingManager from './partition/kpi-marketing-manager';
import DataLeadsAll from '../../pages/leads-all/index';
import StudentFollowUp from './partition/student-followup';
import ReportStudent from './admission-supervisor/report-student';
import ApprovalStudent from '../dashboard/branch/approval-student';
import TaskListConsultant from '../../pages/task-list/list-data-consultant';

const firebaseConfig = {
    apiKey: "AIzaSyAVqznRXhLFVaeXHYVHVYpnXgRWi7J4Pck",
    authDomain: "super-student-e23d1.firebaseapp.com",
    projectId: "super-student-e23d1",
    storageBucket: "super-student-e23d1.appspot.com",
    messagingSenderId: "111947567630",
    appId: "1:111947567630:web:e198a5ac7a008315c3225f",
    measurementId: "G-WB22KPE1D6"
};

class Default extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formData: { start_date: Moment().startOf('isoWeek').format('YYYY-MM-DD'), end_date: Moment().endOf('isoWeek').format('YYYY-MM-DD'), jobdesk: JSON.stringify(['JD002', 'JD004', 'JD007']) },
            date: '',
            show: false,
            staticFilter: '',
            dinamicFilter: 'none',
            start_date: Moment().startOf('isoWeek').format('YYYY-MM-DD'),
            end_date: Moment().endOf('isoWeek').format('YYYY-MM-DD'),
            fcm_token: ''
        }
    }

    componentDidMount() {
        this.checkAnnouncement()
        this.requestPermission()
        this.cekTokenLogin()
    }

    cekTokenLogin() {
        axios.get(API_URL.superStudent + '/userable', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
            })
            .catch(error => {
                this.props.history.push(`${process.env.PUBLIC_URL}/login`)
            })
    }

    requestPermission() {
        Notification.requestPermission().then((permission) => {
            if (permission == 'granted') {
                console.log('Permission Granted');
                const app = initializeApp(firebaseConfig);

                const messaging = getMessaging(app);
                getToken(messaging, {
                    vapidKey: "BDu2-bqV_BT9nkQ8jy0h6sK6coH0w-yH1ww_LxnH6Dwzhlk7wMr0wTWDNWmWp4UyoNTQ4TypvkjHQAASBXpXs7w"
                }).then((currentToken) => {
                    if (currentToken) {
                        this.setState({
                            fcm_token: currentToken
                        }, () => {
                            this.updateFCMToken();
                        })
                    } else {
                        console.log('cannot get token')
                    }
                })
            } else {
                console.log('Dont have permission');
            }
        })
    }

    updateFCMToken() {
        let formData = new FormData()
        formData.append('code_users', this.props.dataUser.code_users);
        formData.append('fcm_token', this.state.fcm_token);

        axios.post('https://superstudent.niecindonesia.com/api/v1/auth/update-fcm-token', formData, {
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
            })
            .catch(error => console.log(error))
    }

    checkAnnouncement = () => {
        axios.get(API_URL.superStudent + '/announcement/' + this.props.dataUser.code_konsultan, {
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                let show = false;
                if (response.data.data === 1)
                    show = true;
                this.setState({
                    show
                })
            })
            .catch(error => console.log(error))
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.date === 'dinammic_date') {
                this.setState({
                    dinamicFilter: '',
                    staticFilter: 'none'
                })
            }
            else {
                this.setDate()
            }
        })
    }

    handlePickerMonth = (e) => {
        this.setState({
            start_date: Moment(e).startOf('month').format('YYYY-MM-DD'),
            end_date: Moment(e).endOf('month').format('YYYY-MM-DD'),
        })
    }

    onConfirm = () => {
        axios.get(API_URL.superStudent + '/create-announcement/' + this.props.dataUser.code_konsultan, {
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.setState({
                    show: false
                })
            })
            .catch(error => console.log(error))
    }

    setDate() {
        let data = setRangeDate(this.state.date);
        let formData = new FormData();
        formData.append('start_date', data.start)
        formData.append('end_date', data.end)
        formData.append('jobdesk', JSON.stringify(['JD002', 'JD004', 'JD007']))
        this.setState({
            start_date: data.start,
            end_date: data.end,
            formData
        })
    }

    render() {
        return (
            <Fragment>
                <SweetAlert
                    info
                    confirmBtnText="OK"
                    confirmBtnBsStyle="success"
                    title="Pengumuman Baru"
                    show={this.state.show}
                    onConfirm={this.onConfirm}
                >
                    Hai {this.props.dataUser.username},  ada penguman baru untuk mu, cek detail nya di menu Anoouncement.
                </SweetAlert>
                <Breadcrumb parent="Home" title = "Home" />
                <div className="container-fluid">
                    <CardStatic/>
                    {
                        this.props.code_roles === 'RL001' || this.props.code_roles === 'RL003' || this.props.code_roles === 'RL005' || this.props.code_roles === 'RL023' ?                            
                        <div className='col-xl-12'>
                            <PeformanceBranch/>
                        </div> : ''
                    }
                    {
                        this.props.code_roles === 'RL001' || this.props.code_roles === 'RL003' ?
                        <div className='col-xl-12'>
                            <KPIMarketingManager/>
                        </div>
                        :
                        <></>   
                    }
                    <div className="row justify-content-center">
                        <div className="col-sm-12"></div>
                        <div style={{display:this.state.staticFilter}}  className='col-md-3 col-sm-4 mb-2'>
                            <select name='date' value={this.state.date} onChange={this.handleChange} className='form-control'>
                                <option value='this_week'>This Week</option>
                                <option value='last_week'>Last Week</option>
                                <option value='this_month'>This Month</option>
                                <option value='last_month'>Last Month</option>
                                <option value='this_year'>This Year</option>
                                <option value='last_year'>Last Year</option>
                                <optgroup label='Quartal'>
                                    <option value='1'>Quartal I</option>
                                    <option value='2'>Quartal II</option>
                                    <option value='3'>Quartal III</option>
                                    <option value='4'>Quartal IV</option>
                                </optgroup>
                                <option value='dinammic_date'> --Dinammic Date-- </option>
                            </select>
                        </div>
                        <div className="col-sm-12"></div>
                        <div style={{display:this.state.dinamicFilter}}  className='row justify-content-center'>
                            <div className='col-md-8 col-sm-6 col-xs-6 mb-2'>
                                <DatePicker dateFormat='MMMM yyyy' selected={Moment(this.state.start_date)._d} showMonthYearPicker showFullMonthYearPicker className='form-control' onChange={this.handlePickerMonth} />
                            </div>
                            <div className='col-md-2 col-sm-4 mb-2'>
                                <button className='btn btn-success btn-md' type='button' onClick={() => this.setState({dinamicFilter:'none', staticFilter:''})}><i  style={{color:'white'}}  className='fa fa-refresh'></i></button>
                            </div>
                        </div>
                        <div className='col-sm-12 mb-3'>
                            <span style={{fontSize:'1.5em'}} className='f-w-600'>{Moment(this.state.start_date).format('DD MMMM yyyy')} - {Moment(this.state.end_date).format('DD MMMM yyyy')}</span>
                        </div>
                        <CardTotal start_date={this.state.start_date} end_date={this.state.end_date}/>
                        <div className="col-xl-12">
                            <TaskListConsultant start_date={this.state.start_date} end_date={this.state.end_date} />
                        </div>
                        <div className='col-xl-12'>
                            <IncomeConsultant start_date={this.state.start_date} end_date={this.state.end_date}/>
                        </div>
                        {
                            this.props.code_roles === 'RL001' || this.props.code_roles === 'RL003' || this.props.code_roles === 'RL005' || this.props.code_roles === 'RL023' ?                          
                            <div className='col-xl-12'>
                                <IncomeArea start_date={this.state.start_date} end_date={this.state.end_date}/> 
                            </div> : ''
                        }
                        <div className='col-xl-6'>
                            <IncomeBizdev start_date={this.state.start_date} end_date={this.state.end_date} />
                        </div> 
                        <div className='col-xl-6'>
                            <PeformanceBizdev start_date={this.state.start_date} end_date={this.state.end_date} />
                        </div>
                        <div className='col-xl-6'>
                            <AdmissionTastk start_date={this.state.start_date} end_date={this.state.end_date} />
                        </div>
                        <div className='col-xl-6'>
                            <SchoolVisitSchedule />
                        </div>
                        {
                            this.props.code_roles === 'RL003' ?
                            <ApprovalStudent/> : ''
                        }

                    </div>
                    {
                        this.props.code_roles === 'RL003' ?
                        <div className='row'>
                            <StudentFollowUp/>
                            <div className='col-md-12'>
                                <ReportStudent/>
                                <DataLeadsAll/>
                            </div>
                        </div> : ''
                    }
                    {
                        this.props.code_roles === 'RL001' || this.props.code_roles === 'RL003' || this.props.code_roles === 'RL005' || this.props.code_roles === 'RL023' ?
                        <>
                            <div className="row justify-content-center">
                                <div className='col-xl-12'>
                                    <EventReport start_date={this.state.start_date} end_date={this.state.end_date} />
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className='col-xl-12'>
                                    <AdmissionReport start_date={this.state.start_date} end_date={this.state.end_date} />
                                </div>
                            </div>
                        </>
                        :
                        ''
                    }
                </div>
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    code_roles: state.Login.detailUser.code_roles
})

export default connect(reduxState, null)(Default);