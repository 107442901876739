/* eslint-disable eqeqeq, no-useless-concat */

import React, { Fragment, Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import API_URL from '../../../../ApiUrl';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { matchSorter } from 'match-sorter';

class DetailStudentStuck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataStudentStuck: [],
            dataProgressLog: [],
            rowStudentStuck: {},
            modalAddFollowUp: false,
            modalViewFollowUp: false,
            noteFollowUp: '',
        }
    }

    componentDidMount() {
        this.getDetailStudentStuck();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.code_konsultan !== this.props.code_konsultan) {
            this.getDetailStudentStuck();
        }
    }

    handleInput(e) {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    getDetailStudentStuck() {
        let formData = new FormData()
        formData.append('code_konsultan', this.props.code_konsultan)
        formData.append('start_date', this.props.start_date)
        formData.append('end_date', this.props.end_date)
        formData.append('type', this.props.type)
        axios.post(API_URL.superStudent+'/list-data-follow-up', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                dataStudentStuck: response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    getListProgressFollowUp() {
        let formData = new FormData()
        formData.append('code_students', this.state.rowStudentStuck.code_students)
        formData.append('code_lpt', 'FLUP')
        axios.post(API_URL.superStudent+'/student/progress-log-detail', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                dataProgressLog: response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    saveData() {
        let formData = new FormData()
        formData.append('code_users', this.props.dataUser.code_users)
        formData.append('code_students', this.state.rowStudentStuck.code_students)
        formData.append('code_lpt', 'FLUP')
        formData.append('code', this.state.noteFollowUp)
        axios.post(API_URL.superStudent+'/student/progress-log', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                modalAddFollowUp: false
            },() => {
                this.getDetailStudentStuck();
            })
        })
        .catch(error => console.log(error))
    }

    openModalAddFollowUp(row) {
        this.setState({
            modalAddFollowUp: true,
            rowStudentStuck: row,
        })
    }

    openModalViewFollowUp(row) {
        this.setState({
            modalViewFollowUp: true,
            rowStudentStuck: row,
        },() => {
            this.getListProgressFollowUp();
        })
    }

    closeModalAddFollowUp() {
        this.setState({
            modalAddFollowUp: false
        })
    }

    closeModalViewFollowUp() {
        this.setState({
            modalViewFollowUp: false
        })
    }

    renderAddFollowUp() {
        const { modalAddFollowUp, rowStudentStuck } = this.state;
        return (
            <Modal isOpen={modalAddFollowUp} toggle={() => this.closeModalAddFollowUp()} >
                <ModalHeader toggle={() => this.closeModalAddFollowUp()}>Add Follow Up Member</ModalHeader>
                <ModalBody>
                    <h5>{rowStudentStuck.nama}</h5>
                    <form className="needs-validation mt-3" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Note Follow Up</label>
                                <textarea type='text' className='form-control' onChange={(e) => this.handleInput(e)} value={this.state.noteFollowUp} name='noteFollowUp'></textarea>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModalAddFollowUp()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderViewFollowUp() {
        const { modalViewFollowUp, rowStudentStuck, dataProgressLog } = this.state;
        return (
            <Modal isOpen={modalViewFollowUp} toggle={() => this.closeModalViewFollowUp()} >
                <ModalHeader toggle={() => this.closeModalViewFollowUp()}>View Follow Up Member</ModalHeader>
                <ModalBody>
                    <h5>{rowStudentStuck.nama}</h5>
                    <div className="card-body scroll scrool recent-notification mt-3">
                        {dataProgressLog.map((dataProgress, index)=>(
                            <div className="media" key={index}>
                                <div className=""><span>{moment(dataProgress.datetime_progress).format('DD MMM YY')}</span>
                                    <p>{moment(dataProgress.datetime_progress).format('HH:mm')}</p>
                                </div>
                                <div className="media-body">
                                    {
                                        dataProgress.code_lpt == 'CMNT' ? 
                                        <div>
                                            <span>{dataProgress.user != null ? dataProgress.user.username :''} has comment <h6>{dataProgress.code}</h6></span>
                                        </div> 
                                        :
                                        dataProgress.code_lpt == 'PGRS' ? 
                                        <div>
                                            <span>Progress has been changed to <p style={{fontSize:'1em', color:'#000', fontWeight:'bold'}}>{dataProgress.progress != null ? dataProgress.progress.name_progress : ''}</p></span>
                                            <p className="f-12">{dataProgress.user != null ? dataProgress.user.username :''}</p>
                                        </div> 
                                        :
                                        dataProgress.code_lpt == 'FLUP' ? 
                                        <div>
                                            <span>Already Follow Up with notes <p style={{fontSize:'1em', color:'#000', fontWeight:'bold'}}>{dataProgress.code}</p></span>
                                            <p className="f-12">{dataProgress.user != null ? dataProgress.user.username :''}</p>
                                        </div> 
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModalViewFollowUp()}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const column = [
            {
                Header: 'Name',
                accessor: 'nama',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["nama"] }),
                filterAll: true,
                Cell: (row) => (
                    <span onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + row.original.token_students)} style={{ border: 'none', cursor: 'pointer' }} >{row.original.nama}</span>
                )
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
                filterAll: true,
            },
            {
                Header: 'No. Hp.',
                accessor: 'no_hp',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["no_hp"] }),
                filterAll: true,
                Cell: (row) => (
                    row.original.no_hp.substring(0,2) == '62' ?
                    <a href={'https://wa.me/'+row.original.no_hp} rel='noopener noreferrer' target='_blank'>{row.original.no_hp}</a>
                    :
                    <a href={'https://wa.me/'+'62'+row.original.no_hp.substring(1)} rel='noopener noreferrer' target='_blank'>{row.original.no_hp}</a>
                )
            },
            {
                Header: 'Progress',
                accessor: 'progress.name_progress',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["progress.name_progress"] }),
                filterAll: true,
            },
            {
                Header: 'Category',
                accessor: 'category.category',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["category.category"] }),
                filterAll: true,
            },
            {
                Header: 'Consultant',
                accessor: 'consultant.nama',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["consultant.nama"] }),
                filterAll: true,
            },
            {
                Header: 'Program',
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    row.original.programs.map((progs) => (
                        <span>{progs.program_name}</span>
                    ))
                )
            },
            {
                Header: 'Total Days',
                accessor: 'total_days',
                style: { textAlign: 'center' },
                filterable: false,
            },
            {
                Header: 'Status Follow Up',
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    <>
                        {
                            row.original.progress_log.length > 0 ?
                            <span className={'badge bg-success'}>Sudah Follow Up</span>
                            :
                            <span className={'badge bg-danger'}>Need Follow Up</span>
                        }
                    </>
                )
            },
            {
                Header: 'Action',
                filterable: false,
                Cell: (row) => (
                    <div className='row justify-content-center'>
                        {
                            this.props.dataUser.code_roles != 'RL001' && this.props.code_roles != 'RL003' ? 
                            <div className='col-md-3'>
                                <button onClick={() => this.openModalAddFollowUp(row.original)} className="btn btn-success btn-xs" type="button"><i className="fa fa-pencil"></i></button>
                            </div>
                            :
                            ''
                        }
                        <div className='col-md-3'>
                            <button onClick={() => this.openModalViewFollowUp(row.original)} className="btn btn-info btn-xs" type="button"><i className="fa fa-list"></i></button>
                        </div>
                    </div>
                )
            },
        ]

        return(
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className='card-header'>
                                        <div className='row'>
                                            <div className='col-sm-11'>
                                                <h5>Detail Student Progress {this.props.judul} {this.props.nama}</h5>
                                            </div>
                                            <div className='col-sm-1'>
                                                <button className='btn-danger' onClick={() => this.props.closeDetailAll()}><i className='fa fa-close'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <ReactTable
                                            data={this.state.dataStudentStuck}
                                            columns={column}
                                            defaultPageSize={20}
                                            className="-striped -highlight"
                                            showPaginationBottom={true}
                                            filterable
                                            defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderAddFollowUp()}
                {this.renderViewFollowUp()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(DetailStudentStuck))