/* eslint-disable eqeqeq, no-useless-concat */

import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../../actions/login.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { addRowStudents } from '../../../actions/students.action';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ExportCSV } from '../../../services/ExportCSV';
import Loader from '../loader'
import { matchSorter } from 'match-sorter';
import debounce from 'lodash.debounce';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DatatableStudents extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.renderEditable = this.renderEditable.bind(this);
    this.filterColumn = debounce(this.filterColumn.bind(this), 500)
    this.state = {
      data: [],
      dataCategory: [],
      editingRow: null,
      deletingRow: null,
      showLoading: false,
      nama: "",
      alamat: "",
      id_propinsi: "",
      id_kabkota: "",
      filter_branch: this.props.dataUser.code_roles === 'RL023' ? '1' : '0',
      no_hp: "",
      email: "",
      kota_lahir: "",
      tgl_lahir: "",
      jenis_kelamin: "",
      code_jenpen: "",
      code_kelas: "",
      code_school: "",
      code_leads_source: "",
      code_konsultan: "",
      code_ambassador: "",
      code_students_category: "",
      code_pilihan_kampus: '',
      code_sekolah_asal: "",
      code_kampus_asal: "",
      code_status: "",
      ket_kampus: "",
      potensial: "",
      university: "",
      sekolah: "",
      status_name: "",
      university_id: '',
      id_program: '',
      errors: {},
      titleList: this.props.title,
      title: this.props.title,
      logName: '',
      toastId: null,
      modal: false,
      modalDelete: false,
      modalTodo: false,
      modalPotensial: false,
      btnDisabled: false,
      dataConsultant: [],
      dataProvinsi: [],
      dataKabKota: [],
      dataKabKotaFilter: [],
      dataJenPen: [],
      dataSekolah: [],
      dataKampus: [],
      dataKelasSemester: [],
      dataKelasSemesterFilter: [],
      dataLeadsSource: [],
      dataAmbassador: [],
      dataRoles: [],
      dataStatus: [],
      dataProgress: [],
      dataStudentSource: [],
      dataCampus: [],
      dataProgram: [],
      dataArea: [],
      filtered: [],
      internalUserList: [],
      searchInput: "",
      checkemail: "",
      pesanEmailError: "",
      renderInputSekolah: "none",
      dropdownOpen: false,
      isOpen: false,
      filterDownload: [],
      filterRequestor: '',
      filterCategory: '',
      filterConsultant: '',
      filterProgress: '',
      filterSource: '',
      filterKelas: '',
      description: '',
      todoType: '',
      code_students: '',
      due_date: '',
      status_approval: '1',
      from: this.props.dataUser.code_users,
      instagram_field: '',
      showAction: this.props.dataUser.code_roles === 'RL001' ? '' : this.props.dataUser.code_roles === 'RL003' ? '' : 'none',
      detailStudent: "",
      current_page: 0,
      last_page: 0,
      per_page: 15,
      start_date: Moment().startOf('month'),
      end_date: Moment().endOf('month'),
      filter_params: '',
      handle_by: 'CT046',
      code_jobdesk: 'JD006',
    }
  }

  componentDidMount() {
    this.getAllstudents();
    this.getCategory();
    this.getDataCampus();
    this.getConsultant();
    this.getStatus();
    this.getProgress();
    this.getStudentSource();
    this.getDataKelasSemester();
  }

  renderEditable = (cellinfo) => {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const data = [...this.state.data]
          data[cellinfo.index][cellinfo.column.id] = e.target.innerHTML;
          this.handleEditable(cellinfo.column.id, cellinfo.original.code_students, e.target.innerHTML);
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.data[cellinfo.index][cellinfo.column.id]
        }}
      />
    )
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    }, () => {
      this.getAllstudents()
    })
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleTypeList = (e) => {
    let due_date = '';
    if (e.target.value === '10' || e.target.value === '33') {
      due_date = Moment().add(1, 'days').format('YYYYY-MM-DD HH:mm:ss')
      if (Moment().format('d') == 5 || Moment().format('d') == 6 || Moment().format('d') == 7)
        due_date = Moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD 19:00:00')
    }
    else if (e.target.value === '11') {
      due_date = Moment().add(2, 'days').format('YYYY-MM-DD HH:mm:ss')
      if (Moment().format('d') == 5 || Moment().format('d') == 6 || Moment().format('d') == 7)
        due_date = Moment().add(1, 'weeks').startOf('isoWeek').day(2).format('YYYY-MM-DD 19:00:00')
      else if (Moment().format('d') == 4)
        due_date = Moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD 19:00:00')
    }
    else if (e.target.value == '73') {
      due_date = Moment().add(3, 'days').format('YYYYY-MM-DD HH:mm:ss')
      if (Moment().format('d') == 5 || Moment().format('d') == 6 || Moment().format('d') == 7)
        due_date = Moment().add(1, 'weeks').startOf('isoWeek').day(2).format('YYYY-MM-DD 19:00:00')
      else if (Moment().format('d') == 4)
        due_date = Moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD 19:00:00')
      this.setState({
        handle_by: 'CT080',
        code_jobdesk: 'JD017',
        title: 'Review Statement Letter',
      })
    }
    this.setState({
      due_date,
      todoType: e.target.value
    })
  }

  handleEditable(kolom, code_students, value) {
    var formData = new FormData();
    formData.append('code_students', code_students);
    formData.append('columns', kolom);
    formData.append('value', value);

    axios.post(API_URL.superStudent + '/student/update-students-by-columns', formData, {
      headers: {
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => {
        this.getAllstudents();
        this.closeModal();
      })
      .catch(error => {
        toast.error('there is an error ' + error)
      })
  }

  getStudentSource() {
    const url = API_URL.superStudent + '/master/student-source';
    axios.get(url, {
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataStudentSource: data })
      })
  }

  getCategory() {
    const url = API_URL.superStudent + '/master/student-category';
    axios.get(url, {
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataCategory: data })
      })
  }

  getAllstudents = () => {
    this.setState({ showLoading: true })
    const codeRoles = this.props.codeRoles
    let filter_branch = ''
    if (codeRoles == 'RL008')
      filter_branch = this.props.dataUser.code_ambassador
    let url = '/students?page=' + this.state.current_page + this.state.filter_params + '&size=' + this.state.per_page + '&filter_branch=' + this.state.filter_branch+'&code_ambassador='+filter_branch
    // if (codeRoles === 'RL008')
    //   url = '/ambassador/students-data/' + this.props.dataUser.code_ambassador
    axios.get(API_URL.superStudent + url, {
      headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
    })
      .then(response => response.data.data)
      .then(result => {
        this.setState({
          data: result.data,
          current_page: result.current_page,
          per_page: result.per_page,
          showLoading: false
        })
      })
      .catch(error => {
        this.setState({ showLoading: true })
        console.log(error)
      })
  }

  updateNoted() {
    let formData = new FormData()
    formData.append('code_students', this.state.detailStudent.code_students)
    formData.append('code', this.state.dataNoted)
    formData.append('code_users', this.props.dataUser.code_users)
    formData.append('code_lpt', 'CMNT')
    axios.post(API_URL.studentUrl + 'progress-log', formData, {
      headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
    })
      .then(() => {
        this.getAllstudents();
        this.closeModal();
      })
  }

  getStatus() {
    const url = API_URL.superStudent + '/master/status';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataStatus: data })
      })
  }

  getProgress() {
    const url = API_URL.superStudent + '/master/progress';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataProgress: data })
      })
  }

  getConsultant() {
    const url = API_URL.superStudent + '/consultant/list-consultant';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataConsultant: data })
      })
  }

  getDataKelasSemester() {
    const url = API_URL.superStudent + '/master/kelas';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataKelasSemester: data })
      })
  }

  getDataJenPen() {
    const url = API_URL.superStudent + '/master/jenjang';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataJenPen: data })
      })
  }

  getDataSekolah(province_id) {
    const url = API_URL.superStudent + '/sekolah-indonesia?province_id=' + province_id;
    axios.get(url, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataSekolah: data })
      })
  }

  getDataArea() {
    axios.get(API_URL.superStudent + '/master/area', {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataArea: data })
      })
  }

  getDataKampus() {
    const url = API_URL.superStudent + '/universities';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataKampus: data })
      })
  }

  getProvinsi() {
    const url = API_URL.superStudent + '/master/provinces';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataProvinsi: data })
      })
  }

  getKabKota() {
    const url = API_URL.superStudent + '/master/kabupaten';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataKabKota: data })
      })
  }

  getDataLeadsSource() {
    const url = API_URL.superStudent + '/master/lead-source';
    axios.get(url, {
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataLeadsSource: data })
      })
  }

  getDataAmbassador() {
    const url = API_URL.superStudent + '/ambassadors?category=all';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataAmbassador: data })
      })
  }

  getDataCategory() {
    const url = API_URL.superStudent + '/master/student-category';
    axios.get(url, {
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataCategory: data })
      })
  }

  getDataCampus() {
    const url = API_URL.superStudent + '/universities';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data)
      .then((data) => {
        this.setState({ dataCampus: data.data })
      })
  }

  getDataProgram() {
    const url = API_URL.superStudent + '/list-program-university/' + this.state.university_id;
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data)
      .then((data) => {
        this.setState({ dataProgram: data.data })
      })
  }

  closeModal() {
    this.setState({
      modal: false,
      modalTodo: false,
      modalPotensial: false,
      modalNoted: false,
      code_students: ''
    });
  }

  setProvinsi = (e) => {
    const newData = e.target.value;
    const data = Object.values(this.state.dataKabKota);
    const filterData = data.filter(dataFilter => {
      return dataFilter.province_id == newData;
    });
    this.setState({ dataKabKotaFilter: filterData })

    this.setState(prevState => {
      let id_propinsi = Object.assign({}, prevState.id_propinsi);
      id_propinsi = newData;
      return { id_propinsi };
    })
    this.getDataSekolah(e.target.value)
  }

  setJenjang = (e) => {
    const newData = e.target.value;
    const data = Object.values(this.state.dataKelasSemester);
    const filterData = data.filter(dataFilter => {
      return dataFilter.code_jenjang == newData;
    });
    this.setState({ dataKelasSemesterFilter: filterData })

    this.setState(prevState => {
      let code_jenpen = Object.assign({}, prevState.code_jenpen);
      code_jenpen = newData;
      return { code_jenpen };
    })
  }

  setSekolahAsal = (e) => {
    const newData = e.length > 0 ? e[0].kode_sekolah : "";
    this.setState(prevState => {
      let code_sekolah_asal = Object.assign({}, prevState.code_sekolah_asal);
      code_sekolah_asal = newData;
      return { code_sekolah_asal };
    })
  }

  setKampusTujuan = (e) => {
    const newData = e.length > 0 ? e[0].code : "";
    const idCampus = e.length > 0 ? e[0].id : "";

    this.setState(prevState => {
      let code_pilihan_kampus = Object.assign({}, prevState.code_pilihan_kampus);
      code_pilihan_kampus = newData;
      return { code_pilihan_kampus };
    })

    this.setState(prevState => {
      let university_id = Object.assign({}, prevState.university_id);
      university_id = idCampus;
      return { university_id };
    }, () => this.getDataProgram());
  }

  setProgramTujuan = (e) => {
    const newData = e.length > 0 ? e[0].id : "";

    this.setState(prevState => {
      let id_program = Object.assign({}, prevState.id_program);
      id_program = newData;
      return { id_program };
    })
  }

  setConsultant = (e) => {
    const newData = e.length > 0 ? e[0].code : "";
    this.setState(prevState => {
      let code_konsultan = Object.assign({}, prevState.code_konsultan);
      code_konsultan = newData;
      return { code_konsultan };
    })
  }

  SetFilterSource = (e) => {
    const newData = e.target.value;
    this.setState(prevState => {
      let filterSource = newData;
      return { filterSource };
    }, () => {
      this.onFilteredChangeCustom(newData, "student_status");
    })
  }

  SetFilterCategory = (e) => {
    const newData = e.target.value;
    this.setState(prevState => {
      let filterCategory = newData;
      return { filterCategory };
    }, () => {
      this.onFilteredChangeCustom(newData, "code_status");
    })
  }

  SetFilterProgress = (e) => {
    const newData = e.target.value;
    this.setState(prevState => {
      let filterProgress = newData;
      return { filterProgress };
    }, () => {
      this.onFilteredChangeCustom(newData, "code_progress");
    })
  }

  SetFilterConsultant = (e) => {
    const newData = e.target.value;
    this.setState(prevState => {
      let filterConsultant = newData;
      return { filterConsultant };
    }, () => {
      this.onFilteredChangeCustom(newData, "code_konsultan");
    })
  }

  SetFilterRequestor = (e) => {
    const newData = e.target.value;
    this.setState(prevState => {
      let filterRequestor = newData;
      return { filterRequestor };
    }, () => {
      this.onFilteredChangeCustom(newData, "code_students_category");
    })
  }

  SetFilterKelas = (e) => {
    const newData = e.target.value;
    this.setState(prevState => {
      let filterKelas = newData;
      return { filterKelas };
    }, () => {
      this.onFilteredChangeCustom(newData, "code_kelas");
    })
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }
    let params = '';
    filtered.forEach(element => {
      params += `&${element.id}=${element.value}`
    });

    this.setState({ filtered: filtered, filter_params: params }, () => {
      this.filterColumn()
    });
  }

  filterColumn() {
    this.getAllstudents()
  }

  addStudents() {
    this.getProvinsi();
    this.getKabKota();
    this.getDataJenPen();
    this.getDataKampus();
    this.getDataKelasSemester();
    this.getDataLeadsSource();
    this.getDataAmbassador();
    this.getConsultant();
    this.getDataCategory();
    this.setState({
      title: 'Data Students',
      modal: true,
      logName: 'ADD-STUDENTS'
    });
  }

  closeDialog() {
    this.setState({
      modal: false,
      modalDelete: false
    });
  }

  handleFrom = (e) => {
    let value = e.target.value
    let status_approval = '1'
    if (value !== this.props.dataUser.code_users) {
      status_approval = '0'
    }
    this.setState({
      status_approval,
      from: e.target.value
    })
  }

  saveList() {
    this.setState({
      btnDisabled: true
    })
    let formData = new FormData()
    formData.append('code_students', this.state.code_students)
    formData.append('description', this.state.description)
    formData.append('todoType', this.state.todoType)
    formData.append('handle_by', this.state.handle_by)
    formData.append('code_jobdesk', this.state.code_jobdesk)
    formData.append('title', this.state.title)
    formData.append('status_approval', this.state.status_approval)
    formData.append('due_date', this.state.due_date)
    formData.append('from', this.state.from)
    formData.append('code_school_rep', this.state.code_pilihan_kampus);
    formData.append('id_program', this.state.id_program)
    formData.append('date_program_start', this.state.start_date.format('YYYY-MM-DD'))
    formData.append('date_program_finish', this.state.end_date.format('YYYY-MM-DD'))
    formData.append('status_id', '6')
    axios.post(API_URL.superStudent + '/todo', formData, {
      headers: {
        Authorization: `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => {
        toast.success('task list add successfully')
        window.location.reload(false);
      })
      .catch(error => {
        toast.error('Opps, check the data requirements again')
        window.location.reload(false);
      })
  }

  loadInternalUser = () => {
    let formData = new FormData()
    formData.append('code_roles', JSON.stringify(['RL003', 'RL006', 'RL013', 'RL023', 'RL026']));
    axios.post(API_URL.superStudent + '/internal-user-active', formData, {
      headers: {
        Authorization: `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then(result => this.setState({ internalUserList: result }))
      .catch(error => console.log(error))
  }

  sendData() {
    this.setState({
      btnDisabled: true
    });
    var formData = new FormData();
    formData.append('nama_lengkap', this.state.nama)
    formData.append('email', this.state.email)
    formData.append('id_propinsi', this.state.id_propinsi);
    formData.append('id_kabkota', this.state.id_kabkota);
    formData.append('no_wa', this.state.no_hp);
    formData.append('alamat', this.state.alamat)
    formData.append('tempat_lahir', this.state.kota_lahir);
    formData.append('tgl_lahir', this.state.tgl_lahir);
    formData.append('jenis_kelamin', this.state.jenis_kelamin);
    formData.append('code_jenjang', this.state.code_jenpen);
    formData.append('kelas_semester', this.state.code_kelas);
    formData.append('code_leads_source', this.state.code_leads_source);
    formData.append('code_konsultan', this.state.code_konsultan);
    formData.append('code_ambassador', this.state.code_ambassador);
    formData.append('code_student_category', this.state.code_students_category);
    formData.append('code_school', this.state.code_sekolah_asal);
    formData.append('code_status', this.state.code_status);
    formData.append('keterangan_kampus', this.state.ket_kampus);
    formData.append('instagram_field', this.state.instagram_field);
    formData.append('student_status', 1);

    axios.post(API_URL.superStudent + '/students', formData, {
      headers: {
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => {
        toast.success('student data update succesfully');
        window.location.reload(false);
      })
      .catch(error => {
        console.log(error)
        toast.error('there is an error')
        window.location.reload(false);
      })
  }

  handleStartMonth = (e) => {
    this.setState({
      start_date: Moment(e).startOf('month')
    })
  }

  handleLastMonth = (e) => {
    this.setState({
      end_date: Moment(e).endOf('month')
    })
  }

  addTodo = (row) => {
    this.loadInternalUser()
    this.setState({
      modalTodo: true,
      code_students: row.code_students
    })
  }

  renderAddDialog() {
    const modal = this.state.modal;
    return (
      <Modal className="modal-dialog" isOpen={modal} toggle={() => this.closeModal()} size="lg">
        <ModalHeader toggle={() => this.closeModal()}>Add Student</ModalHeader>
        <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <form className="needs-validation" noValidate="" >
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="nama">Nama</label>
                <input className="form-control" name="nama" value={this.state.nama} onChange={this.handleInput} type="text" />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="alamat">Alamat</label>
                <input className="form-control" name="alamat" value={this.state.alamat} onChange={this.handleInput} type="text" />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label>Provinsi</label>
                <select className="form-control" value={this.state.id_propinsi} name="id_propinsi" type="text" onChange={this.setProvinsi} >
                  <option value="0">--Pilih Provinsi--</option>
                  {this.state.dataProvinsi.map((data2) => (
                    <option key={data2.id} value={data2.id}>{data2.province_name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="id_kabkota">Kab / Kota</label>
                <select className="form-control" value={this.state.id_kabkota} name="id_kabkota" type="text" onChange={this.handleInput} >
                  <option value="0">--Pilih Kab/Kota--</option>
                  {this.state.dataKabKotaFilter.map((data3) => (
                    <option key={data3.id} value={data3.id}>{data3.kabupaten_name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="no_hp">No HP / WA</label>
                <input className="form-control" name="no_hp" onChange={this.handleInput} value={this.state.no_hp} type="text" />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="email">Email</label>
                <input className="form-control" name="email" value={this.state.email} onChange={this.handleInput} type="text" />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="kota_lahir">Kota Lahir</label>
                <input className="form-control" name="kota_lahir" value={this.state.kota_lahir} onChange={this.handleInput} type="text" />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="tgl_lahir">Tanggal Lahir</label>
                <input type='date' name='tgl_lahir' value={this.state.tgl_lahir} onChange={this.handleInput} className='form-control' />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="jenis_kelamin">Jenis Kelamin</label>
                <select className="form-control" value={this.state.jenis_kelamin} onChange={this.handleInput} name="jenis_kelamin" type="text">
                  <option value="0">--Pilih Jenis Kelamin--</option>
                  <option key="L" value="L">Laki-laki</option>
                  <option key="P" value="P">Perempuan</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_jenpen">Jenjang Pendidikan</label>
                <select className="form-control" value={this.state.code_jenpen} onChange={this.setJenjang} name="code_jenpen" type="text">
                  <option value="0">--Pilih Jenjang Pendidikan--</option>
                  {this.state.dataJenPen.map((data4) => (
                    <option key={data4.code_jenjang} value={data4.code_jenjang}>{data4.jenjang_pendidikan}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_kelas">Kelas / Semester</label>
                <select className="form-control" name="code_kelas" type="text" value={this.state.code_kelas} onChange={this.handleInput}>
                  <option value="0">--Pilih Kelas / Semester--</option>
                  {this.state.dataKelasSemesterFilter.map((data5) => (
                    <option key={data5.code} value={data5.code}>{data5.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_leads_source">Sumber</label>
                <select md="3" className="form-control" value={this.state.code_leads_source} onChange={this.handleInput} name="code_leads_source">
                  <option value="0">--Pilih Sumber Leads--</option>
                  {this.state.dataLeadsSource.map((data1) => (
                    <option key={data1.code_leads_source} value={data1.code_leads_source}>{data1.source}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_jenpen">Sekolah Asal</label>
                <Typeahead
                  id="basic-typeahead"
                  labelKey="nama_sekolah"
                  name="code_sekolah_asal"
                  options={this.state.dataSekolah}
                  onChange={(e) => this.setSekolahAsal(e)}
                  placeholder="--Sekolah Asal--"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_konsultan">Konsultan</label>
                <select md="3" className="form-control" value={this.state.code_konsultan} onChange={this.handleInput} name="code_konsultan">
                  <option value="0">--Pilih Consultant--</option>
                  {this.state.dataConsultant.map((data1) => (
                    <option key={data1.code} value={data1.code}>{data1.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_ambassador">Ambassador</label>
                <select md="3" className="form-control" value={this.state.code_ambassador} onChange={this.handleInput} name="code_ambassador">
                  <option value="0">--Pilih Ambassador--</option>
                  {this.state.dataAmbassador.map((data1) => (
                    <option key={data1.code_ambassador} value={data1.code_ambassador}>{data1.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_students_category">Category</label>
                <select md="3" className="form-control" name="code_students_category" value={this.state.code_students_category} onChange={this.handleInput}>
                  <option value="0">--Category--</option>
                  {this.state.dataCategory.map((data1) => (
                    <option key={data1.code_students_category} value={data1.code_students_category}>{data1.category}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className='form-row'>
              <div className='col-md-12 mb-3'>
                <label>Kategori Student</label>
                <select name="code_status" onChange={this.handleChange} value={this.state.code_status} className="form-control">
                  <option key={0} value="">--Kategori--</option>
                  {
                    this.state.dataStatus.map(status => {
                      return <option key={status.code} value={status.code}>{status.name}</option>
                    })
                  }
                </select>
              </div>
            </div>
            <strong>Social Media</strong>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_students_category">Instargram</label>
                <input className="form-control" value={this.state.instagram_field} onChange={this.handleInput} name="instagram_field" type="text" />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save</Button>
          <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderTodoModal() {
    return (
      <Modal className="modal-dialog" isOpen={this.state.modalTodo} toggle={() => this.closeModal()} size="md">
        <ModalHeader toggle={() => this.closeModal()}>Add Data</ModalHeader>
        <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <form className="needs-validation tooltip-validation" noValidate="" >
            <div className='form-group'>
              <label className='control-label'>Description</label>
              <textarea className='form-control' onChange={this.handleInput} value={this.state.description} name='description'></textarea>
            </div>
            <div className='form-group'>
              <label className='control-label'>Type</label>
              <select onChange={this.handleTypeList} className='form-control' value={this.state.todoType} name='todoType'>
                <option key={0} value='0'>Select Task</option>
                <option key={1} value='10'>Pendaftaran Sekolah</option>
                <option key={2} value='11'>Lodge Visa</option>
                <option key={3} value='33'>Accomodation</option>
                <option key={4} value='73'>Statement Latter</option>
                <option key={5} value='999'>Others</option>
              </select>
            </div>
            <div className='form-group'>
              <label className='control-label'>University</label>
              <Typeahead
                id="basic-typeahead"
                labelKey="name"
                name="code_pilihan_kampus"
                options={this.state.dataCampus}
                onChange={(e) => this.setKampusTujuan(e)}
                placeholder="--Pilihan Kampus--"
              />
            </div>
            <div className='form-group'>
              <label className='control-label'>Program</label>
              <Typeahead
                id="basic-typeahead"
                labelKey="program_name"
                name="id_program"
                options={this.state.dataProgram}
                onChange={(e) => this.setProgramTujuan(e)}
                placeholder="--Pilihan Program--"
              />
            </div>
            {
              this.state.todoType == '10' ?
                <div className='form-group'>
                  <label className='control-label'>Date Program</label>
                  <div className='input-group'>
                    <DatePicker dateFormat='MMMM yyyy' selected={this.state.start_date._d} showMonthYearPicker showFullMonthYearPicker className='form-control' onChange={this.handleStartMonth} />
                    <DatePicker dateFormat='MMMM yyyy' selected={this.state.end_date._d} showMonthYearPicker showFullMonthYearPicker className='form-control' onChange={this.handleLastMonth} />
                  </div>
                </div>
                : ''
            }
            <div className='form-group'>
              <label className='control-label'>From</label>
              <select name='from' className='form-control' onChange={this.handleFrom} value={this.state.from}>
                <option key={0} value=''>--Select--</option>
                {
                  this.state.internalUserList.map(list => (
                    <option key={list.code_users} value={list.code_users}>{list.username}</option>
                  ))
                }
              </select>
              <span style={{ fontSize: '10px', color: 'red' }}>Task perlu di approve yang bersangkuran ketika user yang  di pilih tidak sama dengan user yang login</span>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveList()}>Save</Button>
          <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  setPrivasi(row) {
    this.setState({ showLoading: true })
    let formData = new FormData()
    formData.append('code_students', row.code_students)
    axios.post(API_URL.superStudent + '/student/set-privasi', formData, {
      headers: { Authorization: `Bearer ${this.props.dataToken}` }
    })
      .then(response => {
        this.getAllstudents()
        this.setState({ showLoading: false })
      })
      .catch(error => {
        console.log(error)
      })
  }

  editDetail = (row, type) => {
    this.getDataSekolah(row.id_propinsi)
    this.setState({
      typeUpdate: type,
      detailStudent: row,
      no_hp: row.no_hp,
      nama: row.nama,
      potensial: row.code_students_category,
      code_status: row.code_status,
      code_school: row.code_school,
      modalPotensial: true
    })
  }

  editNoted = (row) => {
    this.setState({
      detailStudent: row,
      dataNoted: row.progress_log.length > 0 ? row.progress_log[0].code : '',
      modalNoted: true,
    })
  }

  updateStudent() {
    this.state.typeUpdate == "Potensial" ?
      this.handleEditable("code_students_category", this.state.detailStudent.code_students, this.state.potensial)
      :
      this.state.typeUpdate == "University" ?
        this.handleEditable("code_school_rep", this.state.detailStudent.code_students, this.state.code_pilihan_kampus)
        :
        this.state.typeUpdate == "Sekolah" ?
          this.handleEditable("code_school", this.state.detailStudent.code_students, this.state.code_sekolah_asal)
          :
          this.state.typeUpdate == "Consultant" ?
            this.handleEditable("code_konsultan", this.state.detailStudent.code_students, this.state.code_konsultan)
            :
            this.state.typeUpdate == "HP" ?
              this.handleEditable("no_hp", this.state.detailStudent.code_students, this.state.no_hp)
              :
              this.state.typeUpdate == "Nama" ?
                this.handleEditable("nama", this.state.detailStudent.code_students, this.state.nama)
                :
                this.handleEditable("code_status", this.state.detailStudent.code_students, this.state.code_status)
  }

  renderModalPotensial() {
    return (
      <Modal className="modal-dialog" isOpen={this.state.modalPotensial} toggle={() => this.closeModal()} size="lg">
        <ModalHeader toggle={() => this.closeModal()}></ModalHeader>
        <ModalBody>
          <h5 className='mb-3'>Edit {this.state.typeUpdate} {this.state.detailStudent.nama}</h5>
          <form className="needs-validation" noValidate="">
            <div className="form-row">
              <div className="col-md-12 mb-3">
                {
                  this.state.typeUpdate == "Potensial" ?
                    <select className="form-control" name="potensial" value={this.state.potensial} onChange={this.handleInput}>
                      {
                        this.state.dataCategory.map(item => {
                          return <option key={item.code_students_category} value={item.code_students_category}>{item.category}</option>
                        })
                      }
                    </select>
                    :
                    this.state.typeUpdate == 'HP' ?
                      <input type='input' name='no_hp' value={this.state.no_hp} className='form-control' onChange={this.handleInput} />
                      :
                      this.state.typeUpdate == 'Nama' ?
                        <input type='input' name='nama' value={this.state.nama} className='form-control' onChange={this.handleInput} />
                        :
                        this.state.typeUpdate == "Sekolah" ?
                          <Typeahead
                            id="basic-typeahead"
                            labelKey="nama_sekolah"
                            name="code_sekolah_asal"
                            options={this.state.dataSekolah}
                            onChange={(e) => this.setSekolahAsal(e)}
                            placeholder="--Sekolah Asal--"
                          />
                          :
                          this.state.typeUpdate == "Consultant" ?
                            <Typeahead
                              id="basic-typeahead"
                              labelKey="name"
                              name="code_konsultan"
                              options={this.state.dataConsultant}
                              onChange={(e) => this.setConsultant(e)}
                              placeholder="--Consultant--"
                            />
                            :
                            <select name="code_status" onChange={this.handleChange} value={this.state.code_status} className="form-control">
                              <option key={0} value="">--Kategori--</option>
                              {
                                this.state.dataStatus.map(status => {
                                  return <option key={status.code} value={status.code}>{status.name}</option>
                                })
                              }
                            </select>
                }

              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          {
            this.state.typeUpdate == 'HP' ?
              this.state.no_hp.substring(0, 2) == '62' ?
                <a href={'https://wa.me/' + this.state.no_hp} rel='noreferrer noopener' target='_blank'><Button color='success'>Chat Watsapp</Button></a>
                :
                <a href={'https://wa.me/' + '62' + this.state.no_hp.substring(1)} rel='noreferrer noopener' target='_blank'><Button color='success'>Chat Watsapp</Button></a>
              :
              ''
          }
          <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.updateStudent()}>Save</Button>
          <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderModalNote() {
    return (
      <Modal className="modal-dialog" isOpen={this.state.modalNoted} toggle={() => this.closeModal()}>
        <ModalHeader toggle={() => this.closeModal()}>Update Note</ModalHeader>
        <ModalBody>
          <h5 className='mb-3'>Edit {this.state.typeUpdate} {this.state.detailStudent.nama}</h5>
          <form className="needs-validation" noValidate="">
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <textarea rows={3} className='form-control' name='dataNoted' value={this.state.dataNoted} onChange={this.handleChange}></textarea>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.updateNoted()}>Save</Button>
          <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    let roles = this.props.codeRoles;

    const columns = [
      {
        Header: 'Code',
        accessor: 'code_students',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["code_students"] }),
        filterAll: true
      },
      {
        Header: 'Member Code',
        accessor: 'code_member',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["code_member"] }),
        filterAll: true
      },
      {
        Header: 'Source',
        accessor: 'student_status',
        filterable: true,
        Cell: (row) => (
          <>{row.original.sumber_name}</>
        ),
        Filter: () =>
          <select value={this.state.filterSource || ''} onChange={(e) => this.SetFilterSource(e)}>
            <option key={0} value="">Show All</option>
            {
              this.state.dataStudentSource.map(source => {
                return <option key={source.id} value={source.id}>{source.name}</option>
              })
            }
          </select>
      },
      {
        Header: 'Name',
        accessor: 'nama',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["nama"] }),
        filterAll: true,
        Cell: (row) => (
          this.props.dataUser.code_roles == 'RL023' || this.props.dataUser.code_roles == 'RL003' ?
            <div onClick={e => this.editDetail(row.original, "Nama")}>{row.original.nama}</div>
            : <div>{row.original.nama}</div>
        ),
      },
      {
        Header: 'Telp',
        accessor: 'no_hp',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["no_hp"] }),
        filterAll: true,
        Cell: (row) => (
          <div onClick={e => this.editDetail(row.original, "HP")}>{row.original.no_hp}</div>
        ),
      },
      {
        Header: 'Kategori',
        accessor: 'code_status',
        filterable: true,
        Cell: (row) => (
          <div onClick={e => this.editDetail(row.original, "Kategori")}>{row.original.status_name}</div>
        ),
        Filter: () =>
          <select value={this.state.filterCategory || ''} onChange={(e) => this.SetFilterCategory(e)}>
            <option key={0} value="">Show All</option>
            {
              this.state.dataStatus.map(status => {
                return <option key={status.code} value={status.code}>{status.name}</option>
              })
            }
          </select>
      },
      {
        Header: 'Progress',
        accessor: 'code_progress',
        filterable: true,
        Cell: (row) => (
          <>{row.original.progress_name}</>
        ),
        Filter: () =>
          <select value={this.state.filterProgress || ''} onChange={(e) => this.SetFilterProgress(e)}>
            <option key={0} value="">Show All</option>
            {
              this.state.dataProgress.map(progress => {
                return <option key={progress.code} value={progress.code}>{progress.name}</option>
              })
            }
          </select>
      },
      {
        Header: 'Consultant',
        accessor: 'code_konsultan',
        filterable: true,
        Cell: (row) => (
          <div onClick={e => this.editDetail(row.original, "Consultant")}>{row.original.consultant_name}</div>
        ),
        Filter: () =>
          <select value={this.state.filterConsultant || ''} onChange={(e) => this.SetFilterConsultant(e)} name="code_konsultan">
            <option key={0} value="">Show All</option>
            {this.state.dataConsultant.map((data1) => (
              <option key={data1.code} value={data1.code}>{data1.name}</option>
            ))}
          </select>
      },
      {
        Header: 'Potensial',
        accessor: 'code_students_category',
        style: { textAlign: 'center' },
        filterable: true,
        Cell: (row) => (
          <div onClick={e => this.editDetail(row.original, "Potensial")}>
            {
              row.original.code_students_category == 'SC00001' ?
                <span className={'badge bg-info'}>{row.original.category_name}</span>
                :
                row.original.code_students_category == 'SC00002' ?
                  <span className={'badge bg-success'}>{row.original.category_name}</span>
                  :
                  row.original.code_students_category == 'SC00003' ?
                    <span className={'badge bg-danger'}>{row.original.category_name}</span>
                    :
                    row.original.code_students_category == 'SC00004' ?
                      <span className={'badge bg-warning'}>{row.original.category_name}</span>
                      :
                      <span className={'badge bg-dark'}>None</span>
            }
          </div>
        ),
        Filter: () =>
          <select value={this.state.filterRequestor || ''} onChange={(e) => this.SetFilterRequestor(e)}>
            <option key={0} value=''>Show All</option>
            {
              this.state.dataCategory.map(item => {
                return <option key={item.code_students_category} value={item.code_students_category}>{item.category}</option>
              })
            }
          </select>,
      },
      {
        Header: 'Sekolah',
        accessor: 'sekolah_name',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["sekolah_name"] }),
        filterable: false,
        Cell: (row) => (
          row.original.sekolah_name != '' ?
            <div onClick={e => this.editDetail(row.original, "Sekolah")}>{row.original.sekolah_name}</div>
            :
            <div onClick={e => this.editDetail(row.original, "Sekolah")}>''</div>
        )
      },
      {
        Header: 'Kelas',
        accessor: 'kelas_semester.kelas',
        filterable: true,
        Filter: () =>
          <select value={this.state.filterKelas || ''} onChange={(e) => this.SetFilterKelas(e)}>
            <option key={0} value=''>Show All</option>
            {
              this.state.dataKelasSemester.map(item => {
                return <option key={item.code} value={item.code}>{item.name}</option>
              })
            }
          </select>,
      },
      {
        Header: 'Programs',
        accessor: 'programs',
        filterable: false,
        Cell: (row) => (
          <ul style={{ overflow: 'auto' }} className='row'>
            {
              row.original.programs.length > 0 ?
                row.original.programs.map((item, index) => (
                  <li style={{ fontSize: '10px' }} className='col' key={index}>({item.university.countrycode}) {item.university.nama_sekolah} - {item.program_name}</li>
                ))
                : <></>
            }
          </ul>
        ),
      },
      roles == 'RL008' ?
        {
          Header: 'Commision',
          accessor: 'ambassador_commission',
          filterable: false,
          style: { textAlign: 'center' },
          Cell: (row) => (
            <>
              {row.original.ambassador_commission == 'paid' ? <span className='badge badge-success'>{row.original.ambassador_commission}</span> : <span className='badge badge-danger'>{row.original.ambassador_commission}</span>}
            </>
          ),
        }
        :
        {
          Header: 'Date Granted',
          accessor: 'last_progress_log',
          filterable: false,
          Cell: (row) => (
            <>
              { row.original.last_progress_log !== null ?
                Moment(row.original.last_progress_log.datetime_progress).format('DD/MM/YYYY')
                : ''
              }
            </>
          ),
        },
      {
        Header: <b>Action</b>,
        id: 'delete',
        accessor: str => "delete",
        Cell: (row) => (
          <div className="row">
            <div className="col-md-3">
              <button
                onClick={() =>
                  window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + row.original.token_students)
                }
                className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none' }} ><i className="fa fa-list" style={{ fontSize: 16 }}></i>
              </button>
              <button onClick={() => this.addTodo(row.original)} style={{ border: 'none' }} className="btn btn-outline-warning btn-xs"><i className='fa fa-file-text-o' style={{ fontSize: 16 }}></i></button>
              {
                this.props.codeRoles === 'RL003' || this.props.codeRoles === 'RL012' ?
                  <button onClick={() => this.setPrivasi(row.original)} style={{ border: 'none' }} className='btn btn-outline-success btn-xs'>
                    <i className={`fa fa-eye${row.original.privation == 1 ? '-slash' : ''}`} style={{ fontSize: 16 }}></i>
                  </button>
                  :
                  ''
              }
            </div>
          </div>
        ),
        style: {
          textAlign: 'center'
        },
        width: 100,
        sortable: false,
        filterable: false
      }
    ]

    const codeRoles = this.props.codeRoles

    return (
      <Fragment>
        <div style={{ display: codeRoles === 'RL008' ? 'none' : '', border: 'none' }} className="row justify-content-between mb-2">
          <div className="col-md-3">
            <button onClick={() => this.addStudents()} className="btn btn-success" type="button" ><i className="fa fa-plus"></i></button>
          </div>
          <div className='col-md-3' style={{ display: codeRoles === 'RL006' ? 'none' : '', border: 'none' }}>
            <select name='filter_branch' value={this.state.filter_branch} onChange={this.handleChange} className='form-control' >
              <option value='0'>All Students</option>
              <option value='1'>Filter Branch</option>
            </select>
          </div>
          <div style={{ display: this.state.showAction }} className="col-md-3">
            <div className="row">
              <div className="col-md-6">
                <ExportCSV csvData={this.state.data} fileName={"data-students"} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className='col-md-2 float-right mb-2'>
            <select name='per_page' value={this.state.per_page} onChange={this.handleChange} className='form-control' >
              <option value='20'>20 rows</option>
              <option value='50'>50 rows</option>
              <option value='100'>100 rows</option>
              <option value='200'>200 rows</option>
              <option value='500'>500 rows</option>
            </select>
          </div>
          <div className="col-md-12">
            <div className="card">
              <ReactTable
                data={this.state.data}
                pageSize={this.state.per_page}
                filtered={this.state.filtered}
                showPagination={false}
                onSortedChange={(newSorted, column, shiftKey) => {
                  console.log('new Sorte =>', newSorted, 'Column =>', column, 'shift Key =>', shiftKey);
                }}
                columns={columns}
                filterable
                onFilteredChange={(filtered, column, value) => {
                  this.onFilteredChangeCustom(value, column.id || column.accessor);
                }}
                collapseOnDataChange={false}
                defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                defaultPageSize={this.state.size}
                className="-striped -highlight"
                SubComponent={row => {
                  return (
                    <div style={{ padding: "20px" }}>
                      <div className='row'>
                        <div className='col'>
                          <i onClick={e => this.editNoted(row.original)} className="fa fa-pencil" style={{ fontSize: 16, color: "#22af47" }}></i><strong> Notes :</strong> <span>{row.original.progress_log.length > 0 ?
                            <>
                              {row.original.progress_log[0].code}
                            </>
                            : ''}</span>
                        </div>
                      </div>
                    </div>
                  )
                }}
              />
              <Loader showLoading={this.state.showLoading} />
            </div>
          </div>
        </div>
        <ToastContainer />
        {this.renderAddDialog()}
        {this.renderTodoModal()}
        {this.renderModalPotensial()}
        {this.renderModalNote()}
      </Fragment>
    );
  }
};


const reduxState = (state) => ({
  dataToken: state.Login.lgnInitialize,
  dataUser: state.Login.detailUser,
  codeUsers: state.Login.detailUser.code_users,
  codeRoles: state.Login.detailUser.code_roles,
})

const reduxDispatch = (dispatch) => ({
  resettoken: (data, headers) => dispatch(resettoken(data, headers)),
  addRowStudents: (data) => dispatch(addRowStudents(data))
})

export default connect(reduxState, reduxDispatch)(withRouter(DatatableStudents));
